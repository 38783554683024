import axios from 'axios';
import getConfig from 'next/config';
import qs from 'qs';

const { serverRuntimeConfig } = getConfig();

const externalUrls = {
  local: `https://api.dev.on3.com/`,
  development: `https://api.dev.on3.com/`,
  qa: `https://api.qa.on3.com/`,
  production: `https://api.on3.com/`,
} as const;

const adminUrls = {
  local: `https://db.dev.on3.com`,
  development: `https://db.dev.on3.com`,
  qa: `https://db.qa.on3.com`,
  production: `https://db.on3.com`,
} as const;

const cmsUrls = {
  local: `https://admin.dev.on3.com`,
  development: `https://admin.dev.on3.com`,
  qa: `https://admin.qa.on3.com`,
  production: `https://admin.on3.com`,
} as const;

const webUrls = {
  local: `https://www.dev.on3.com`,
  development: `https://www.dev.on3.com`,
  qa: `https://www.qa.on3.com`,
  production: `https://www.on3.com`,
} as const;

type Environment = keyof typeof adminUrls;

const nextPublicAppEnv: Environment =
  (process.env.NEXT_PUBLIC_APP_ENV as Environment) || 'local';

const databaseUrl = adminUrls[nextPublicAppEnv];
const cmsUrl = cmsUrls[nextPublicAppEnv];
const webUrl = webUrls[nextPublicAppEnv];

const externalApi = axios.create({
  baseURL: externalUrls[nextPublicAppEnv],
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 20000,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

const internalApi = axios.create({
  baseURL: serverRuntimeConfig?.internalApiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 20000,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

const proxyApi = axios.create({
  baseURL: serverRuntimeConfig?.proxyApiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 5000,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

const proxyApiWithVary = axios.create({
  baseURL: serverRuntimeConfig?.proxyApiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 20000,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

const getFulfilledValue = <T>(data?: PromiseSettledResult<T>): T | null => {
  return data?.status === 'fulfilled' ? data.value : null;
};

const swrFetcher = <T>(url: string, params?: Record<string, unknown>) =>
  externalApi.get<T>(url, { params }).then((r) => r.data);

const settleSequentially = async <T>(
  items: Array<Promise<T> | (() => Promise<T>)>,
): Promise<PromiseSettledResult<T>[]> => {
  const results: PromiseSettledResult<T>[] = [];

  for (const item of items) {
    try {
      // If item is a function, invoke it; otherwise, use it directly
      const promise = typeof item === 'function' ? item() : item;
      const value = await promise;

      results.push({ status: 'fulfilled', value });
    } catch (reason) {
      results.push({ status: 'rejected', reason });
    }
  }

  return results;
};

export {
  cmsUrl,
  databaseUrl,
  externalApi,
  getFulfilledValue,
  internalApi,
  proxyApi,
  proxyApiWithVary,
  settleSequentially,
  swrFetcher,
  webUrl,
};
