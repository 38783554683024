import { Visibility } from '@on3/api';
import { Checkbox } from '@on3/ui-lib/components/Checkbox/Checkbox';
import { Input } from '@on3/ui-lib/components/Input/Input';
import { filterPhoneNumber } from '@on3/ui-lib/utils/filters';
import { formatPhoneNumber } from '@on3/ui-lib/utils/formatters';
import { ChangeEvent, useState } from 'react';

import { CompleteProfileModalScreenProps } from '..';
import styles from './Screens.module.scss';

type Key = 'email' | 'phoneNumber';

export const InfoScreen = ({
  formState,
  profileData,
  handleSave,
}: CompleteProfileModalScreenProps) => {
  const [state, setState] = useState({
    email: {
      value: profileData.email?.value || '',
      visibility: profileData.email?.visibility || Visibility.Athletes,
    },
    phoneNumber: {
      value: profileData.phoneNumber?.value || '',
      visibility: profileData.phoneNumber?.visibility || Visibility.Athletes,
    },
  });

  const handleValueChange = (key: Key, val: string) => {
    setState((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        value: val,
      },
    }));
  };

  const handleVisibilityChange = (key: Key, val: boolean) => {
    setState((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        visibility: val ? Visibility.Athletes : Visibility.Private,
      },
    }));
  };

  return (
    <form className={styles.block} onSubmit={(e) => handleSave(e, state)}>
      <div className={styles.group}>
        <Input
          error={formState.errors['email.value']}
          id="email"
          label="Email Address"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleValueChange('email', e.target.value)
          }
          value={state.email.value}
        />
        <Checkbox
          id="share-email"
          label="Share with Athletes"
          onChange={(val: boolean) => handleVisibilityChange('email', val)}
          value={state.email.visibility === Visibility.Athletes}
        />
      </div>
      <div className={styles.group}>
        <Input
          error={formState.errors['phoneNumber.value']}
          id="phone"
          label="Phone Number"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleValueChange('phoneNumber', filterPhoneNumber(e.target.value))
          }
          value={formatPhoneNumber(state.phoneNumber.value)}
        />
        <Checkbox
          id="share-phone"
          label="Share with Athletes"
          onChange={(val: boolean) =>
            handleVisibilityChange('phoneNumber', val)
          }
          value={state.phoneNumber.visibility === Visibility.Athletes}
        />
      </div>
      <div className={styles.submit}>
        <button disabled={formState.loading} type="submit">
          Continue
        </button>
      </div>
    </form>
  );
};
