import {
  PersonContactInfoResponse,
  PlayerProfileResponse,
  v1PeopleContactInfoUpdate,
  Visibility,
} from '@on3/api';
import { Check } from '@on3/ui-lib/components/Svg/Check';
import { externalApi, useToast } from '@on3/ui-lib/index';
import { ErrorsType, getErrors, yup } from '@on3/ui-lib/utils/yup';
import clsx from 'clsx';
import { useModal } from 'components/Modals';
import { useCallback, useEffect, useMemo, useState } from 'react';

import styles, { primaryColor } from './CompleteProfile.module.scss';
import { AgentScreen } from './Screens/Agent';
import { GuardianScreen } from './Screens/Guardians';
import { InfoScreen } from './Screens/Info';

interface CompleteProfileModalProps {
  player: PlayerProfileResponse;
  contactInfo: PersonContactInfoResponse;
}

export interface CompleteProfileModalScreenProps {
  formState: {
    loading: boolean;
    errors: ErrorsType;
  };
  profileData: PersonContactInfoResponse;
  handleSave: (e: React.FormEvent<HTMLFormElement>, data: any) => void;
}

const screens = [
  {
    label: 'My Info',
    value: 'info',
    check: ['email.value', 'phoneNumber.value'],
    screen: InfoScreen,
  },
  {
    label: 'Agent',
    value: 'agent',
    check: [
      'agentInfo.value.name',
      'agentInfo.value.email',
      'agentInfo.value.phoneNumber',
    ],
    screen: AgentScreen,
  },
  {
    label: 'Guardians',
    value: 'guardians',
    check: [
      'parentInfo.value.name',
      'parentInfo.value.email',
      'parentInfo.value.phoneNumber',
    ],
    screen: GuardianScreen,
  },
];

// Validation schema
const phoneValidation = yup.string().matches(/[0-9]{10}/g, {
  message: 'Invalid phone number',
  excludeEmptyString: true,
});

const emailValiation = yup.string().email('Invalid email address');

const contactInfoValidation = yup.object().shape({
  value: yup.object().shape({
    name: yup.string().nullable(),
    email: emailValiation.nullable(),
    phoneNumber: phoneValidation.nullable(),
  }),
});

const schema = yup.object().shape({
  agentInfo: contactInfoValidation,
  email: yup.object().shape({
    value: emailValiation.required('Email is required'),
    visibility: yup.string().oneOf(Object.values(Visibility)).nullable(),
  }),
  phoneNumber: yup.object().shape({
    value: phoneValidation.required('Phone number is required'),
    visibility: yup.string().oneOf(Object.values(Visibility)).nullable(),
  }),
  parentInfo: contactInfoValidation,
  secondaryParentInfo: contactInfoValidation,
});

export const CompleteProfileModal = ({
  player,
  contactInfo,
}: CompleteProfileModalProps) => {
  const { notify } = useToast();
  const [active, setActive] = useState<number>(0);
  const [profileData, setProfileData] =
    useState<PersonContactInfoResponse>(contactInfo);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const { setModal } = useModal();

  // Check if all desired fields are completed
  const hasCompleted = useCallback(
    (check: string[]) => {
      return check.every((key) => {
        const keys = key.split('.');
        const property = keys.reduce(
          (acc, k) => (acc as any)?.[k],
          profileData,
        ) as string | null | undefined;

        return property !== undefined && property !== null && property !== '';
      });
    },
    [profileData],
  );

  // Check if the required fields are completed
  const hasCompletedRequired = useMemo(
    () => hasCompleted(screens[0].check),
    [hasCompleted],
  );

  const handleSave = useCallback(
    async (e: React.FormEvent<HTMLFormElement>, data: any) => {
      e.preventDefault();
      setLoading(true);

      data = {
        ...profileData,
        ...data,
      };

      try {
        await schema.validate(data, { abortEarly: false });

        const res = await v1PeopleContactInfoUpdate(
          externalApi,
          player.key,
          data,
        );

        setProfileData(res);
        setErrors({});

        // If there is a next screen, move to it
        // Otherwise, close the modal
        const nextScreen = screens[active + 1];

        if (nextScreen) {
          setActive(active + 1);
        } else {
          setModal(null);
        }
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          setErrors(getErrors(error));

          return;
        }

        notify('An error occurred. Please try again.', {
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [active, player, profileData, notify, setModal],
  );

  const Screen = useMemo(() => screens[active].screen, [active]);

  // If user has provided email and phone number, allow the modal to be closed
  useEffect(() => {
    if (hasCompletedRequired) {
      setModal((modal: any) => ({ ...modal, required: false }));
    }
  }, [hasCompletedRequired, setModal]);

  return (
    <div className={styles.modal}>
      <h2>Complete Your Profile</h2>
      <nav>
        {screens.map((item, idx) => (
          <button
            className={clsx(styles.navItem, {
              [styles.active]: idx === active,
            })}
            disabled={idx > 0 && !hasCompletedRequired}
            key={item.value}
            onClick={() => setActive(idx)}
          >
            {item.label}
            {hasCompleted(item.check) && <Check fill={primaryColor} />}
          </button>
        ))}
      </nav>
      <div>
        <Screen
          formState={{ loading, errors }}
          handleSave={handleSave}
          profileData={profileData}
        />
      </div>
    </div>
  );
};
