import type { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface ActiveGameStatusResponse {
  currentPeriod?: string | null;
  down?: string | null;
  possession?: string | null;
  timeRemaining?: string | null;
  yardline?: string | null;
  yardsRemaining?: string | null;
}

export interface AddAliasRequest {
  alias?: string;
  index?: string;
}

export interface AddAndRemoveAliasRequest {
  alias?: string;
  indexToAdd?: string;
  indexToRemove?: string;
}

export interface AdminNavPerResponse {
  isAthlete: boolean;
  isCoach: boolean;
  /** @format int64 */
  key: number;
  name?: string;
  slug?: string;
  sports?: AdminNavPsSlimResponse[];
}

export interface AdminNavPsSlimResponse {
  /** @format int64 */
  key: number;
  name?: string;
  organizations?: AdminNavReResponse[];
}

export interface AdminNavReResponse {
  draft: boolean;
  oracleLocked: boolean;
  /** @format int32 */
  organizationKey?: number | null;
  organizationName?: string | null;
  organizationType?: OrganizationType;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  /** @format int64 */
  recruitmentKey: number;
  shortTermSignee: boolean;
  transfer: boolean;
  /** @format int32 */
  year: number;
}

export interface AdminOrgResponse {
  organization?: AdminOrgResponseOrg;
  sports?: SportResponse[];
}

export interface AdminOrgResponseOrg {
  /** @format double */
  act?: number | null;
  bio?: string | null;
  /** @format int32 */
  classYear?: number | null;
  /** @format double */
  gpa?: number | null;
  /** @format int32 */
  organizationKey: number;
  organizationKnownAs?: string | null;
  organizationName?: string;
  positionType: PositionType;
  positionTypeName?: string;
  /** @format double */
  sat?: number | null;
  stateAbbreviation?: string | null;
  /** @format int32 */
  stateKey?: number | null;
  type: OrganizationType;
  typeName?: string;
  website?: string | null;
}

export interface AdminPerResponse {
  almaMater?: OrganizationResponse;
  /** @format int32 */
  almaMaterClassYear?: number | null;
  athleteVerified: boolean;
  bio?: string | null;
  bioCollegeRecruit?: string | null;
  bioProProspect?: string | null;
  /** @format date-time */
  dateOfBirth?: string | null;
  defaultAsset?: DefaultAssetResponse;
  degree?: string | null;
  espnProfile?: string | null;
  excludeFromNil: boolean;
  firstName?: string;
  goesBy?: string | null;
  highSchoolAlmaMater?: OrganizationResponse;
  /** @format int32 */
  highSchoolAlmaMaterClassYear?: number | null;
  hometown?: HometownResponse;
  instagramProfile?: string | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  ncaaId?: string | null;
  /** @format double */
  nilAdjustedRosterValuation: number;
  /** @format double */
  nilAdjustedValuation: number;
  nilStatus: NilStatuses;
  prospectVerified: boolean;
  reviewStatus?: PersonStatusReviewStatus;
  rivalsProfile?: string | null;
  socialMetas?: SocialMetaAdminResponse[];
  tier?: PersonStatusTier;
  tikTokHandle?: string | null;
  twitterHandle?: string | null;
  twoFourSevenProfile?: string | null;
  verificationCode?: string | null;
  visibility?: PersonStatusVisibility;
}

export interface AdvancedOrgSearchResponse {
  abbreviation?: string | null;
  city?: string | null;
  defaultAssetUrl?: string | null;
  /** @format int32 */
  key: number;
  name?: string;
  slug?: string | null;
}

export interface AdvancedOrgSearchResponsePagedData {
  list?: AdvancedOrgSearchResponse[];
  pagination?: Pagination;
}

export interface AgencyResponse {
  /** @format int64 */
  key: number;
  name?: string;
}

export interface AgentContactInfo {
  agencyName?: string | null;
  email?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
}

export interface AgentContactInfoPersonContactInfoMeta {
  value?: AgentContactInfo;
  verification?: Verification;
  visibility: Visibility;
}

export interface AgentContactInfoPersonContactInfoMetaRequest {
  value?: AgentContactInfo;
  verification?: Verification;
  visibility?: Visibility;
}

export interface AgentContactInfoPersonContactInfoMetaResponse {
  value?: AgentContactInfo;
  verification?: VerificationResponse;
  visibility: Visibility;
}

export interface AgentPersonResponse {
  agency?: AgencyResponse;
  /** @format int64 */
  key: number;
  name?: string;
}

/** Specifies the kind of asset and Asset object is. */
export enum AssetClasses {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
}

export interface AssetResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface AttributeResponse {
  abbr?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
}

export interface BadRequestResult {
  /** @format int32 */
  statusCode: number;
}

export interface CatAliasResponse {
  alias: string;
  index: string;
}

export interface CatAliasResponsePagedData {
  list?: CatAliasResponse[];
  pagination?: Pagination;
}

export interface CatIndexResponse {
  docsCount: string;
  health: string;
  name: string;
  status: string;
}

export interface CatIndexResponsePagedData {
  list?: CatIndexResponse[];
  pagination?: Pagination;
}

export enum Category {
  Undetermined = 'Undetermined',
  Highlights = 'Highlights',
  Training = 'Training',
  Experts = 'Experts',
  Conversations = 'Conversations',
  Camp = 'Camp',
}

export interface CityResponseRecord {
  country?: CountryResponseRecord;
  /** @format int32 */
  key: number;
  name?: string;
  state?: StateResponseRecord;
}

export enum ClassYear {
  Unknown = 'Unknown',
  Freshman = 'Freshman',
  RedShirtFreshman = 'RedShirt_Freshman',
  Sophomore = 'Sophomore',
  RedShirtSophomore = 'RedShirt_Sophomore',
  Junior = 'Junior',
  RedShirtJunior = 'RedShirt_Junior',
  Senior = 'Senior',
  RedShirtSenior = 'RedShirt_Senior',
}

export enum ClassificationType {
  Unknown = 'Unknown',
  League = 'League',
  State = 'State',
  Conference = 'Conference',
  Division = 'Division',
  Association = 'Association',
  Region = 'Region',
  District = 'District',
  Class = 'Class',
  Metro = 'Metro',
  Section = 'Section',
}

export interface CoachVisitedResponse {
  /** @format int64 */
  coachKey: number;
  coachName?: string;
  firstName?: string;
  fullName?: string;
  /** @format int64 */
  key: number;
  lastName?: string;
  name?: string;
  position?: PositionResponse;
  slug?: string;
}

export interface CoachingVisitRequest {
  coachKeys: number[];
  /** @format date-time */
  dateOccurred: string;
  /** @format int64 */
  key?: number | null;
}

export interface CoachingVisitResponse {
  coaches?: CoachVisitedResponse[];
  /** @format date-time */
  dateOccurred: string;
  /** @format int64 */
  dateOccurredTimestamp: number;
  /** @format int64 */
  key?: number | null;
}

export interface CollectiveGroupFounderResponse {
  /** @format int64 */
  collectiveGroupKey: number;
  instagramHandle?: string | null;
  /** @format int64 */
  key: number;
  linkedInHandle?: string | null;
  name?: string | null;
  /** @format int32 */
  order?: number | null;
  /** @format int64 */
  personKey?: number | null;
  personSlug?: string | null;
  tikTokHandle?: string | null;
  title?: string | null;
  twitterHandle?: string | null;
}

export interface CollectiveGroupResponse {
  /** @format double */
  annualGoalAmount?: number | null;
  /** @format double */
  confirmedRaisedAmount?: number | null;
  defaultAsset?: ImageResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  description?: string | null;
  founders?: CollectiveGroupFounderResponse[];
  instagramHandle?: string | null;
  /** @format int64 */
  key: number;
  /** @format date-time */
  launchDate?: string | null;
  linkedInHandle?: string | null;
  mergedIntoGroup?: CollectiveGroupSimpleResponse;
  /** @format int64 */
  mergedIntoGroupKey?: number | null;
  missionStatement?: string | null;
  /** @minLength 1 */
  name: string;
  organization?: OrganizationResponse;
  /** @format int32 */
  organizationKey: number;
  organizationType?: string | null;
  /** @minLength 1 */
  slug: string;
  socialAsset?: ImageResponse;
  /** @format int64 */
  socialAssetKey?: number | null;
  sports?: CollectiveGroupSportResponse[];
  tikTokHandle?: string | null;
  twitterHandle?: string | null;
  websiteName?: string | null;
  websiteUrl?: string | null;
  youtubeHandle?: string | null;
}

export interface CollectiveGroupSimpleResponse {
  defaultAsset?: ImageResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  /** @format int64 */
  key: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  slug: string;
}

export interface CollectiveGroupSportRequest {
  primary: boolean;
  /** @format int32 */
  sportKey: number;
}

export interface CollectiveGroupSportResponse {
  /** @minLength 1 */
  name: string;
  primary: boolean;
  /** @format int64 */
  sportKey: number;
}

export interface ConferenceOrgResponse {
  defaultAsset?: KeyUrlBase;
  defaultAssetRes?: AssetResponse;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  sites?: ConferenceOrgSiteResponse[];
  slug?: string | null;
}

export interface ConferenceOrgSiteResponse {
  /** @format int32 */
  key: number;
  name?: string | null;
  slug?: string | null;
  type: SiteType;
}

export interface ConferenceResponse {
  abbreviation?: string | null;
  asset?: KeyUrlBase;
  /** @format int64 */
  assetKey?: number | null;
  assetRes?: AssetResponse;
  isGroupOfFive: boolean;
  isPowerFive: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface ContactInfo {
  email?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
}

export interface ContactInfoPersonContactInfoMeta {
  value?: ContactInfo;
  verification?: Verification;
  visibility: Visibility;
}

export interface ContactInfoPersonContactInfoMetaRequest {
  value?: ContactInfo;
  verification?: Verification;
  visibility?: Visibility;
}

export interface ContactInfoPersonContactInfoMetaResponse {
  value?: ContactInfo;
  verification?: VerificationResponse;
  visibility: Visibility;
}

export interface CountryResponseRecord {
  abbreviation?: string;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface CreateCollectiveGroupFounderRequest {
  instagramHandle?: string | null;
  linkedInHandle?: string | null;
  name?: string | null;
  /** @format int32 */
  order?: number | null;
  /** @format int64 */
  personKey?: number | null;
  tikTokHandle?: string | null;
  title?: string | null;
  twitterHandle?: string | null;
}

export interface CreateCollectiveGroupRequest {
  /** @format double */
  annualGoalAmount?: number | null;
  /** @format double */
  confirmedRaisedAmount?: number | null;
  /** @format int64 */
  defaultAssetKey?: number | null;
  description?: string | null;
  founders: CreateCollectiveGroupFounderRequest[];
  instagramHandle?: string | null;
  /** @format date-time */
  launchDate?: string | null;
  linkedInHandle?: string | null;
  /** The Collective Groups merged to create this Group */
  mergedGroupKeys?: number[] | null;
  missionStatement?: string | null;
  /** @minLength 1 */
  name: string;
  /** @format int32 */
  organizationKey: number;
  organizationType?: string | null;
  /** @format int64 */
  socialAssetKey?: number | null;
  sports: CollectiveGroupSportRequest[];
  tikTokHandle?: string | null;
  twitterHandle?: string | null;
  websiteName?: string | null;
  websiteUrl?: string | null;
  youtubeHandle?: string | null;
}

export interface CreateIndexRequest {
  index?: string;
}

export interface CreateOrganizationRequest {
  /**
   * @minLength 0
   * @maxLength 35
   */
  abbreviation?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  address?: string | null;
  /** @format int32 */
  addressCityKey?: number | null;
  /** @format int64 */
  alternateAssetKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 2000
   */
  bio?: string | null;
  /** @format int64 */
  defaultAssetKey?: number | null;
  isForeign?: boolean | null;
  isPrivate?: boolean | null;
  /** @format int32 */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  knownAs?: string | null;
  /** @format int32 */
  latitude?: number | null;
  /** @format int64 */
  lightAssetKey?: number | null;
  /** @format int32 */
  longitude?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  mascot?: string | null;
  /** @format int32 */
  masseyKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  orgType: OrganizationType;
  /**
   * @minLength 0
   * @maxLength 10
   */
  primaryColor?: string | null;
  /**
   * @minLength 0
   * @maxLength 10
   */
  secondaryColor?: string | null;
  /**
   * @minLength 0
   * @maxLength 75
   */
  slug?: string | null;
  socials?: OrganizationSocialRequest;
  /**
   * @minLength 0
   * @maxLength 35
   */
  telephone?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  website?: string | null;
  /** @format int32 */
  zipCode?: number | null;
}

export interface CreatePersonRequest {
  /** @format int32 */
  almaMaterClassYear?: number | null;
  /** @format int32 */
  almaMaterKey?: number | null;
  bio?: string | null;
  bioCollegeRecruit?: string | null;
  bioProProspect?: string | null;
  /** @format date-time */
  dateOfBirth?: string | null;
  /** @format int64 */
  defaultAssetKey?: number | null;
  degree?: string | null;
  espnProfile?: string | null;
  excludeFromNil: boolean;
  /** @minLength 1 */
  firstName: string;
  /** @format int32 */
  highSchoolAlmaMaterKey?: number | null;
  /** @format int32 */
  hometownCityKey?: number | null;
  instagramProfile?: string | null;
  knownAsName?: string | null;
  /** @minLength 1 */
  lastName: string;
  ncaaId?: string | null;
  /**
   * @format double
   * @min -9999999999.99
   * @max 9999999999.99
   */
  nilAdjustedRosterValuation: number;
  /**
   * @format double
   * @min -9999999999.99
   * @max 9999999999.99
   */
  nilAdjustedValuation: number;
  nilStatus: NilStatuses;
  prospectVerified: boolean;
  reviewStatus: PersonStatusReviewStatus;
  rivalsProfile?: string | null;
  tier: PersonStatusTier;
  tikTokHandle?: string | null;
  twitterHandle?: string | null;
  twoFourSevenProfile?: string | null;
  visibility: PersonStatusVisibility;
}

export interface CreatePersonSportOrganizationRequest {
  blueShirt: boolean;
  /** @format int32 */
  depth?: number | null;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  fired: boolean;
  freeYear: boolean;
  grayShirt: boolean;
  /** @format int32 */
  jerseyNumber?: number | null;
  medical: boolean;
  optedOut: boolean;
  /** @format int32 */
  organizationKey: number;
  /** @format int64 */
  personSportKey: number;
  promoted: boolean;
  redShirt: boolean;
  resigned: boolean;
  /** @format double */
  salary?: number | null;
  transer: boolean;
  /** @default true */
  walkOn: boolean;
  /** @format int32 */
  year: number;
  /**
   * @format int32
   * @min 1
   * @max 10
   */
  yearOrder: number;
}

export interface CreatePersonSportRequest {
  defaultSport: boolean;
  /** @format int64 */
  personKey: number;
  /** @format int32 */
  sportKey: number;
}

export interface CreatePositionRequest {
  /**
   * @minLength 0
   * @maxLength 8
   */
  abbreviation?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  positionType: PositionType;
  /** @format int32 */
  sportKey?: number | null;
}

export interface CreateRecruitmentOracleRequest {
  /**
   * @minLength 0
   * @maxLength 500
   */
  articleLink?: string | null;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  confidence: number;
  hidden: boolean;
  /** @format int32 */
  organizationKey: number;
  /** @format int64 */
  pickerKey?: number | null;
  premium: boolean;
  /** @format int64 */
  recruitmentKey: number;
}

export interface CreateReeRequest {
  /** @minLength 1 */
  body: string;
  category?: ScoutingCategory;
  /** @format date-time */
  dateAdded?: string | null;
  premium: boolean;
  /** @default false */
  primary: boolean;
  /** @format int64 */
  recruitmentKey: number;
  /**
   * @minLength 0
   * @maxLength 512
   */
  title: string;
}

export interface CreateRelationRequest {
  /** @format int64 */
  relatedPersonKey: number;
  /** @format int32 */
  relationTypeKey: number;
}

export interface CreateStrengthRequest {
  /** @format int64 */
  assetKey?: number | null;
  /** @minLength 1 */
  description: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /** @format int32 */
  positionKey?: number | null;
  /** @format int32 */
  sportKey?: number | null;
}

export interface DatabaseUpdatePersonResponse {
  /** @format int64 */
  dateAdded: number;
  /** @format int64 */
  dateOccurred: number;
  /** @format int64 */
  key: number;
  link?: string | null;
  /** @format int64 */
  objectKey?: number | null;
  /** @format int32 */
  organizationKey?: number | null;
  person?: DatabaseUpdatePersonResponsePerson;
  /** @format int64 */
  personKey?: number | null;
  replacementText?: string | null;
  sport?: SportBase;
  /** @format int32 */
  sportKey?: number | null;
  text?: string | null;
  /** Specifies types on a database update */
  type: DatabaseUpdateType;
}

export interface DatabaseUpdatePersonResponsePerson {
  bio?: string | null;
  bioCollegeRecruit?: string | null;
  bioProProspect?: string | null;
  /** @format int32 */
  classYear: number;
  /** @format int32 */
  collegeOrganizationKey?: number | null;
  /** @format date-time */
  dateOfBirth?: string | null;
  /** @format int64 */
  defaultAssetKey?: number | null;
  espnProfile?: string | null;
  firstName: string;
  fullName?: string;
  /** @format int32 */
  highSchoolOrganizationKey?: number | null;
  /** @format int32 */
  hometownCityKey?: number | null;
  instagramProfile?: string | null;
  /** @format int64 */
  key: number;
  knownAsName?: string | null;
  lastName: string;
  rivalsProfile?: string | null;
  tikTokHandle?: string | null;
  twitterHandle?: string | null;
  twoFourSevenProfile?: string | null;
}

export interface DatabaseUpdateRequest {
  /** @format int64 */
  dateOccurred: number;
  link?: string | null;
  /** @format int32 */
  organizationKey?: number | null;
  /** @format int64 */
  personKey?: number | null;
  replacementText?: string | null;
  /** @format int32 */
  sportKey?: number | null;
  /** @minLength 1 */
  text: string;
  /** Specifies types on a database update */
  type: DatabaseUpdateType;
}

/** Specifies types on a database update */
export enum DatabaseUpdateType {
  Commitment = 'Commitment',
  Decommitment = 'Decommitment',
  OfficialVisit = 'OfficialVisit',
  UnofficialVisit = 'UnofficialVisit',
  VerifiedHeightOrWeight = 'VerifiedHeightOrWeight',
  RankingsMove = 'RankingsMove',
  HighSchoolTransfer = 'HighSchoolTransfer',
  Reclassification = 'Reclassification',
  NIL = 'NIL',
  CoachInHome = 'CoachInHome',
  TopSchools = 'TopSchools',
  NewVideo = 'NewVideo',
  Signed = 'Signed',
  Enrolled = 'Enrolled',
}

export interface DefaultAssetResponse {
  /** @format int64 */
  key: number;
  url?: string | null;
}

export interface DeleteAliasRequest {
  alias?: string;
  index?: string;
}

export interface DeleteOrgSportBodyReq {
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  organizationKey: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  sportKey: number;
}

export interface DeleteResponse {
  clientError: boolean;
  errors?: string[];
  success: boolean;
}

export interface DraftAutocompleteResponse {
  /** @format int64 */
  key: number;
  value?: string;
}

export interface DraftDistributionByStateResponse {
  /** @format int32 */
  firstRoundTotal: number;
  states: Record<string, DraftDistributionByStateResponseState>;
  /** @format int32 */
  total: number;
}

export interface DraftDistributionByStateResponseState {
  abbreviation: string;
  /** @format double */
  firstRoundPercentage: number;
  /** @format int32 */
  firstRoundRank: number;
  /** @format int32 */
  firstRoundTotal: number;
  /** @format int32 */
  total: number;
  /** @format double */
  totalPercentage: number;
  /** @format int32 */
  totalRank: number;
}

export interface DraftPickRequest {
  compensatory: boolean;
  /** @format int64 */
  draftKey: number;
  forfeited: boolean;
  /** @format int64 */
  key?: number | null;
  notes?: string | null;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  overallPick?: number | null;
  /** @format int64 */
  recruitmentKey?: number | null;
  /** @format int32 */
  round: number;
  /** @format int32 */
  roundPick?: number | null;
  supplementary: boolean;
  /** @format int32 */
  throughOrganizationOneKey?: number | null;
  /** @format int32 */
  throughOrganizationThreeKey?: number | null;
  /** @format int32 */
  throughOrganizationTwoKey?: number | null;
  traded: boolean;
  /** @format int32 */
  tradingOrganizationKey?: number | null;
}

export interface DraftPickResponse {
  compensatory: boolean;
  /** @format int64 */
  draftKey: number;
  draftName?: string;
  forfeited: boolean;
  /** @format int64 */
  key: number;
  organization?: OrganizationBase;
  /** @format int32 */
  overallPick?: number | null;
  recruitment?: DraftRecruitmentResponse;
  /** @format int32 */
  round: number;
  /** @format int32 */
  roundPick?: number | null;
  supplementary: boolean;
  throughOrganizationOne?: OrganizationBase;
  throughOrganizationThree?: OrganizationBase;
  throughOrganizationTwo?: OrganizationBase;
  traded: boolean;
  tradingOrganization?: OrganizationBase;
}

export interface DraftPickResponsePagedData {
  list?: DraftPickResponse[];
  pagination?: Pagination;
}

export interface DraftPickSimpleResponse {
  /** @format double */
  age?: number | null;
  draftedFromOrganization?: OrganizationBase;
  highSchoolOrganization?: OrganizationBase;
  hometown?: string | null;
  /** @format int64 */
  key: number;
  organization?: OrganizationBase;
  /** @format int32 */
  overallPick?: number | null;
  person?: PersonBase;
  position?: PositionBase;
  rating?: RatingResponseApi;
  /** @format int32 */
  round: number;
  state?: string | null;
}

export interface DraftPickSimpleResponsePagedData {
  list?: DraftPickSimpleResponse[];
  pagination?: Pagination;
}

export interface DraftRecruitmentResponse {
  draftedFromOrganization?: OrganizationBase;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  personName?: string;
  personSlug?: string;
}

export interface DraftRequest {
  /** @format date-time */
  dateOccurred: string;
  /** @format int64 */
  key?: number | null;
  /** @format int64 */
  leagueKey: number;
  /** @format int32 */
  year: number;
}

export interface DraftResponse {
  /** @format date-time */
  dateOccurred?: string | null;
  /** @format int64 */
  key: number;
  /** @format int64 */
  leagueKey: number;
  leagueName?: string;
  name?: string;
  slug?: string;
  /** @format int32 */
  year: number;
}

export interface DraftResponsePagedData {
  list?: DraftResponse[];
  pagination?: Pagination;
}

export interface DraftSimpleResponse {
  collegeOrganization?: OrganizationResponse;
  compensatory: boolean;
  forfeited: boolean;
  industryComparison?: PlayerRatingAllResponse[] | null;
  /** @format int64 */
  key: number;
  organization?: OrganizationResponse;
  /** @format int32 */
  overallPick?: number | null;
  /** @format int32 */
  pick?: number | null;
  rosterRating?: RatingResponse;
  /** @format int32 */
  round: number;
  /** @format int32 */
  sportKey: number;
  supplementary: boolean;
  traded: boolean;
  /** @format int32 */
  year: number;
}

export interface EnsureRostersBatchRequest {
  /** @format int32 */
  limit: number;
  organizationType: OrganizationType;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  startAfterOrganizationKey: number;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  year: number;
}

export interface EventAutocompleteResponse {
  /** @format int64 */
  key: number;
  value?: string;
}

export interface EventAutocompleteResponsePagedData {
  list?: EventAutocompleteResponse[];
  pagination?: Pagination;
}

export enum EventClassification {
  None = 'None',
  TrackAndField = 'TrackAndField',
  Combine = 'Combine',
}

export interface EventResponseRecord {
  city?: CityResponseRecord;
  /** @format int32 */
  cityKey?: number | null;
  classification: EventClassification;
  description?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format int64 */
  key: number;
  name?: string;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey?: number | null;
  /** @format date-time */
  startDate?: string | null;
}

export interface EventResponseRecordPagedData {
  list?: EventResponseRecord[];
  pagination?: Pagination;
}

export interface EventUpdateRequest {
  /** @format int32 */
  cityKey?: number | null;
  classification: EventClassification;
  description?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format int64 */
  key?: number | null;
  /** @minLength 1 */
  name: string;
  /** @format int32 */
  sportKey?: number | null;
  /** @format date-time */
  startDate?: string | null;
}

export interface FileRes {
  domain?: string;
  fileName?: string;
  fullUrl?: string;
  /** @format date-time */
  lastModifiedDate?: string | null;
  path?: string | null;
  tags?: FileTag[];
}

export interface FileTag {
  key?: string;
  value?: string;
}

export enum ForfeitType {
  Unknown = 'Unknown',
  HomeTeamForfeit = 'HomeTeamForfeit',
  AwayTeamForfeit = 'AwayTeamForfeit',
  DoubleForfeit = 'DoubleForfeit',
}

export interface FullOrgSportRes {
  /** @format int32 */
  key: number;
  organization: OrganizationResponse;
  /** @format int32 */
  organizationKey: number;
  sport: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
}

export interface GameOdds {
  moneyline?: MoneylineInfo;
  overUnder?: OverUnderInfo;
  pointSpread?: PointSpreadInfo;
}

export interface GameOddsResponse {
  /** @format double */
  awayHandicap?: number | null;
  /** @format int32 */
  awayMoneylineOdds?: number | null;
  /** @format double */
  homeHandicap?: number | null;
  /** @format int32 */
  homeMoneylineOdds?: number | null;
  /** @format double */
  overUnderTotal?: number | null;
}

export enum GameStatuses {
  Unknown = 'Unknown',
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  Delayed = 'Delayed',
  Postponed = 'Postponed',
  Completed = 'Completed',
}

export interface GetIndexAsyncResponse {
  aliases: string[];
  name: string;
}

export interface HometownResponse {
  /** @format int32 */
  key: number;
  name?: string;
}

export interface ImageResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface ImportMappingReq {
  matches?: number[];
  /** @minLength 1 */
  sourceOrgKeyStr: string;
  sourceType: MappingSource;
}

export interface InitialPersonStatusRequest {
  /**
   * @format int32
   * @min 1
   * @max 1000
   */
  limit: number;
  reviewStatus: PersonStatusReviewStatus;
  tier: PersonStatusTier;
  visibility: PersonStatusVisibility;
}

export interface InsiderRequest {
  hidePrediction: boolean;
  /** @minLength 1 */
  predictionUrl: string;
  /** @format int64 */
  userKey: number;
}

export interface InsiderResponse {
  hidePrediction: boolean;
  /** @format int32 */
  key: number;
  predictionUrl: string;
  sport: SportBase;
  /** @format int32 */
  sportKey: number;
  user: UserProfileBase;
  /** @format int64 */
  userKey: number;
}

export interface InsiderResponsePagedData {
  list?: InsiderResponse[];
  pagination?: Pagination;
}

export interface InsiderScrapeHistoryResponse {
  /** @format int32 */
  person247Key?: number | null;
  abbreviation?: string | null;
  /** @format int32 */
  confidence?: number | null;
  /** @format date-time */
  date?: string | null;
  errors?: string[];
  /** @format int32 */
  organizationKey?: number | null;
  /** @format int64 */
  personKey?: number | null;
  personName?: string | null;
  pickerName?: string | null;
  pickerTitle?: string | null;
  rpmPick?: InsiderScrapeHistoryRpmResponse;
  /**
   * The URI scraped to get this information
   * @format uri
   */
  scrapedUri?: string | null;
  /** @format int32 */
  sportKey: number;
  /** @format uri */
  url?: string | null;
  /** @format int32 */
  year: number;
}

export interface InsiderScrapeHistoryRpmResponse {
  /** @format int32 */
  confidence: number | null;
  /** @format date-time */
  date: string | null;
  /** @format int64 */
  key: number;
  /** @format int32 */
  organizationKey: number | null;
  organizationName: string;
  /** @format int64 */
  personKey: number | null;
  personName: string;
  pickerName: string;
  pickerTitle: string;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface InsiderScrapeOracleResponse {
  /** @format double */
  confidence: number | null;
  /** @format date-time */
  dateAdded: string;
  /** @format int32 */
  flippedFromOrganizationKey: number | null;
  hidden: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  organizationKey: number;
  premium: boolean;
  /** @format int64 */
  recruitmentKey: number;
  /** @format double */
  score: number | null;
  status: OracleStatus;
  /** @format int64 */
  userKey: number;
}

export interface InsiderScrapeResponse {
  /** @format int32 */
  person247Key?: number | null;
  abbreviation?: string | null;
  /** @format int32 */
  confidence?: number | null;
  /** @format date-time */
  date?: string | null;
  errors?: string[];
  /** @format int32 */
  organizationKey?: number | null;
  /** @format int64 */
  personKey?: number | null;
  personName?: string | null;
  /**
   * Example: https://247sports.com/playerinstitution/colt-langdon-at-millbrook-274762/currentexpertpredictions/
   * @format uri
   */
  playerExpertPredictionsUri?: string | null;
  possibleOrganizations?: number[];
  /** @format uri */
  profileUri?: string | null;
  recruitmentOracle?: InsiderScrapeOracleResponse;
  /**
   * The URI scraped to get this information
   * @format uri
   */
  scrapedUri?: string | null;
  /** @format int32 */
  year: number;
}

export interface Int32StringKeyValuePair {
  /** @format int32 */
  key: number;
  value?: string;
}

export interface Int64PersonContactInfoMetaResponse {
  /** @format int64 */
  value: number;
  verification?: VerificationResponse;
  visibility: Visibility;
}

export interface Int64PersonContactInfoMetaStruct {
  /** @format int64 */
  value?: number | null;
  verification?: Verification;
  visibility: Visibility;
}

export interface Int64PersonContactInfoMetaStructRequest {
  /** @format int64 */
  value?: number | null;
  verification?: Verification;
  visibility?: Visibility;
}

export enum InterestLevel {
  None = 'None',
  ScoutLook = 'ScoutLook',
  Interested = 'Interested',
  HighChoice = 'HighChoice',
  TopTarget = 'TopTarget',
}

export interface KeyUrlBase {
  fullName?: string;
  /** @format int64 */
  key: number;
  slug?: string | null;
  url?: string | null;
}

export interface KeyUrlSlug {
  fullName?: string;
  /** @format int64 */
  key: number;
  name?: string | null;
  slug?: string | null;
  url?: string | null;
}

export interface LeagueConferenceOrganizationRes {
  conference?: ConferenceResponse;
  /** @format int32 */
  conferenceKey: number;
  /** @format int64 */
  key: number;
  league?: LeagueResponse;
  /** @format int64 */
  leagueConferenceKey: number;
  /** @format int64 */
  leagueKey: number;
  organization?: OrganizationResponse;
  /** @format int32 */
  organizationKey: number;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface LeagueConferenceOrganizationResPagedData {
  list?: LeagueConferenceOrganizationRes[];
  pagination?: Pagination;
}

export interface LeagueConferenceRes {
  conference?: ConferenceResponse;
  /** @format int32 */
  conferenceKey: number;
  /** @format int64 */
  key: number;
  league?: LeagueResponse;
  /** @format int64 */
  leagueKey: number;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
}

export interface LeagueConferenceResPagedData {
  list?: LeagueConferenceRes[];
  pagination?: Pagination;
}

export interface LeagueRequest {
  /**
   * @minLength 0
   * @maxLength 10
   */
  abbreviation?: string | null;
  /** @format int64 */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 75
   */
  name: string;
  organizationType: OrganizationType;
  /** @format int32 */
  sportKey: number;
}

export interface LeagueResponse {
  abbreviation?: string | null;
  /** @format int64 */
  key: number;
  name?: string;
  organizationType: OrganizationType;
  slug?: string;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
}

export interface LeagueResponsePagedData {
  list?: LeagueResponse[];
  pagination?: Pagination;
}

export interface LeagueScoreBoardRelatedResponse {
  filter?: ScoreboardGroupingFilterResponse;
  league?: LeagueResponse;
  phase?: SeasonPhaseFilterResponse;
  /** @format int32 */
  year: number;
}

export interface LockOracleRequest {
  locked: boolean;
}

export interface MailingAddress {
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
}

export interface MailingAddressPersonContactInfoMeta {
  value?: MailingAddress;
  verification?: Verification;
  visibility: Visibility;
}

export interface MailingAddressPersonContactInfoMetaRequest {
  value?: MailingAddress;
  verification?: Verification;
  visibility?: Visibility;
}

export interface MailingAddressPersonContactInfoMetaResponse {
  value?: MailingAddress;
  verification?: VerificationResponse;
  visibility: Visibility;
}

export enum MappingSource {
  Massey = 'Massey',
  MaxPreps = 'MaxPreps',
  CalPreps = 'CalPreps',
  Nfhs = 'Nfhs',
  Nces = 'Nces',
  TfsSports = 'TfsSports',
  Si = 'Si',
  Espn = 'Espn',
  TfsSportsCoach = 'TfsSportsCoach',
  Rivals = 'Rivals',
  CollegeContact = 'CollegeContact',
  NcaaId = 'NcaaId',
}

export interface MeasurementPersonResponseRecord {
  additionalTypes?: MeasurementTypeResponse[] | null;
  playerMeasurements?: MeasurementResponseRecord[] | null;
}

/**
 * Place to store what type of record that a person achieved with a measurement.
 * As this measurement can achieve multiple records at the same time, a flag makes the most sense here
 *   (i.e. - Personal Record, State Record, World Record)
 */
export enum MeasurementRecord {
  None = 'None',
  PersonalRecord = 'PersonalRecord',
  StateRecord = 'StateRecord',
  WorldRecord = 'WorldRecord',
}

export interface MeasurementRequest {
  /** @format int32 */
  dateOccurred: number;
  elite: boolean;
  /** @format int64 */
  eventKey?: number | null;
  /** @format int64 */
  key?: number | null;
  /** @format int64 */
  measurementTypeKey: number;
  /**
   * Place to store what type of record that a person achieved with a measurement.
   * As this measurement can achieve multiple records at the same time, a flag makes the most sense here
   *   (i.e. - Personal Record, State Record, World Record)
   */
  record: MeasurementRecord;
  value?: string | null;
  verified: boolean;
}

export interface MeasurementResponseRecord {
  /** @format int32 */
  dateAdded: number;
  /** @format int32 */
  dateModified: number;
  /** @format int32 */
  dateOccurred?: number | null;
  elite: boolean;
  /** @format int64 */
  eventKey?: number | null;
  eventName?: string | null;
  isCurrent: boolean;
  /** @format int64 */
  key: number;
  /** @format int64 */
  measurementTypeKey: number;
  /** @format int64 */
  personKey: number;
  /** @format int64 */
  personSportOrgKey?: number | null;
  /**
   * Place to store what type of record that a person achieved with a measurement.
   * As this measurement can achieve multiple records at the same time, a flag makes the most sense here
   *   (i.e. - Personal Record, State Record, World Record)
   */
  record: MeasurementRecord;
  type?: string;
  /** @format double */
  value?: number | null;
  verified: boolean;
  /** @format int64 */
  verifiedByUserKey?: number | null;
}

export interface MeasurementTypeResponse {
  isTrackAndField: boolean;
  /** @format int64 */
  key: number;
  lowerIsBetter: boolean;
  /** @format int64 */
  measurementTypeKey: number;
  sort: SortMeasurementType;
  type: string | null;
  units: string | null;
}

export interface MoneylineInfo {
  /** @format int32 */
  americanAwayOdds?: number | null;
  /** @format int32 */
  americanHomeOdds?: number | null;
  /** @format int64 */
  lastUpdatedUtc?: number | null;
  opening?: boolean | null;
  status?: string | null;
}

export enum NilStatuses {
  Normal = 'Normal',
  Pending = 'Pending',
  Suspended = 'Suspended',
  Private = 'Private',
  Hidden = 'Hidden',
  Show = 'Show',
  Frozen = 'Frozen',
  Final = 'Final',
}

export interface NoContentResult {
  /** @format int32 */
  statusCode: number;
}

export interface NotFoundResult {
  /** @format int32 */
  statusCode: number;
}

export enum On3AccessType {
  Unknown = 'Unknown',
  Monthly = 'Monthly',
  Annual = 'Annual',
}

export interface OracleBaseAdminResponse {
  /** @format double */
  confidence?: number | null;
  /** @format int32 */
  dateAdded: number;
  hidden: boolean;
  /** @format int64 */
  key: number;
  organizationAssetUrl?: string | null;
  organizationName?: string;
  premium: boolean;
  userName?: string | null;
}

export enum OracleStatus {
  Undetermined = 'Undetermined',
  Flipped = 'Flipped',
  Locked = 'Locked',
  Processed = 'Processed',
  Correct = 'Correct',
}

export interface OrgAutocompleteResponse {
  asset?: KeyUrlSlug;
  defaultAsset?: AssetResponse;
  /** @format int32 */
  key: number;
  organizationAssetUrl?: string | null;
  type?: string;
  value?: string;
}

export interface OrgAutocompleteResponsePagedData {
  list?: OrgAutocompleteResponse[];
  pagination?: Pagination;
}

export interface OrgRequest {
  /** @format double */
  act?: number | null;
  /** @format int32 */
  classYear?: number | null;
  /** @format double */
  gpa?: number | null;
  /** @format int32 */
  organizationKey: number;
  /** @format double */
  sat?: number | null;
  sports?: SpRequest[];
}

export interface OrgSingleAdminResponse {
  abbreviation?: string | null;
  address?: string | null;
  /** @format int32 */
  addressCityKey?: number | null;
  alternateAsset?: AssetResponse;
  /** @format int64 */
  alternateAssetKey?: number | null;
  alternateAssetUrl?: string | null;
  bio?: string | null;
  city?: string | null;
  defaultAsset?: AssetResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  defaultAssetUrl?: string | null;
  isForeign?: boolean | null;
  isPrivate?: boolean | null;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  /** @format int32 */
  latitude?: number | null;
  lightAsset?: AssetResponse;
  /** @format int64 */
  lightAssetKey?: number | null;
  lightAssetUrl?: string | null;
  /** @format int32 */
  longitude?: number | null;
  mascot?: string | null;
  /** @format int32 */
  masseyKey?: number | null;
  name?: string;
  orgType: OrganizationType;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  slug?: string | null;
  socials?: OrganizationSocialResponse;
  telephone?: string | null;
  website?: string | null;
  /** @format int32 */
  zipCode?: number | null;
}

export interface OrgSingleAdminResponseResourceResponseMessage {
  message?: string;
  resource?: OrgSingleAdminResponse;
  /** @format int32 */
  statusCode: number;
}

export interface OrgSportForOrgRes {
  /** @format int32 */
  key: number;
  /** @format int32 */
  organizationKey: number;
  sport: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
}

export interface OrgSportForOrgResOrganizationResponseRelatedPagedData {
  list?: OrgSportForOrgRes[];
  pagination?: Pagination;
  relatedModel?: OrganizationResponse;
}

export interface OrgSportHierarchyForOrgSportRes {
  /** @format int64 */
  key: number;
  orgTypeClassHierarchy: OrgTypeClassHierarchyRes;
  /** @format int32 */
  orgTypeClassHierarchyKey: number;
  /** @format int32 */
  organizationSportKey: number;
  /** @format int32 */
  year: number;
}

export interface OrgSportHierarchyForOrgSportResRelatedOrgSportHierarcyFilterResRelatedPagedData {
  list?: OrgSportHierarchyForOrgSportRes[];
  pagination?: Pagination;
  relatedModel?: RelatedOrgSportHierarcyFilterRes;
}

export interface OrgSportHierarchyYearRes {
  /** @format int32 */
  count: number;
  /** @format int32 */
  year: number;
}

export interface OrgSportHierarchyYearResFullOrgSportResRelatedPagedData {
  list?: OrgSportHierarchyYearRes[];
  pagination?: Pagination;
  relatedModel?: FullOrgSportRes;
}

export interface OrgTypeClassHierarchyRes {
  classificationType: ClassificationType;
  displayName: string;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name: string;
  organizationType: OrganizationType;
  /** @format int32 */
  parentOrgTypeClassHierarchyKey?: number | null;
  path?: string | null;
  /** @format int64 */
  relatedKey?: number | null;
  /** @format int32 */
  rootOrgTypeClassHierarchyKey?: number | null;
  status: OrgTypeClassHierarchyStatus;
}

export interface OrgTypeClassHierarchyResRelatedOrgTypeClassHierarcyFilterResRelatedPagedData {
  list?: OrgTypeClassHierarchyRes[];
  pagination?: Pagination;
  relatedModel?: RelatedOrgTypeClassHierarcyFilterRes;
}

export enum OrgTypeClassHierarchyStatus {
  Unknown = 'Unknown',
  Active = 'Active',
  Removed = 'Removed',
}

export interface OrganizationBase {
  abbreviation?: string | null;
  asset?: AssetResponse;
  assetUrl?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  primaryColor?: string | null;
  slug?: string | null;
}

export interface OrganizationKeyMappingRes {
  /** @format int32 */
  key: number;
  /** @format int32 */
  orgKey: number;
  source: MappingSource;
  sourceOrgKeyStr: string;
}

export interface OrganizationKeyMappingResOrganizationResponseRelatedPagedData {
  list?: OrganizationKeyMappingRes[];
  pagination?: Pagination;
  relatedModel?: OrganizationResponse;
}

export interface OrganizationRankingPhaseRequest {
  /** @format int32 */
  associatedPressPoints?: number | null;
  /** @format int32 */
  associatedPressRanking?: number | null;
  associatedPressTrend?: RankingTrend;
  /** @format int32 */
  associatedPressTrendNumber?: number | null;
  /** @format int32 */
  coachesPollPoints?: number | null;
  /** @format int32 */
  coachesPollRanking?: number | null;
  coachesPollTrend?: RankingTrend;
  /** @format int32 */
  coachesPollTrendNumber?: number | null;
  /** @format int64 */
  gameKey?: number | null;
  /** @format int32 */
  orgKey: number;
  /** @format int32 */
  playoffRanking?: number | null;
  playoffTrend?: RankingTrend;
  /** @format int32 */
  playoffTrendNumber?: number | null;
  /** @format int64 */
  seasonPhaseKey: number;
}

export interface OrganizationRankingPhaseResponse {
  /** @format int32 */
  associatedPressPoints?: number | null;
  /** @format int32 */
  associatedPressRanking?: number | null;
  associatedPressTrend?: RankingTrend;
  /** @format int32 */
  associatedPressTrendNumber?: number | null;
  /** @format int32 */
  coachesPollPoints?: number | null;
  /** @format int32 */
  coachesPollRanking?: number | null;
  coachesPollTrend?: RankingTrend;
  /** @format int32 */
  coachesPollTrendNumber?: number | null;
  organization?: OrganizationResponse;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  playoffRanking?: number | null;
  playoffTrend?: RankingTrend;
  /** @format int32 */
  playoffTrendNumber?: number | null;
  /** @format int64 */
  seasonPhaseKey?: number | null;
}

export interface OrganizationResponse {
  abbreviation?: string | null;
  assetUrl?: string | null;
  defaultAsset?: AssetResponse;
  division?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  mascot?: string | null;
  name?: string;
  orgType?: string;
  orgTypeEnum: OrganizationType;
  primaryColor?: string | null;
  siteKeys?: number[] | null;
  slug?: string | null;
  urlSlug?: string | null;
}

export interface OrganizationResponseOrgTypeClassHierarchyResRelatedPagedData {
  list?: OrganizationResponse[];
  pagination?: Pagination;
  relatedModel?: OrgTypeClassHierarchyRes;
}

export interface OrganizationSeasonRecordResponse {
  /** @format int32 */
  losses?: number | null;
  /** @format int32 */
  ties?: number | null;
  /** @format int32 */
  wins?: number | null;
}

export interface OrganizationSocialRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  athleticsInstagram?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  athleticsTwitter?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  basketballInstagram?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  basketballTwitter?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  footballInstagram?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  footballTwitter?: string | null;
}

export interface OrganizationSocialResponse {
  athleticsInstagram?: string | null;
  athleticsTwitter?: string | null;
  basketballInstagram?: string | null;
  basketballTwitter?: string | null;
  footballInstagram?: string | null;
  footballTwitter?: string | null;
  /** @format int32 */
  key: number;
}

export enum OrganizationType {
  Unknown = 'Unknown',
  HighSchool = 'HighSchool',
  JuniorCollege = 'JuniorCollege',
  College = 'College',
  Professional = 'Professional',
  PrepSchool = 'PrepSchool',
  Developmental = 'Developmental',
}

export interface OverUnderInfo {
  /** @format int64 */
  lastUpdatedUtc?: number | null;
  opening?: boolean | null;
  /** @format int32 */
  overOdds?: number | null;
  status?: string | null;
  /** @format double */
  totals?: number | null;
  /** @format int32 */
  underOdds?: number | null;
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export interface PersonBase {
  /** @format int64 */
  key: number;
  lastName?: string;
  name?: string;
  slug?: string;
}

export interface PersonConnectionFullResponse {
  connectedCollegeOrganization?: OrganizationResponse;
  connectedDraft?: DraftSimpleResponse;
  connectedPlayer?: SmallPlayerResponse;
  connectedRating?: RatingResponse;
  connectedRosterRating?: RatingResponse;
  connection?: PersonConnectionResponse;
}

export interface PersonConnectionFullResponsePagedData {
  list?: PersonConnectionFullResponse[];
  pagination?: Pagination;
}

export interface PersonConnectionRequest {
  /** @format int64 */
  connectedPersonKey: number;
  /** @minLength 1 */
  description: string;
  /** @format int32 */
  sportKey: number;
}

export interface PersonConnectionResponse {
  /** @format int64 */
  connectedPersonKey: number;
  connectedPersonSport?: PersonSportResponseRecord;
  description?: string;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  /** @format int32 */
  sportKey: number;
}

export interface PersonContactInfo {
  agentInfo?: AgentContactInfoPersonContactInfoMeta;
  birthDate?: Int64PersonContactInfoMetaStruct;
  coachInfo?: ContactInfoPersonContactInfoMeta;
  counselorInfo?: ContactInfoPersonContactInfoMeta;
  email?: StringPersonContactInfoMeta;
  instagramHandle?: StringPersonContactInfoMeta;
  mailingAddress?: MailingAddressPersonContactInfoMeta;
  parentInfo?: ContactInfoPersonContactInfoMeta;
  phoneNumber?: StringPersonContactInfoMeta;
  secondaryParentInfo?: ContactInfoPersonContactInfoMeta;
  tikTokHandle?: StringPersonContactInfoMeta;
  twitterHandle?: StringPersonContactInfoMeta;
  /** @format int32 */
  version: number;
}

export interface PersonContactInfoBatchResponse {
  contactInfo?: PersonContactInfoResponse;
  /** @format int64 */
  personKey: number;
}

export interface PersonContactInfoBatchResponsePagedData {
  list?: PersonContactInfoBatchResponse[];
  pagination?: Pagination;
}

export interface PersonContactInfoContributionResponse {
  /** @format int32 */
  contributions: number;
  displayName?: string | null;
  userLogin?: string;
}

export interface PersonContactInfoContributionResponsePagedData {
  list?: PersonContactInfoContributionResponse[];
  pagination?: Pagination;
}

export interface PersonContactInfoKPI {
  /** @format int32 */
  emailCount: number;
  /** @format int32 */
  phoneNumberCount: number;
}

export interface PersonContactInfoKPIResponse {
  name?: string;
  summary?: PersonContactInfoKPISummary;
}

export interface PersonContactInfoKPIResponsePagedData {
  list?: PersonContactInfoKPIResponse[];
  pagination?: Pagination;
}

export interface PersonContactInfoKPISummary {
  agent?: PersonContactInfoKPI;
  parent?: PersonContactInfoKPI;
  player?: PersonContactInfoKPI;
  /** @format int32 */
  totalCount: number;
}

export interface PersonContactInfoLogResponse {
  /** @format int64 */
  key: number;
  newValue?: PersonContactInfoResponse;
  oldValue?: PersonContactInfoResponse;
  /** @format int64 */
  personKey: number;
  /** @format date-time */
  updatedAt: string;
  /** @format int64 */
  userKey: number;
}

export interface PersonContactInfoLogResponsePagedData {
  list?: PersonContactInfoLogResponse[];
  pagination?: Pagination;
}

export interface PersonContactInfoRequest {
  agentInfo?: AgentContactInfoPersonContactInfoMetaRequest;
  birthDate?: Int64PersonContactInfoMetaStructRequest;
  coachInfo?: ContactInfoPersonContactInfoMetaRequest;
  counselorInfo?: ContactInfoPersonContactInfoMetaRequest;
  email?: StringPersonContactInfoMetaRequest;
  instagramHandle?: StringPersonContactInfoMetaRequest;
  mailingAddress?: MailingAddressPersonContactInfoMetaRequest;
  parentInfo?: ContactInfoPersonContactInfoMetaRequest;
  phoneNumber?: StringPersonContactInfoMetaRequest;
  secondaryParentInfo?: ContactInfoPersonContactInfoMetaRequest;
  tikTokHandle?: StringPersonContactInfoMetaRequest;
  twitterHandle?: StringPersonContactInfoMetaRequest;
}

export interface PersonContactInfoResponse {
  agentInfo?: AgentContactInfoPersonContactInfoMetaResponse;
  birthDate?: Int64PersonContactInfoMetaResponse;
  coachInfo?: ContactInfoPersonContactInfoMetaResponse;
  counselorInfo?: ContactInfoPersonContactInfoMetaResponse;
  email?: StringPersonContactInfoMetaResponse;
  instagramHandle?: StringPersonContactInfoMetaResponse;
  mailingAddress?: MailingAddressPersonContactInfoMetaResponse;
  parentInfo?: ContactInfoPersonContactInfoMetaResponse;
  phoneNumber?: StringPersonContactInfoMetaResponse;
  secondaryParentInfo?: ContactInfoPersonContactInfoMetaResponse;
  tikTokHandle?: StringPersonContactInfoMetaResponse;
  twitterHandle?: StringPersonContactInfoMetaResponse;
}

export interface PersonEducationResponse {
  /** @format int32 */
  act?: number | null;
  /** @format double */
  gpa?: number | null;
  highSchoolTranscript?: FileRes;
  highSchoolTranscriptUrl?: string | null;
  intendedMajor?: string | null;
  /** @format int64 */
  key: number;
  /** @format int32 */
  sat?: number | null;
}

export interface PersonKeyMappingRes {
  /** @format int32 */
  key: number;
  /** @format int64 */
  perKey: number;
  source: MappingSource;
  sourcePerKeyStr: string;
}

export interface PersonKeyMappingResSimplePersonResponseRelatedPagedData {
  list?: PersonKeyMappingRes[];
  pagination?: Pagination;
  relatedModel?: SimplePersonResponse;
}

export interface PersonRelationResponse {
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  relatedPerson: RelatedPersonResponse;
  /** @format int32 */
  relationType: number;
}

export interface PersonRelationTypeRes {
  /** @format int32 */
  key: number;
  name: string;
}

export interface PersonSportBase {
  /** @format int64 */
  key: number;
  name?: string | null;
}

export interface PersonSportOrganizationBase {
  /** @format int64 */
  key: number;
  name?: string;
}

export interface PersonSportResponseRecord {
  /** @format int64 */
  currentPersonSportRankingKey?: number | null;
  /** @format int64 */
  currentRosterPersonSportRankingKey?: number | null;
  defaultSport: boolean;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
}

export interface PersonSportResponseRecordPagedData {
  list?: PersonSportResponseRecord[];
  pagination?: Pagination;
}

export interface PersonSportResponseRecordResourceResponseMessage {
  message?: string;
  resource?: PersonSportResponseRecord;
  /** @format int32 */
  statusCode: number;
}

export interface PersonStatusBulkRequest {
  /**
   * @maxItems 1000
   * @minItems 1
   */
  personKeys: number[];
  reviewStatus: PersonStatusReviewStatus;
  tier: PersonStatusTier;
  visibility: PersonStatusVisibility;
}

export interface PersonStatusListResponse {
  person: SimplePersonResponse;
  reviewStatus: PersonStatusReviewStatus;
  tier: PersonStatusTier;
  visibility: PersonStatusVisibility;
}

export interface PersonStatusListResponsePagedData {
  list?: PersonStatusListResponse[];
  pagination?: Pagination;
}

export interface PersonStatusRequest {
  reviewStatus: PersonStatusReviewStatus;
  tier: PersonStatusTier;
  visibility: PersonStatusVisibility;
}

export interface PersonStatusResponse {
  /** @format int64 */
  personKey: number;
  reviewStatus: PersonStatusReviewStatus;
  tier: PersonStatusTier;
  visibility: PersonStatusVisibility;
}

export interface PersonStatusResponsePagedData {
  list?: PersonStatusResponse[];
  pagination?: Pagination;
}

export enum PersonStatusReviewStatus {
  Unreviewed = 'Unreviewed',
  Reviewed = 'Reviewed',
}

export enum PersonStatusTier {
  Base = 'Base',
  Emerging = 'Emerging',
  Star = 'Star',
  Unknown = 'Unknown',
  Elite = 'Elite',
}

export enum PersonStatusVisibility {
  None = 'None',
  PublicWithArticleTaggingDisabled = 'PublicWithArticleTaggingDisabled',
  PublicWithArticleTaggingEnabled = 'PublicWithArticleTaggingEnabled',
}

export interface PersonTargetRequest {
  coachingVisits?: CoachingVisitRequest[];
  events?: RecruitmentInterestEventBase[];
  /** @format int64 */
  key?: number | null;
  offer: boolean;
  organizationAssetUrl?: string | null;
  /** @format int32 */
  organizationInterestLevel?: number | null;
  /** @format int32 */
  organizationKey: number;
  organizationName?: string;
  /** @format int32 */
  positionKey: number;
  /** @format int64 */
  primaryPersonKey?: number | null;
  primaryPersonName?: string | null;
  /** @format int32 */
  recruitOrder: number;
  /** @format int64 */
  secondaryPersonKey?: number | null;
  secondaryPersonName?: string | null;
  status: RecruitmentStatus;
  target: boolean;
  transferredIn: boolean;
  transferredOut: boolean;
  visits?: RecruitmentInterestVisitBase[];
  /** @format int32 */
  year: number;
}

export interface PersonTargetResponse {
  interestLevel?: number[];
  positions?: PositionBase[];
  recruitmentInterests?: RecruitmentInterestBase[] | null;
  statusOptions?: string[];
}

export interface PersonWithSocial {
  /** @format int32 */
  classYear: number;
  espnProfile?: string | null;
  firstName?: string;
  instagramProfile?: string | null;
  /** @format int64 */
  key: number;
  knownAsName?: string | null;
  lastName?: string;
  rivalsProfile?: string | null;
  tikTokHandle?: string | null;
  twitterHandle?: string | null;
  twoFourSevenProfile?: string | null;
}

export interface PhaseScoreResponse {
  /** @format int32 */
  away?: number | null;
  /** @format int32 */
  home?: number | null;
  name?: string;
}

export interface PickerAutocompleteResponse {
  /** @format int64 */
  key: number;
  value: string;
}

export interface PickerResponse {
  adminDisplayName?: string;
  affiliation?: string | null;
  /** @format int64 */
  defaultAssetKey?: number | null;
  defaultAssetUrl?: string | null;
  displayName?: string;
  firstName?: string | null;
  isGuest: boolean;
  isMystery: boolean;
  /** @format int64 */
  key: number;
  lastName?: string | null;
  /** @format int32 */
  mysteryIndex: number;
  title?: string | null;
}

export interface PlayerItemContactInfoResponse {
  contactInfo?: PersonContactInfo;
  /**
   * Date a person decommitted. Only relevant when looking at decommits
   * @format date-time
   */
  dateDecommitted?: string | null;
  industryComparison?: PlayerRatingAllResponse[] | null;
  nilValue?: ValuationResponse;
  organization?: OrganizationResponse;
  player?: PlayerResponse;
  /** @format int64 */
  psoKey?: number | null;
  rating?: RatingResponse;
  /** @format int64 */
  recKey?: number | null;
  rosterRating?: RatingResponse;
  rpm?: RpmPercentResponse[] | null;
  status?: RecStatusResponse;
  transferRating?: RatingResponse;
}

export interface PlayerItemContactInfoResponsePagedData {
  list?: PlayerItemContactInfoResponse[];
  pagination?: Pagination;
}

export interface PlayerListBase {
  athleteVerified: boolean;
  /** @format int32 */
  classYear?: number | null;
  commitStatus?: Status;
  defaultAsset?: ImageResponse;
  defaultAssetUrl?: string | null;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  height?: string | null;
  highSchool?: OrganizationResponse;
  highSchoolName?: string | null;
  homeTownName?: string | null;
  /** @format int64 */
  key: number;
  name?: string;
  nilStatus: NilStatuses;
  /** @format double */
  nilValue?: number | null;
  positionAbbreviation?: string | null;
  predictions?: RpmResponse[] | null;
  prospectVerified: boolean;
  rating?: RatingBase;
  /** @format int64 */
  recruitmentKey?: number | null;
  rosterRating?: RatingBase;
  slug?: string;
  sport?: SportBase;
  /** @format double */
  weight?: number | null;
}

export interface PlayerProfileAdminResponse {
  /** @format int32 */
  age?: number | null;
  athleteVerified: boolean;
  bioCollegeRecruit?: string | null;
  bioProProspect?: string | null;
  /** @format int32 */
  classYear: number;
  /** @format double */
  codeGeneratedVerifiedDate?: number | null;
  /** @format int64 */
  collegeOrgKey?: number | null;
  currentOrg?: SimpleOrganizationResponse;
  currentState?: StateResponse;
  defaultAsset?: ImageResponse;
  defaultSport?: SportBase;
  degree?: string | null;
  description?: string | null;
  email?: string | null;
  height?: string | null;
  highSchool?: OrganizationResponse;
  highSchoolName?: string | null;
  /** @format int64 */
  highSchoolOrgKey?: number | null;
  hometownName?: string | null;
  hometownState?: StateResponse;
  /** @format int64 */
  juniorCollegeOrgKey?: number | null;
  /** @format int64 */
  key: number;
  name?: string;
  /** @format double */
  nilValue?: number | null;
  /** @format int64 */
  oracleKey?: number | null;
  organizationLevel?: string | null;
  /** @format int64 */
  personSportKey?: number | null;
  positionAbbreviation?: string | null;
  /** @format int64 */
  prepSchoolOrgKey?: number | null;
  ranking?: RatingBase;
  /** @format int64 */
  rankingKey?: number | null;
  /** @format int64 */
  recruitmentKey?: number | null;
  rosterRanking?: RatingResponse;
  slug?: string;
  /** @format double */
  socialTokenVerifiedDate?: number | null;
  sports?: SportBase[];
  username?: string | null;
  valuation?: ValuationResponse;
  /** @format double */
  weight?: number | null;
}

export interface PlayerProfileAdminResponsePagedData {
  list?: PlayerProfileAdminResponse[];
  pagination?: Pagination;
}

export interface PlayerRatingAllResponse {
  change?: Record<string, PlayerRatingChange>;
  /** @format int32 */
  classYear?: number | null;
  fiveStarPlus: boolean;
  link?: string | null;
  nearlyFiveStarPlus: boolean;
  /** @format int32 */
  overallRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format int32 */
  rankingKey?: number | null;
  rankingType: RankingType;
  /** @format int32 */
  rankingYear: number;
  /** @format double */
  rating?: number | null;
  sport?: string | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
  type?: string;
}

export enum PlayerRatingChange {
  Decrease = 'Decrease',
  None = 'None',
  Increase = 'Increase',
}

export interface PlayerResponse {
  /** @format int32 */
  age?: number | null;
  athleteVerified: boolean;
  classRank?: string | null;
  /** @format int32 */
  classYear?: number | null;
  /** @format date-time */
  dateOfBirth?: string | null;
  defaultAsset?: AssetResponse;
  defaultSport?: AttributeResponse;
  division?: string | null;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  firstName?: string;
  fullName?: string;
  height?: string | null;
  highSchool?: OrganizationResponse;
  highSchoolName?: string | null;
  hometown?: AttributeResponse;
  /** @format int32 */
  jerseyNumber?: number | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  position?: AttributeResponse;
  prospectVerified: boolean;
  /** @format int64 */
  recruitmentKey?: number | null;
  slug?: string;
  state?: AttributeResponse;
  /** @format double */
  weight?: number | null;
}

export interface PointSpreadInfo {
  /** @format int32 */
  americanAwayOdds?: number | null;
  /** @format int32 */
  americanHomeOdds?: number | null;
  /** @format double */
  awayHandicap?: number | null;
  /** @format double */
  homeHandicap?: number | null;
}

export interface PositionBase {
  abbr?: string | null;
  /** @format int32 */
  key: number;
  name?: string | null;
}

export interface PositionRequest {
  /**
   * @minLength 0
   * @maxLength 8
   */
  abbreviation?: string | null;
  /** @format int64 */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  positionType: PositionType;
  /** @format int32 */
  sportKey?: number | null;
}

export interface PositionResponse {
  abbreviation?: string | null;
  /** @format int32 */
  key: number;
  name?: string;
  positionType: PositionType;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey?: number | null;
}

export interface PositionResponseResourceResponseMessage {
  message?: string;
  resource?: PositionResponse;
  /** @format int32 */
  statusCode: number;
}

export enum PositionType {
  Player = 'Player',
  Coach = 'Coach',
  Operations = 'Operations',
}

export interface PredictionAccuracies {
  all?: PredictionAccuracy;
  year?: PredictionAccuracy;
}

export interface PredictionAccuracy {
  /** @format double */
  averageDaysCorrect?: number | null;
  /** @format double */
  correct: number;
  /** @format double */
  percentCorrect?: number | null;
  /** @format double */
  total: number;
}

export interface PrivateOpenSearchResponse {
  /** @format int32 */
  age?: number | null;
  articleTaggingEnabled?: boolean | null;
  athleteVerified: boolean;
  classRank?: string;
  /**
   * The person's high school class year
   * @format int32
   */
  classYear?: number | null;
  college?: OrganizationResponse;
  collegeName?: string | null;
  currentOrganization?: OrganizationBase;
  /** @format date-time */
  dateOfBirth?: string | null;
  defaultAsset?: ImageResponse;
  /** The url of the person's default asset */
  defaultAssetUrl?: string | null;
  defaultSport?: SportResponseRecord;
  division?: string | null;
  /** Did the person enroll early */
  earlyEnrollee: boolean;
  /** Did the person sign early */
  earlySignee: boolean;
  firstName?: string;
  /** The person's height in Feet and Inches */
  formattedHeight?: string | null;
  goesBy?: string | null;
  /**
   * The person's height in inches
   * @format double
   */
  height?: number | null;
  highSchool?: OrganizationResponse;
  /** The name of the High School the person attended */
  highSchoolName?: string | null;
  /** The name of the person's hometown */
  homeTownName?: string | null;
  hometown?: CityResponseRecord;
  /** Comma separated latitude and longitude for OpenSearch. e.g. "39.9526, -75.1652" */
  hometownLatLong?: string | null;
  isDraftPick?: boolean | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  /** The full name of the person */
  name?: string;
  /** @format int64 */
  nilValuation?: number | null;
  officialVisits?: SlimVisitResponse[] | null;
  organizationLevel?: OrganizationType;
  personSportOrganizations?: SlimPsoRes[] | null;
  position?: PositionResponse;
  /** The abbreviation of the person's primary position */
  positionAbbreviation?: string | null;
  positionTypes?: PositionType[] | null;
  predictions?: RpmResponse[] | null;
  prospectVerified: boolean;
  rating?: RatingExtendedResponse;
  recruitmentInterests?: RecruitmentInterestResponse[] | null;
  /** @format int64 */
  recruitmentKey?: number | null;
  reviewStatus?: PersonStatusReviewStatus;
  /** The slug of the person */
  slug?: string;
  sport?: SportResponseRecord;
  status?: Status;
  tier?: PersonStatusTier;
  visibility?: PersonStatusVisibility;
  /**
   * The person's weight in pounds
   * @format double
   */
  weight?: number | null;
}

export interface PrivateOpenSearchResponsePagedData {
  list?: PrivateOpenSearchResponse[];
  pagination?: Pagination;
}

export interface ProblemDetails {
  detail?: string | null;
  instance?: string | null;
  /** @format int32 */
  status?: number | null;
  title?: string | null;
  type?: string | null;
  [key: string]: any;
}

export interface PsoRequest {
  /** @format int64 */
  agencyKey?: number | null;
  /** @format int64 */
  agentKey?: number | null;
  blueShirt: boolean;
  deceased: boolean;
  /** @format int32 */
  depth?: number | null;
  draftParticipant: boolean;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  endOfTeam: boolean;
  fired: boolean;
  freeTransferYear: boolean;
  freeYear: boolean;
  grayShirt: boolean;
  /** @format int32 */
  jerseyNumber?: number | null;
  medical: boolean;
  noAgent: boolean;
  optedOut: boolean;
  positionKeys?: number[];
  promoted: boolean;
  redShirt: boolean;
  resigned: boolean;
  /** @format double */
  salary?: number | null;
  salaryUrl?: string | null;
  /** @format int64 */
  secondaryAgencyKey?: number | null;
  /** @format int64 */
  secondaryAgentKey?: number | null;
  transfer: boolean;
  /** @format date-time */
  transferDate?: string | null;
  walkOn: boolean;
  withdrawnTransfer: boolean;
  /** @format date-time */
  withdrawnTransferDate?: string | null;
  /** @format int32 */
  year: number;
  /**
   * @format int32
   * @min 1
   * @max 10
   */
  yearOrder: number;
}

export interface PsoResponse {
  /** @format int64 */
  assetKey?: number | null;
  blueShirt: boolean;
  classYear?: ClassYear;
  deceased: boolean;
  /** @format int32 */
  depth?: number | null;
  draftParticipant: boolean;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  endOfTeam: boolean;
  fired: boolean;
  freeTransferYear: boolean;
  freeYear: boolean;
  grayShirt: boolean;
  /** @format int32 */
  jerseyNumber?: number | null;
  /** @format int64 */
  key: number;
  medical: boolean;
  optedOut: boolean;
  /** @format int32 */
  organizationKey: number;
  /** @format int64 */
  personSportKey: number;
  /** @format int32 */
  primaryPositionKey?: number | null;
  promoted: boolean;
  redShirt: boolean;
  resigned: boolean;
  /** @format double */
  salary?: number | null;
  /** @format int32 */
  secondaryPositionKey?: number | null;
  transfer: boolean;
  /** @format date-time */
  transferDate?: string | null;
  walkOn: boolean;
  withdrawnTransfer: boolean;
  /** @format date-time */
  withdrawnTransferDate?: string | null;
  /** @format int32 */
  year: number;
  /** @format int32 */
  yearOrder: number;
}

export interface PublicOpenSearchResponse {
  /** @format int32 */
  age?: number | null;
  articleTaggingEnabled?: boolean | null;
  athleteVerified: boolean;
  classRank?: string;
  /**
   * The person's high school class year
   * @format int32
   */
  classYear?: number | null;
  college?: OrganizationResponse;
  collegeName?: string | null;
  currentOrganization?: OrganizationBase;
  /** @format date-time */
  dateOfBirth?: string | null;
  defaultAsset?: ImageResponse;
  /** The url of the person's default asset */
  defaultAssetUrl?: string | null;
  defaultSport?: SportResponseRecord;
  division?: string | null;
  /** Did the person enroll early */
  earlyEnrollee: boolean;
  /** Did the person sign early */
  earlySignee: boolean;
  firstName?: string;
  /** The person's height in Feet and Inches */
  formattedHeight?: string | null;
  goesBy?: string | null;
  /**
   * The person's height in inches
   * @format double
   */
  height?: number | null;
  highSchool?: OrganizationResponse;
  /** The name of the High School the person attended */
  highSchoolName?: string | null;
  /** The name of the person's hometown */
  homeTownName?: string | null;
  hometown?: CityResponseRecord;
  /** Comma separated latitude and longitude for OpenSearch. e.g. "39.9526, -75.1652" */
  hometownLatLong?: string | null;
  isDraftPick?: boolean | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  /** The full name of the person */
  name?: string;
  /** @format int64 */
  nilValuation?: number | null;
  officialVisits?: SlimVisitResponse[] | null;
  organizationLevel?: OrganizationType;
  personSportOrganizations?: SlimPsoRes[] | null;
  position?: PositionResponse;
  /** The abbreviation of the person's primary position */
  positionAbbreviation?: string | null;
  positionTypes?: PositionType[] | null;
  predictions?: RpmResponse[] | null;
  prospectVerified: boolean;
  rating?: RatingExtendedResponse;
  recruitmentInterests?: RecruitmentInterestResponse[] | null;
  /** @format int64 */
  recruitmentKey?: number | null;
  /** The slug of the person */
  slug?: string;
  sport?: SportResponseRecord;
  status?: Status;
  /**
   * The person's weight in pounds
   * @format double
   */
  weight?: number | null;
}

export interface PublicOpenSearchResponsePagedData {
  list?: PublicOpenSearchResponse[];
  pagination?: Pagination;
}

export interface PublicOrganizationSearchResponse {
  abbreviation?: string | null;
  assetUrl?: string | null;
  city?: CityResponseRecord;
  defaultAsset?: AssetResponse;
  division?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  lcos?: SimpleLcoRes[] | null;
  mascot?: string | null;
  name?: string;
  orgType?: string;
  orgTypeEnum: OrganizationType;
  primaryColor?: string | null;
  siteKeys?: number[] | null;
  sites?: SimpleSiteResponseRecord[] | null;
  slug?: string | null;
  urlSlug?: string | null;
}

export interface PublicOrganizationSearchResponsePagedData {
  list?: PublicOrganizationSearchResponse[];
  pagination?: Pagination;
}

/** Specifies the caegories quotes can be. */
export enum QuoteCategory {
  Inspirational = 'Inspirational',
  Motivational = 'Motivational',
  Funny = 'Funny',
  Leadership = 'Leadership',
  Success = 'Success',
}

export interface QuoteRequest {
  /** @minLength 1 */
  body: string;
  /** Specifies the caegories quotes can be. */
  category: QuoteCategory;
  /** @format date-time */
  dateAdded?: string | null;
  /** @format int64 */
  personKey?: number | null;
}

export interface QuoteResponse {
  body?: string;
  /** Specifies the caegories quotes can be. */
  category: QuoteCategory;
  /** @format date-time */
  dateAdded: string;
  /** @format date-time */
  dateUpdated: string;
  /** @format int64 */
  key: number;
  person?: PersonWithSocial;
  /** @format int64 */
  personKey?: number | null;
}

export interface RankInformationResponse {
  /** @format int32 */
  associatedPressRanking?: number | null;
  /** @format int32 */
  coachesPollRanking?: number | null;
  conferenceAbbreviation?: string | null;
  conferenceRecord?: string | null;
  /** @format int32 */
  nationalRank?: number | null;
  record?: string | null;
  /** @format int32 */
  stateRank?: number | null;
}

export enum RankingTrend {
  NoChange = 'NoChange',
  Up = 'Up',
  Down = 'Down',
}

export enum RankingType {
  Player = 'Player',
  AllTimePlayer = 'AllTimePlayer',
  TransferPortal = 'TransferPortal',
  AllTimeTransferPortal = 'AllTimeTransferPortal',
  Draft = 'Draft',
  AllTimeDraft = 'AllTimeDraft',
  Organization = 'Organization',
  AllTimeOrganization = 'AllTimeOrganization',
  Roster = 'Roster',
  AllTimeRoster = 'AllTimeRoster',
  TeamTransferPortal = 'TeamTransferPortal',
  AllTimeTeamTransferPortal = 'AllTimeTeamTransferPortal',
  TeamComposite = 'TeamComposite',
  AllTimeTeamComposite = 'AllTimeTeamComposite',
  ProjectedImpact = 'ProjectedImpact',
}

export interface RatingBase {
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  nationalRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format double */
  rating?: number | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
}

export interface RatingExtendedResponse {
  /** @format int32 */
  consensusNationalRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars?: number | null;
  /** @format int32 */
  consensusStateRank?: number | null;
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  nationalRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format double */
  rating?: number | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
}

export interface RatingResponse {
  /** @format int32 */
  consensusNationalRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars?: number | null;
  /** @format int32 */
  consensusStateRank?: number | null;
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  nationalRank?: number | null;
  nearlyFiveStarPlus: boolean;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format double */
  rating?: number | null;
  sport?: AttributeResponse;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
  /** @format int32 */
  year?: number | null;
}

export interface RatingResponseApi {
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  nationalRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format double */
  rating?: number | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
}

export interface RecStatusResponse {
  committedAsset?: OrganizationResponse;
  /** @format date-time */
  date?: string | null;
  decommittedAsset?: OrganizationResponse;
  draft: boolean;
  /** @format int32 */
  recruitmentYear?: number | null;
  shortTermSignee: boolean;
  transfer: boolean;
  /** @format date-time */
  transferEntered?: string | null;
  /** @format date-time */
  transferWithdrawn?: string | null;
  transferredAsset?: OrganizationResponse;
  type?: string;
}

export interface RecruitmentArticlesRequest {
  committedArticleUrl?: string | null;
  enteredArticleUrl?: string | null;
  exitedArticleUrl?: string | null;
}

export interface RecruitmentArticlesResponse {
  committedArticle?: SimpleHeadlineResponse;
  enteredArticle?: SimpleHeadlineResponse;
  exitedArticle?: SimpleHeadlineResponse;
}

export interface RecruitmentBreakdownSummaryResponse {
  /** @format int32 */
  fiveStars: number;
  /** @format int32 */
  fourStars: number;
  /** @format int64 */
  key: number;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  threeStars: number;
  /** @format int32 */
  total: number;
  /** @format int32 */
  year: number;
  /** @format int32 */
  zeroStars: number;
}

export interface RecruitmentEvaluationResponse {
  /** @format int64 */
  authorKey: number;
  authorName?: string | null;
  authorTitle?: string | null;
  body: string;
  category?: ScoutingCategory;
  /** @format date-time */
  dateAdded: string;
  /** @format date-time */
  dateUpdated: string;
  /** @format int32 */
  dateUpdatedUnix: number;
  /** @format int64 */
  key: number;
  premium: boolean;
  primary: boolean;
  /** @format int64 */
  recruitmentKey: number;
  title: string;
}

export interface RecruitmentInterestBase {
  coachingVisits?: CoachingVisitResponse[];
  events?: RecruitmentInterestEventBase[];
  /** @format int64 */
  key: number;
  offer: boolean;
  organizationAssetUrl?: string | null;
  /** @format int32 */
  organizationInterestLevel?: number | null;
  /** @format int32 */
  organizationKey: number;
  organizationName?: string;
  /** @format int32 */
  positionKey: number;
  /** @format int64 */
  primaryPersonKey?: number | null;
  primaryPersonName?: string | null;
  /** @format int32 */
  recruitOrder: number;
  /** @format int64 */
  secondaryPersonKey?: number | null;
  secondaryPersonName?: string | null;
  status: RecruitmentStatus;
  target: boolean;
  transferredIn: boolean;
  transferredOut: boolean;
  visits?: RecruitmentInterestVisitBase[];
  /** @format int32 */
  year: number;
}

export interface RecruitmentInterestEventBase {
  /** @format date-time */
  dateOccurred?: string | null;
  /** @format int64 */
  key?: number | null;
  type: RecruitmentStatus;
}

export interface RecruitmentInterestResponse {
  /** @format int64 */
  key: number;
  /** @format int64 */
  latestEventKey?: number | null;
  offer: boolean;
  /** @format int32 */
  organizationInterestLevel?: number | null;
  /** @format int32 */
  organizationKey: number;
  /** @format int64 */
  personSportKey?: number | null;
  /** @format int32 */
  positionKey: number;
  /** @format int64 */
  primaryPersonKey?: number | null;
  /** @format int64 */
  recruitmentKey: number;
  /** @format int64 */
  secondaryPersonKey?: number | null;
  status: RecruitmentStatus;
  /** @format int32 */
  year: number;
}

export interface RecruitmentInterestResponsePagedData {
  list?: RecruitmentInterestResponse[];
  pagination?: Pagination;
}

export interface RecruitmentInterestVisitBase {
  /** @format date-time */
  dateOccurred: string;
  /** @format int64 */
  key?: number | null;
  official: boolean;
}

export interface RecruitmentResponse {
  /** @format date-time */
  announcementDate?: string | null;
  draft: boolean;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  /** @format int32 */
  interestCount: number;
  /** @format int64 */
  key: number;
  maxInterestLevel?: InterestLevel;
  oracleLocked: boolean;
  /** @format int32 */
  organizationKey?: number | null;
  /** @format int64 */
  personSportKey: number;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  /** @format int64 */
  primaryInterestKey?: number | null;
  /** @format int64 */
  rpmOneKey?: number | null;
  /** @format int64 */
  rpmTwoKey?: number | null;
  shortTermSignee: boolean;
  showRpmPercentage?: boolean | null;
  status: RecruitmentStatus;
  transfer: boolean;
  /** @format int32 */
  year: number;
}

export interface RecruitmentResponsePagedData {
  list?: RecruitmentResponse[];
  pagination?: Pagination;
}

export enum RecruitmentStatus {
  None = 'None',
  Decommitted = 'Decommitted',
  Committed = 'Committed',
  Signed = 'Signed',
  Enrolled = 'Enrolled',
}

export interface RecruitmentStatusResponse {
  /** @format date-time */
  commitmentDate?: string | null;
  committedOrganizationAsset?: ImageResponse;
  committedOrganizationAssetUrl?: string | null;
  /** @format int64 */
  committedOrganizationKey?: number | null;
  committedOrganizationPrimaryColor?: string | null;
  committedOrganizationSlug?: string | null;
  /** @format int32 */
  highestInterestLevel?: number | null;
  /** @format int32 */
  interestCount: number;
  isCommitted: boolean;
  isEnrolled: boolean;
  isSigned: boolean;
  isTransfer: boolean;
  /** @format int32 */
  recruitmentYear: number;
  shortTermSignee: boolean;
  sportName?: string | null;
  transferredFromOrganizationAssetUrl?: string | null;
  transferredFromOrganizationSlug?: string | null;
}

export interface RecruitmentYearRequest {
  /** @format int32 */
  year: number;
}

export interface RelatedOrgSportHierarcyFilterRes {
  organizationSport: FullOrgSportRes;
  /** @format int32 */
  year: number;
}

export interface RelatedOrgTypeClassHierarcyFilterRes {
  childOrgTypeClassHierarchy?: OrgTypeClassHierarchyRes;
  classificationType?: ClassificationType;
  isRankable?: boolean | null;
  organizationType: OrganizationType;
  parentOrgTypeClassHierarchy?: OrgTypeClassHierarchyRes;
  /** @format int32 */
  sportKey?: number | null;
  status: OrgTypeClassHierarchyStatus;
}

export interface RelatedPersonResponse {
  /** @format int64 */
  key: number;
  name?: string;
}

export interface RelatedScheduleResponse {
  /** @format int32 */
  orgKey: number;
  organization?: OrganizationResponse;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface RelatedTeamRankingResponse {
  childClassifications?: OrgTypeClassHierarchyRes[] | null;
  /** @format int32 */
  conferenceKey?: number | null;
  currentClassification?: OrgTypeClassHierarchyRes;
  filter?: string | null;
  /** @format int64 */
  lastUpdated?: number | null;
  /** @format int32 */
  orgTypeClassHierarchyKey?: number | null;
  organizationType: OrganizationType;
  siblingClassifications?: OrgTypeClassHierarchyRes[] | null;
  /** @format int32 */
  sportKey?: number | null;
  /** @format int32 */
  stateKey?: number | null;
  /** @format int32 */
  totalResults: number;
  /** @format int32 */
  year?: number | null;
}

export interface RenOrgSportBodyReq {
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  organizationKey: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  sportKey: number;
}

export interface RenOrgSportHierarchyReq {
  /** @format int64 */
  key?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  orgTypeClassHierarchyKey: number;
  /** @format int32 */
  organizationKey?: number | null;
  /** @format int32 */
  organizationSportKey?: number | null;
  /** @format int32 */
  sportKey?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  year: number;
}

export interface RenOrgTypeClassHierarchyReq {
  classificationType: ClassificationType;
  /**
   * @minLength 0
   * @maxLength 300
   */
  displayName: string;
  isRankable: boolean;
  /** @format int32 */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 300
   */
  name: string;
  organizationType: OrganizationType;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  parentOrgTypeClassHierarchyKey?: number | null;
  /**
   * @format int64
   * @min 1
   */
  relatedKey?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  rootOrgTypeClassHierarchyKey?: number | null;
  status: OrgTypeClassHierarchyStatus;
}

export interface RenOrganizationKeyMappingReq {
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  key?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  organizationKey: number;
  source: MappingSource;
  /**
   * @minLength 1
   * @maxLength 255
   */
  sourceOrgKeyStr: string;
}

export interface RenPersonKeyMappingReq {
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  key?: number | null;
  /**
   * @format int64
   * @min 1
   */
  personKey: number;
  source: MappingSource;
  /**
   * @minLength 1
   * @maxLength 255
   */
  sourcePerKeyStr: string;
}

export interface RenSportReq {
  /**
   * @minLength 0
   * @maxLength 8
   */
  abbreviation?: string;
  isIndustryRankable: boolean;
  isRankable: boolean;
  /** @format int64 */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
}

export interface RenovateConferenceReq {
  /**
   * @minLength 0
   * @maxLength 35
   */
  abbreviation?: string | null;
  /** @format int64 */
  assetKey?: number | null;
  isGroupOfFive: boolean;
  isPowerFive: boolean;
  /** @format int32 */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /** @format int32 */
  sportKey: number;
}

export interface RenovateConferencesReq {
  conferences?: RenovateConferenceReq[];
  /** @format int64 */
  userKey?: number | null;
}

export interface RenovateDraftPickRequest {
  draftPicks?: DraftPickRequest[];
}

export interface RenovateDraftRequest {
  drafts?: DraftRequest[];
}

export interface RenovateGameByMatchIdRequest {
  /** @minLength 1 */
  matchId: string;
}

export interface RenovateLeagueConferenceOrganizationReq {
  /**
   * @format int64
   * @min 1
   */
  key?: number | null;
  /**
   * @format int64
   * @min 1
   */
  leagueConferenceKey: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  organizationKey: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  year: number;
}

export interface RenovateLeagueConferenceOrganizationsReq {
  leagueConferenceOrganizations?: RenovateLeagueConferenceOrganizationReq[];
  /** @format int64 */
  userKey?: number | null;
}

export interface RenovateLeagueConferenceReq {
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  conferenceKey: number;
  /**
   * @format int64
   * @min 1
   */
  key?: number | null;
  /**
   * @format int64
   * @min 1
   */
  leagueKey: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  sportKey: number;
}

export interface RenovateLeagueConferencesReq {
  leagueConferences?: RenovateLeagueConferenceReq[];
  /** @format int64 */
  userKey?: number | null;
}

export interface RenovateLeagueRequest {
  leagues?: LeagueRequest[];
  /** @format int64 */
  userKey?: number | null;
}

export interface RenovateOrgRequest {
  organizations?: OrgRequest[];
}

export interface RenovateRopRequest {
  /**
   * @minLength 0
   * @maxLength 100
   */
  affiliation?: string;
  /** @format int64 */
  defaultAssetKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 45
   */
  firstName?: string;
  isGuest: boolean;
  isMystery: boolean;
  /** @format int64 */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 45
   */
  lastName?: string;
  /** @format int32 */
  mysteryIndex: number;
  /**
   * @minLength 0
   * @maxLength 100
   */
  title?: string;
}

export interface RenovateVideoHighlightRequest {
  isFeatured: boolean;
  /** @format int64 */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  sourceUrl: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  title?: string | null;
}

export interface ReorAdminResponse {
  committed: boolean;
  pickers?: PersonBase[];
  picks?: OracleBaseAdminResponse[];
}

export interface ResponseMessage {
  message?: string;
  /** @format int32 */
  statusCode: number;
}

export interface RosterResponse {
  agency?: AgencyResponse;
  agent?: AgentPersonResponse;
  blueShirt: boolean;
  deceased: boolean;
  /** @format int32 */
  depthChart?: number | null;
  draftParticipant: boolean;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  endOfTeam: boolean;
  fired: boolean;
  freeTransferYear: boolean;
  freeYear: boolean;
  grayShirt: boolean;
  /** @format int32 */
  jerseyNumber?: number | null;
  /** @format int64 */
  key: number;
  medical: boolean;
  noAgent: boolean;
  optedOut: boolean;
  /** @format int32 */
  positionKey?: number | null;
  positionName?: string | null;
  positionType: PositionType;
  positionTypeName?: string;
  promoted: boolean;
  redShirt: boolean;
  resigned: boolean;
  /** @format double */
  salary?: number | null;
  salaryUrl?: string | null;
  secondaryAgency?: AgencyResponse;
  secondaryAgent?: AgentPersonResponse;
  /** @format int32 */
  secondaryPositionKey?: number | null;
  secondaryPositionName?: string | null;
  transfer: boolean;
  /** @format date-time */
  transferDate?: string | null;
  walkOn: boolean;
  withdrawnTransfer: boolean;
  /** @format date-time */
  withdrawnTransferDate?: string | null;
  /** @format int32 */
  year: number;
  /** @format int32 */
  yearOrder: number;
}

export interface RpmAccuracyResponse {
  /** @format double */
  overallAverage: number;
  /** @format double */
  overallAveragePercent?: number | null;
  /** @format double */
  playerCorrect: number;
  /** @format double */
  yearlyAverage: number;
}

export interface RpmAdminResponse {
  boost: boolean;
  committed: boolean;
  /** @format date-time */
  committedDate?: string | null;
  /** @format double */
  distance?: number | null;
  flippedFromOrganization?: Team;
  /** @format int64 */
  key: number;
  organization?: Team;
  /** @format double */
  percent?: number | null;
  status?: string | null;
  /** @format date-time */
  statusDate?: string | null;
}

export interface RpmAdminResponsePagedData {
  list?: RpmAdminResponse[];
  pagination?: Pagination;
}

export interface RpmPercentResponse {
  /** @format double */
  distance?: number | null;
  organization?: OrganizationResponse;
  /** @format double */
  percent?: number | null;
  showRpmPercentage?: boolean | null;
}

export interface RpmPickList {
  pick?: RpmPicks;
  player?: PlayerListBase;
}

export interface RpmPickListPredictionAccuraciesRelatedPagedData {
  list?: RpmPickList[];
  pagination?: Pagination;
  relatedModel?: PredictionAccuracies;
}

export interface RpmPicks {
  articleLink?: string | null;
  /** @format double */
  confidence?: number | null;
  correct?: boolean | null;
  /** @format date-time */
  dateAdded: string;
  /** @format double */
  daysCorrect?: number | null;
  description?: string;
  expert?: UserProfileBase;
  flippedFromOrganization?: Team;
  /** @format int64 */
  key: number;
  organization?: Team;
  premium: boolean;
  /** @format double */
  previousConfidence?: number | null;
  /** @format date-time */
  previousDateAdded?: string | null;
  topTeams?: RpmSummaryChangeResponse[];
  type: RpmType;
  /** @format int32 */
  year: number;
}

export interface RpmResponse {
  committed: boolean;
  /** @format date-time */
  committedDate?: string | null;
  /** @format double */
  distance?: number | null;
  flippedFromOrganization?: Team;
  /** @format int64 */
  key: number;
  organization?: Team;
  /** @format double */
  percent?: number | null;
  status?: string | null;
  /** @format date-time */
  statusDate?: string | null;
}

export interface RpmSummaryChangeResponse {
  /** @format double */
  currentPercentage?: number | null;
  /** @format int32 */
  currentRank?: number | null;
  /** @format int64 */
  key: number;
  organization?: Team;
  /** @format double */
  previousPercentage?: number | null;
  /** @format int32 */
  previousRank?: number | null;
}

export enum RpmType {
  Pick = 'Pick',
  ConfidenceChange = 'ConfidenceChange',
  NewLeader = 'NewLeader',
  PercentChange = 'PercentChange',
}

export interface ScheduleResponse {
  /** @format int32 */
  awayScore?: number | null;
  awaySeasonRecord?: OrganizationSeasonRecordResponse;
  /** @format int32 */
  awayTeamOrgKey?: number | null;
  awayTeamOrganization?: OrganizationResponse;
  /** @format int32 */
  awayTeamRank?: number | null;
  broadcastInformation?: string | null;
  city?: string | null;
  /** @format int32 */
  currentOrgKey?: number | null;
  currentOrganization?: OrganizationResponse;
  /** @format int32 */
  homeScore?: number | null;
  homeSeasonRecord?: OrganizationSeasonRecordResponse;
  /** @format int32 */
  homeTeamOrgKey?: number | null;
  homeTeamOrganization?: OrganizationResponse;
  /** @format int32 */
  homeTeamRank?: number | null;
  isFinal: boolean;
  /** @format int64 */
  key: number;
  location?: string | null;
  odds?: GameOdds;
  opponentOrganization?: OrganizationResponse;
  phaseScores?: PhaseScoreResponse[];
  seasonPhase?: SeasonPhaseResponse;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
  startDate?: string;
  /** @format int64 */
  startDateUtc?: number | null;
  startTime?: string;
  startTimeTbd: boolean;
  state?: string | null;
  status?: string | null;
  statusEnum: GameStatuses;
  ticketLink?: string | null;
  winner?: OrganizationResponse;
  /** @format int32 */
  year: number;
}

export interface ScheduleResponseRelatedScheduleResponseRelatedPagedData {
  list?: ScheduleResponse[];
  pagination?: Pagination;
  relatedModel?: RelatedScheduleResponse;
}

export interface ScoreboardFiltersResponse {
  groups?: ScoreboardGroupingFilterResponsePagedData;
  phases?: SeasonPhaseFilterResponsePagedData;
  season?: SeasonFilterResponse;
}

export interface ScoreboardFiltersResponseLeagueResponseRelatedPagedData {
  list?: ScoreboardFiltersResponse[];
  pagination?: Pagination;
  relatedModel?: LeagueResponse;
}

export interface ScoreboardGroupByResponse {
  /** @format int32 */
  completed: number;
  date?: string;
  games?: ScoreboardResponse[] | null;
  /** @format int32 */
  inProgress: number;
  /** @format int32 */
  scheduled: number;
}

export interface ScoreboardGroupByResponseLeagueScoreBoardRelatedResponseRelatedPagedData {
  list?: ScoreboardGroupByResponse[];
  pagination?: Pagination;
  relatedModel?: LeagueScoreBoardRelatedResponse;
}

export interface ScoreboardGroupingFilterResponse {
  label?: string;
  value?: string;
}

export interface ScoreboardGroupingFilterResponsePagedData {
  list?: ScoreboardGroupingFilterResponse[];
  pagination?: Pagination;
}

export interface ScoreboardResponse {
  activeGameStatus?: ActiveGameStatusResponse;
  awayRankingInformation?: RankInformationResponse;
  /** @format int32 */
  awayTeamOrgKey?: number | null;
  awayTeamOrganization?: OrganizationResponse;
  /** @format int32 */
  awayTeamScore?: number | null;
  /** @format int32 */
  awayTeamScorePrediction?: number | null;
  /** @format double */
  awayTeamWinPercentage?: number | null;
  /** @format double */
  awayTeamWinProbability?: number | null;
  broadcast?: string | null;
  city?: string | null;
  currentOrgIsHome?: boolean | null;
  currentOrgIsWinner?: boolean | null;
  /** @format int32 */
  currentOrgKey?: number | null;
  currentOrganization?: OrganizationResponse;
  currentOrganizationRankInformation?: RankInformationResponse;
  forecast?: WeatherInformationResponse;
  forfeitEnum?: ForfeitType;
  homeRankingInformation?: RankInformationResponse;
  /** @format int32 */
  homeTeamOrgKey?: number | null;
  homeTeamOrganization?: OrganizationResponse;
  /** @format int32 */
  homeTeamScore?: number | null;
  /** @format int32 */
  homeTeamScorePrediction?: number | null;
  /** @format double */
  homeTeamWinPercentage?: number | null;
  /** @format double */
  homeTeamWinProbability?: number | null;
  isFinal: boolean;
  isForfeit: boolean;
  /** @format int64 */
  key: number;
  location?: string | null;
  odds?: GameOddsResponse;
  opponentOrganization?: OrganizationResponse;
  opponentOrganizationRankInformation?: RankInformationResponse;
  overUnderResult?: string | null;
  seasonPhase?: SeasonPhaseResponse;
  spreadPrediction?: string | null;
  spreadResult?: string | null;
  startDate?: string;
  /** @format int64 */
  startDateUtc?: number | null;
  startTime?: string;
  startTimeTbd: boolean;
  state?: string | null;
  status?: string | null;
  statusEnum: GameStatuses;
  ticketLink?: string | null;
  winner?: OrganizationResponse;
  /** @format int32 */
  winnerOrgKey?: number | null;
}

export interface ScoreboardResponseLeagueScoreBoardRelatedResponseRelatedPagedData {
  list?: ScoreboardResponse[];
  pagination?: Pagination;
  relatedModel?: LeagueScoreBoardRelatedResponse;
}

export interface ScoreboardResponseRelatedScheduleResponseRelatedPagedData {
  list?: ScoreboardResponse[];
  pagination?: Pagination;
  relatedModel?: RelatedScheduleResponse;
}

export enum ScoutingCategory {
  Camp = 'Camp',
  Game = 'Game',
  SevenVsSeven = 'SevenVsSeven',
  AllAmericanPractice = 'AllAmericanPractice',
  Summary = 'Summary',
  RankingEvaluation = 'RankingEvaluation',
}

export enum ScrapeErrorType {
  Unknown = 'Unknown',
  RateLimited = 'RateLimited',
  MissingResource = 'MissingResource',
  PrivateResource = 'PrivateResource',
  InvalidResource = 'InvalidResource',
  SuspendedResource = 'SuspendedResource',
}

export interface SearchV1OrganizationsAdvancedListParams {
  conferenceKeys?: number[];
  /**
   * @format int32
   * @min 1
   * @max 50
   */
  limit?: number;
  organizationKeys?: number[];
  organizationType?: OrganizationType;
  organizationTypes?: OrganizationType[];
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /**
   * @minLength 0
   * @maxLength 500
   */
  searchText?: string;
  sportKeys?: number[];
  /** @format int32 */
  stateKey?: number;
}

export interface SearchV1PeopleAdminSearchListParams {
  articleTaggingEnabled?: boolean;
  athleteVerified?: boolean;
  /** @format int32 */
  attendedOrGraduatedFromOrgKey?: number;
  collegeOrgKeys?: number[];
  /** @format int32 */
  committedOrganizationKey?: number;
  /** @format int32 */
  currentOrganizationKey?: number;
  currentOrganizationLevel?: OrganizationType;
  currentPositionTypes?: PositionType[];
  /** @format double */
  distanceFromLatitude?: number;
  /** @format double */
  distanceFromLongitude?: number;
  /** @format double */
  distanceFromMiles?: number;
  /** The first name of the person */
  firstName?: string;
  /** The known alias a person goes by */
  goesBy?: string;
  highSchoolOrgKeys?: number[];
  /** @format int32 */
  homeStateKey?: number;
  isDraftPick?: boolean;
  isFiveStarPlus?: boolean;
  /** The last name of the person */
  lastName?: string;
  /**
   * @format int32
   * @min 1
   * @max 50
   */
  limit?: number;
  /** @format int32 */
  maxClassYear?: number;
  /** @format int32 */
  maxConsensusNationalRank?: number;
  /** @format int64 */
  maxNilValuation?: number;
  /** @format int32 */
  maxOverallNationalRank?: number;
  /** @format int32 */
  maxStars?: number;
  /** @format int32 */
  minClassYear?: number;
  /**
   * The optional minimum height in inches to filter down
   * @format double
   */
  minHeight?: number;
  /** @format int64 */
  minNilValuation?: number;
  /** @format int32 */
  minStars?: number;
  /**
   * The optional minimum weight in pounds to filter down
   * @format double
   */
  minWeight?: number;
  /** @format int64 */
  officialVisitEndDate?: number;
  officialVisitOrgKeys?: number[];
  /** @format int64 */
  officialVisitStartDate?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /**
   * The optional position key to filter down
   * @format int32
   */
  positionKey?: number;
  positionTypes?: PositionType[];
  /** @format int64 */
  recruitedByPersonKey?: number;
  recruitingStatuses?: RecruitmentStatus[];
  recruitmentInterestOrgKeys?: number[];
  searchText?: string;
  /**
   * The optional sport to filter down
   * @format int32
   */
  sportKey?: number;
  tier?: PersonStatusTier;
  /**
   * The optional recruitment year to filter down
   * @format int32
   */
  year?: number;
}

export interface SearchV1PeopleAdvancedDetailsDetailParams {
  /** @default false */
  forceRebuild?: boolean;
  /** @default "people" */
  index?: string;
  /** @format int64 */
  key: number;
}

export interface SearchV1PeopleListParams {
  articleTaggingEnabled?: boolean;
  athleteVerified?: boolean;
  /** @format int32 */
  attendedOrGraduatedFromOrgKey?: number;
  collegeOrgKeys?: number[];
  /** @format int32 */
  committedOrganizationKey?: number;
  /** @format int32 */
  currentOrganizationKey?: number;
  currentOrganizationLevel?: OrganizationType;
  currentPositionTypes?: PositionType[];
  /** @format double */
  distanceFromLatitude?: number;
  /** @format double */
  distanceFromLongitude?: number;
  /** @format double */
  distanceFromMiles?: number;
  /** The first name of the person */
  firstName?: string;
  /** The known alias a person goes by */
  goesBy?: string;
  highSchoolOrgKeys?: number[];
  /** @format int32 */
  homeStateKey?: number;
  isDraftPick?: boolean;
  isFiveStarPlus?: boolean;
  /** The last name of the person */
  lastName?: string;
  /**
   * @format int32
   * @min 1
   * @max 50
   */
  limit?: number;
  /** @format int32 */
  maxClassYear?: number;
  /** @format int32 */
  maxConsensusNationalRank?: number;
  /** @format int64 */
  maxNilValuation?: number;
  /** @format int32 */
  maxOverallNationalRank?: number;
  /** @format int32 */
  maxStars?: number;
  /** @format int32 */
  minClassYear?: number;
  /**
   * The optional minimum height in inches to filter down
   * @format double
   */
  minHeight?: number;
  /** @format int64 */
  minNilValuation?: number;
  /** @format int32 */
  minStars?: number;
  /**
   * The optional minimum weight in pounds to filter down
   * @format double
   */
  minWeight?: number;
  /** @format int64 */
  officialVisitEndDate?: number;
  officialVisitOrgKeys?: number[];
  /** @format int64 */
  officialVisitStartDate?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /**
   * The optional position key to filter down
   * @format int32
   */
  positionKey?: number;
  positionTypes?: PositionType[];
  /** @format int64 */
  recruitedByPersonKey?: number;
  recruitingStatuses?: RecruitmentStatus[];
  recruitmentInterestOrgKeys?: number[];
  searchText?: string;
  /**
   * The optional sport to filter down
   * @format int32
   */
  sportKey?: number;
  tier?: PersonStatusTier;
  /**
   * The optional recruitment year to filter down
   * @format int32
   */
  year?: number;
}

export interface SearchV1RebuildCreate2Params {
  index?: string;
  /** @format int64 */
  key: number;
}

export interface SearchV1RebuildCreateParams {
  /**
   * @format int32
   * @default 500
   */
  batchSize?: number;
  index?: string;
  /** @default false */
  resetIndex?: boolean;
}

export interface SearchV1UsersListParams {
  accessType?: On3AccessType;
  email?: string;
  /**
   * @format int32
   * @min 1
   * @max 50
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  pianoUid?: string;
  premium?: boolean;
  resourceId?: string;
  revoked?: boolean;
  /** @format int32 */
  siteKey?: number;
  termId?: string;
  /** @format int64 */
  userKey?: number;
  username?: string;
}

export interface SeasonFilterResponse {
  current: boolean;
  label?: string;
  /** @format int32 */
  value: number;
}

export interface SeasonPhaseFilterResponse {
  current: boolean;
  /** @format date */
  endDate?: string | null;
  /** @format int32 */
  gameCount: number;
  label?: string;
  /** @format date */
  startDate?: string | null;
  value?: string;
}

export interface SeasonPhaseFilterResponsePagedData {
  list?: SeasonPhaseFilterResponse[];
  pagination?: Pagination;
}

export interface SeasonPhaseResponse {
  /** @format date */
  endDate?: string | null;
  /** @format int64 */
  key: number;
  /** @format int64 */
  leagueSeasonKey: number;
  name?: string;
  /** @format int32 */
  number?: number | null;
  /** @format int64 */
  parentSeasonPhaseKey?: number | null;
  phaseType: SeasonPhaseType;
  /** @format date */
  startDate?: string | null;
}

export enum SeasonPhaseType {
  Unknown = 'Unknown',
  Round = 'Round',
  MatchRound = 'MatchRound',
}

export interface SimpleHeadlineResponse {
  /**
   * Date the article was published
   * @format date-time
   */
  datePublishedGmt: string;
  /** url of headline */
  fullUrl?: string | null;
  /** Denotes if the content is marked as premium or not */
  isPremium: boolean;
  /**
   * Key of headline
   * @format int64
   */
  key: number;
  /** Slug of headline */
  slug?: string | null;
  /** Title of headline */
  title?: string | null;
}

export interface SimpleLcoRes {
  /** @format int32 */
  conferenceKey: number;
  /** @format int64 */
  key: number;
  /** @format int64 */
  leagueConferenceKey: number;
  /** @format int64 */
  leagueKey: number;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface SimpleOrganizationResponse {
  abbreviation?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  organizationType: OrganizationType;
  primaryColor?: string | null;
  slug?: string | null;
}

export interface SimplePersonResponse {
  /** @format int32 */
  classYear: number;
  firstName?: string;
  fullName?: string;
  /** @format int64 */
  key: number;
  knownAsName?: string | null;
  lastName?: string;
  slug?: string;
}

export interface SimpleSiteResponseRecord {
  allowSubs: boolean;
  alternateAvatar?: AssetResponse;
  /** @format int64 */
  alternateAvatarKey?: number | null;
  alternateLogo?: AssetResponse;
  /** @format int64 */
  alternateLogoKey?: number | null;
  amazonAffiliateLink?: string | null;
  /** @format int64 */
  categoryKey?: number | null;
  defaultAsset?: AssetResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  defaultAvatar?: AssetResponse;
  /** @format int64 */
  defaultAvatarKey?: number | null;
  /** @format int64 */
  defaultTrendingListKey?: number | null;
  facebookProfile?: string | null;
  /** @format int32 */
  forumKey?: number | null;
  gTagId?: string | null;
  googleAnalyticsProjectId?: string | null;
  inIndex: boolean;
  instagramProfile?: string | null;
  isChannel: boolean;
  isNational: boolean;
  isTeam: boolean;
  /** @format int32 */
  key: number;
  keyPersons?: SimpleUserResponse[] | null;
  linkColor?: string | null;
  live: boolean;
  /** @format int32 */
  menuKey?: number | null;
  name?: string | null;
  /** @format int32 */
  organizationKey?: number | null;
  primaryColor?: string | null;
  /** @format int32 */
  primaryForumId?: number | null;
  resourceId?: string | null;
  secondaryColor?: string | null;
  /** @format int32 */
  siblingSiteKey?: number | null;
  slug?: string | null;
  subscribeText?: string | null;
  tagLine?: string | null;
  template: SiteTemplate;
  templateId?: string | null;
  tertiaryColor?: string | null;
  twitterHandle?: string | null;
  type: SiteType;
  url?: string | null;
  youTubeChannelId?: string | null;
  youtubeProfile?: string | null;
}

export interface SimpleUserResponse {
  displayName?: string | null;
  /** @format int64 */
  key: number;
  login?: string | null;
  niceName?: string | null;
}

export interface SingleTeamRankingResponse {
  /** @format int32 */
  compositeNationalRank?: number | null;
  /** @format int32 */
  compositeStateRank?: number | null;
  /** @format int32 */
  losses: number;
  /** @format int32 */
  nationalRank?: number | null;
  /** @format int32 */
  orgKey: number;
  sportResponseRecord: SportResponseRecord;
  /** @format int32 */
  stateRank?: number | null;
  stateResponseRecord?: StateResponseRecord;
  /** @format int32 */
  ties: number;
  /** @format int32 */
  wins: number;
  /** @format int32 */
  year: number;
}

export enum SiteTemplate {
  Feed = 'Feed',
  Traditional = 'Traditional',
}

export enum SiteType {
  National = 'National',
  Channel = 'Channel',
  Team = 'Team',
}

export interface SlimPsoRes {
  classYear?: ClassYear;
  /** @format int64 */
  key: number;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  year: number;
}

export interface SlimRecruitmentEvaluationRes {
  /** @format int64 */
  authorKey: number;
  body: string;
  category: ScoutingCategory;
  /** @format int32 */
  dateAdded: number;
  /** @format int32 */
  dateUpdated: number | null;
  /** @format int64 */
  key: number;
  premium: boolean;
  primary: boolean;
  /** @format int64 */
  recruitmentKey: number;
  title: string;
}

export interface SlimVisitResponse {
  /** @format int64 */
  dateOccurred: number;
  official: boolean;
  /** @format int32 */
  organizationKey: number;
}

export interface SmallPlayerResponse {
  /** @format int32 */
  classYear?: number | null;
  defaultAsset?: AssetResponse;
  firstName?: string;
  fullName?: string;
  height?: string | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  primaryPosition?: AttributeResponse;
  /** @format int64 */
  recruitmentKey?: number | null;
  slug?: string;
  /** @format double */
  weight?: number | null;
}

export interface SocialMetaAdminResponse {
  /** @format int64 */
  key: number;
  /** @format date-time */
  lastError?: string | null;
  lastErrorMessage?: string | null;
  lastErrorType?: ScrapeErrorType;
  /** @format date-time */
  lastScrape?: string | null;
  /** @format date-time */
  nextScrape?: string | null;
  /** @format int64 */
  personKey: number;
  socialType: SocialType;
  status: SocialMetaStatus;
}

export enum SocialMetaStatus {
  NeedsUpdated = 'NeedsUpdated',
  Skip = 'Skip',
  ReadyToScrape = 'ReadyToScrape',
  Scraping = 'Scraping',
  Scraped = 'Scraped',
}

export enum SocialType {
  Unknown = 'Unknown',
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  TikTok = 'TikTok',
  Facebook = 'Facebook',
  Youtube = 'Youtube',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortMeasurementType {
  Latest = 'Latest',
  Lowest = 'Lowest',
  Highest = 'Highest',
}

export interface SpRequest {
  additions?: PsoRequest[];
  defaultSport: boolean;
  keysToDelete?: number[];
  /** @format int32 */
  sportKey: number;
  updates?: UpdatePsoRequest[];
}

export interface SportBase {
  /** @format int32 */
  key: number;
  name?: string;
}

export enum SportKeys {
  Unknown = 'Unknown',
  Football = 'Football',
  Basketball = 'Basketball',
  MensTrack = 'MensTrack',
  Baseball = 'Baseball',
  WomensBasketball = 'WomensBasketball',
  Volleyball = 'Volleyball',
  Gymnastics = 'Gymnastics',
  MensLacrosse = 'MensLacrosse',
  WomensLacrosse = 'WomensLacrosse',
  MensSoccer = 'MensSoccer',
  WomensSoccer = 'WomensSoccer',
  Softball = 'Softball',
  WomensTrack = 'WomensTrack',
  MensGolf = 'MensGolf',
  WomensGolf = 'WomensGolf',
  MensHockey = 'MensHockey',
  WomensHockey = 'WomensHockey',
  MensSwimming = 'MensSwimming',
  WomensSwimming = 'WomensSwimming',
  Wrestling = 'Wrestling',
  MensTennis = 'MensTennis',
  WomensTennis = 'WomensTennis',
}

export interface SportResponse {
  defaultSport: boolean;
  /** @format int32 */
  sportKey: number;
  sportName?: string | null;
  years?: RosterResponse[] | null;
}

export interface SportResponseRecord {
  abbreviation?: string;
  isIndustryRankable: boolean;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StatBase {
  /** @format int64 */
  key?: number | null;
  name: string;
  /** @format int64 */
  personSportOrgKey?: number | null;
  /** @format int32 */
  statKey: number;
  /** @format double */
  value?: number | null;
}

export interface StatRequest {
  /** @format int64 */
  key?: number | null;
  /** @format int32 */
  statKey: number;
  /** @format double */
  value: number;
}

export interface StatResponse {
  /** @format int64 */
  personSportOrgKey: number;
  stats?: StatBase[];
  /** @format int32 */
  year: number;
}

export interface StatResponseListResourceResponseMessage {
  message?: string;
  resource?: StatResponse[];
  /** @format int32 */
  statusCode: number;
}

export interface StateResponse {
  abbreviation?: string;
  /** @format int32 */
  countryKey?: number | null;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StateResponseRecord {
  abbreviation?: string;
  /** @format int32 */
  countryKey?: number | null;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface Status {
  classRank?: string | null;
  committedAsset?: KeyUrlBase;
  committedAssetRes?: AssetResponse;
  committedOrganization?: OrganizationBase;
  /** @format date-time */
  date?: string | null;
  decommittedAsset?: OrganizationResponse;
  /** @format int64 */
  recruitmentKey: number;
  /** @format int32 */
  recruitmentYear?: number | null;
  shortTermSignee: boolean;
  transfer: boolean;
  /** @format date-time */
  transferEntered?: string | null;
  transferredAsset?: KeyUrlBase;
  transferredAssetRes?: AssetResponse;
  type?: string;
  withdrawnTransfer: boolean;
  /** @format date-time */
  withdrawnTransferDate?: string | null;
}

export interface StrengthBase {
  /** @format int64 */
  key: number;
  name?: string;
}

export interface StrengthRes {
  /** @format int64 */
  assetKey?: number | null;
  description?: string;
  /** @format int64 */
  key: number;
  name?: string;
  /** @format int32 */
  positionKey?: number | null;
  /** @format int32 */
  sportKey?: number | null;
}

export interface StrengthResResourceResponseMessage {
  message?: string;
  resource?: StrengthRes;
  /** @format int32 */
  statusCode: number;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export interface StringPersonContactInfoMeta {
  value?: string | null;
  verification?: Verification;
  visibility: Visibility;
}

export interface StringPersonContactInfoMetaRequest {
  value?: string | null;
  verification?: Verification;
  visibility?: Visibility;
}

export interface StringPersonContactInfoMetaResponse {
  value?: string | null;
  verification?: VerificationResponse;
  visibility: Visibility;
}

export interface SyncPhaseRanksToGamesReq {
  orgType: OrganizationType;
  /** @format date-time */
  phaseDate: string;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface Team {
  abbreviation?: string | null;
  asset?: AssetResponse;
  assetUrl?: KeyUrlBase;
  fullName?: string;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  primaryColor?: string | null;
  slug?: string | null;
}

export interface TeamRankingResponse {
  city?: CityResponseRecord;
  /** @format int32 */
  classificationRank?: number | null;
  conferenceResponse?: ConferenceResponse;
  currentOrganization?: OrganizationResponse;
  /** @format int32 */
  defensiveRank?: number | null;
  /** @format double */
  defensiveScore?: number | null;
  /** @format int32 */
  homeFieldAdvantageRank?: number | null;
  /** @format double */
  homeFieldAdvantageScore?: number | null;
  /** @format int32 */
  hrpKey?: number | null;
  /** @format int32 */
  losses: number;
  /** @format int32 */
  nationalRank?: number | null;
  /** @format int32 */
  offensiveRank?: number | null;
  /** @format double */
  offensiveScore?: number | null;
  /** @format int32 */
  orgKey: number;
  /** @format int32 */
  overallRank?: number | null;
  /** @format int32 */
  overallRankChange?: number | null;
  /** @format int32 */
  overallRankPreviousWeek?: number | null;
  /** @format double */
  overallScore?: number | null;
  /** @format int32 */
  powerRank?: number | null;
  /** @format double */
  powerScore?: number | null;
  record?: string;
  /** @format int32 */
  strengthOfScheduleRank?: number | null;
  /** @format double */
  strengthOfScheduleScore?: number | null;
  /** @format int32 */
  ties?: number | null;
  /** @format int32 */
  totalGames: number;
  /** @format double */
  winPercentage: number;
  /** @format int32 */
  wins: number;
}

export interface TeamRankingResponseRelatedTeamRankingResponseRelatedPagedData {
  list?: TeamRankingResponse[];
  pagination?: Pagination;
  relatedModel?: RelatedTeamRankingResponse;
}

export interface UInt32StringKeyValuePair {
  /** @format int32 */
  key: number;
  value?: string;
}

export interface UInt64StringKeyValuePair {
  /** @format int64 */
  key: number;
  value?: string;
}

export interface UpdateCollectiveGroupFounderRequest {
  instagramHandle?: string | null;
  /** @format int64 */
  key?: number | null;
  linkedInHandle?: string | null;
  name?: string | null;
  /** @format int32 */
  order?: number | null;
  /** @format int64 */
  personKey?: number | null;
  tikTokHandle?: string | null;
  title?: string | null;
  twitterHandle?: string | null;
}

export interface UpdateCollectiveGroupRequest {
  /** @format double */
  annualGoalAmount?: number | null;
  /** @format double */
  confirmedRaisedAmount?: number | null;
  /** @format int64 */
  defaultAssetKey?: number | null;
  description?: string | null;
  founders: UpdateCollectiveGroupFounderRequest[];
  instagramHandle?: string | null;
  /** @format date-time */
  launchDate?: string | null;
  linkedInHandle?: string | null;
  /** The Collective Groups merged to create this Group */
  mergedGroupKeys?: number[] | null;
  /**
   * The Collective Group this Group was merged into.
   * @format int64
   */
  mergedIntoGroupKey?: number | null;
  missionStatement?: string | null;
  /** @minLength 1 */
  name: string;
  /** @format int32 */
  organizationKey: number;
  organizationType?: string | null;
  /** @format int64 */
  socialAssetKey?: number | null;
  sports: CollectiveGroupSportRequest[];
  tikTokHandle?: string | null;
  twitterHandle?: string | null;
  websiteName?: string | null;
  websiteUrl?: string | null;
  youtubeHandle?: string | null;
}

export interface UpdateOrganizationRequest {
  /**
   * @minLength 0
   * @maxLength 35
   */
  abbreviation?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  address?: string | null;
  /** @format int32 */
  addressCityKey?: number | null;
  /** @format int64 */
  alternateAssetKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 2000
   */
  bio?: string | null;
  /** @format int64 */
  defaultAssetKey?: number | null;
  isForeign?: boolean | null;
  isPrivate?: boolean | null;
  /** @format int32 */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  knownAs?: string | null;
  /** @format int32 */
  latitude?: number | null;
  /** @format int64 */
  lightAssetKey?: number | null;
  /** @format int32 */
  longitude?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  mascot?: string | null;
  /** @format int32 */
  masseyKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  orgType: OrganizationType;
  /**
   * @minLength 0
   * @maxLength 10
   */
  primaryColor?: string | null;
  /**
   * @minLength 0
   * @maxLength 10
   */
  secondaryColor?: string | null;
  /**
   * @minLength 0
   * @maxLength 75
   */
  slug?: string | null;
  socials?: OrganizationSocialRequest;
  /**
   * @minLength 0
   * @maxLength 35
   */
  telephone?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  website?: string | null;
  /** @format int32 */
  zipCode?: number | null;
}

export interface UpdatePersonRequest {
  /** @format int32 */
  almaMaterClassYear?: number | null;
  /** @format int32 */
  almaMaterKey?: number | null;
  bio?: string | null;
  bioCollegeRecruit?: string | null;
  bioProProspect?: string | null;
  /** @format date-time */
  dateOfBirth?: string | null;
  /** @format int64 */
  defaultAssetKey?: number | null;
  degree?: string | null;
  espnProfile?: string | null;
  excludeFromNil: boolean;
  /** @minLength 1 */
  firstName: string;
  /** @format int32 */
  highSchoolAlmaMaterKey?: number | null;
  /** @format int32 */
  hometownCityKey?: number | null;
  instagramProfile?: string | null;
  knownAsName?: string | null;
  /** @minLength 1 */
  lastName: string;
  ncaaId?: string | null;
  /**
   * @format double
   * @min -9999999999.99
   * @max 9999999999.99
   */
  nilAdjustedRosterValuation: number;
  /**
   * @format double
   * @min -9999999999.99
   * @max 9999999999.99
   */
  nilAdjustedValuation: number;
  nilStatus: NilStatuses;
  prospectVerified: boolean;
  reviewStatus: PersonStatusReviewStatus;
  rivalsProfile?: string | null;
  tier: PersonStatusTier;
  tikTokHandle?: string | null;
  twitterHandle?: string | null;
  twoFourSevenProfile?: string | null;
  visibility: PersonStatusVisibility;
}

export interface UpdatePersonSportOrganizationRequest {
  blueShirt: boolean;
  /** @format int32 */
  depth?: number | null;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  fired: boolean;
  freeYear: boolean;
  grayShirt: boolean;
  /** @format int32 */
  jerseyNumber?: number | null;
  medical: boolean;
  optedOut: boolean;
  promoted: boolean;
  redShirt: boolean;
  resigned: boolean;
  /** @format double */
  salary?: number | null;
  transer: boolean;
  /** @default true */
  walkOn: boolean;
  /**
   * @format int32
   * @min 1
   * @max 10
   */
  yearOrder: number;
}

export interface UpdatePsoRequest {
  /** @format int64 */
  agencyKey?: number | null;
  /** @format int64 */
  agentKey?: number | null;
  blueShirt: boolean;
  deceased: boolean;
  /** @format int32 */
  depth?: number | null;
  draftParticipant: boolean;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  endOfTeam: boolean;
  fired: boolean;
  freeTransferYear: boolean;
  freeYear: boolean;
  grayShirt: boolean;
  /** @format int32 */
  jerseyNumber?: number | null;
  /** @format int64 */
  key: number;
  medical: boolean;
  noAgent: boolean;
  optedOut: boolean;
  positionKeys?: number[];
  promoted: boolean;
  redShirt: boolean;
  resigned: boolean;
  /** @format double */
  salary?: number | null;
  salaryUrl?: string | null;
  /** @format int64 */
  secondaryAgencyKey?: number | null;
  /** @format int64 */
  secondaryAgentKey?: number | null;
  transfer: boolean;
  /** @format date-time */
  transferDate?: string | null;
  walkOn: boolean;
  withdrawnTransfer: boolean;
  /** @format date-time */
  withdrawnTransferDate?: string | null;
  /** @format int32 */
  year: number;
  /**
   * @format int32
   * @min 1
   * @max 10
   */
  yearOrder: number;
}

export interface UpdateRecruitmentBreakdownSummaryRequest {
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface UpdateReeRequest {
  /** @minLength 1 */
  body: string;
  category?: ScoutingCategory;
  /** @format date-time */
  dateAdded?: string | null;
  premium: boolean;
  /** @default false */
  primary: boolean;
  /**
   * @minLength 0
   * @maxLength 512
   */
  title: string;
}

export interface UpdateRelationRequest {
  /** @format int64 */
  relatedPersonKey: number;
  /** @format int32 */
  relationTypeKey: number;
}

export interface UpdateRpmRequest {
  boost: boolean;
  /** @format int64 */
  key: number;
}

export interface UpdateScheduleResponse {
  /** @format int32 */
  gamesAdded: number;
  /** @format int32 */
  gamesSavedToDb: number;
}

export type UpdateStatResponse = object;

export interface UpdateStrengthRequest {
  /** @format int64 */
  assetKey?: number | null;
  /** @minLength 1 */
  description: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /** @format int32 */
  positionKey?: number | null;
  /** @format int32 */
  sportKey?: number | null;
}

export interface UploadByCsvResponse {
  /** @format int32 */
  added: number;
  /** @format int32 */
  deleted: number;
  /** @format int32 */
  processed: number;
  /** @format int32 */
  rejected: number;
  /** @format int32 */
  updated: number;
}

export interface UserProfileBase {
  bio?: string | null;
  instagramHandle?: string | null;
  jobTitle?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
  niceName?: string;
  profilePicture?: KeyUrlBase;
  profilePictureResponse?: ImageResponse;
  twitterHandle?: string | null;
  youtubeUrl?: string | null;
}

export interface UserSearchAccessRes {
  accessType: On3AccessType;
  /** @format date-time */
  expireDate: string | null;
  /** @format int64 */
  key: number;
  premium: boolean | null;
  resourceId: string;
  revoked: boolean;
  /** @format int32 */
  siteKey: number | null;
  /** @format date-time */
  startDate: string;
  termId: string | null;
}

export interface UserSearchResponse {
  access: UserSearchAccessRes[];
  email: string;
  /** @format int64 */
  key: number;
  pianoUid: string | null;
  /** @format date-time */
  registeredAt: string;
  username: string;
}

export interface UserSearchResponsePagedData {
  list?: UserSearchResponse[];
  pagination?: Pagination;
}

export interface V1CollectiveGroupsAutocompleteListParams {
  term?: string;
}

export interface V1ConferencesListParams {
  abbreviation?: string;
  isGroupOfFive?: boolean;
  isPowerFive?: boolean;
  /** @format int64 */
  leagueKey?: number;
  name?: string;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int32 */
  sportKey?: number;
}

export interface V1ConferencesOrganizationsDetailParams {
  /** @format int32 */
  conferenceKey: number;
  /**
   * The optional sport key to get teams for. Defaults to Football
   * @format int32
   */
  sportKey?: number;
  /**
   * The year to retrieve Organizations for
   * @format int32
   */
  year: number;
}

export interface V1ContactInfoContributionsListParams {
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1ContactInfoIndustryTop500ListParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  year: number;
}

export interface V1ContactInfoKpisListParams {
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  year: number;
}

export interface V1ContactInfoListParams {
  personKeys: number[];
}

export interface V1ContactInfoTop300ListParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  year: number;
}

export interface V1ContactInfoTransferPortalTop750ListParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  year: number;
}

export interface V1DraftDraftPicksDetailParams {
  /**
   * The key of the draft
   * @format int64
   */
  draftKey: number;
  /**
   * The page of the full draft list
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The full round to return
   * @format int32
   */
  round?: number;
}

export interface V1DraftPicksListParams {
  /** @format int64 */
  draftKey?: number;
  /** @format int64 */
  leagueKey?: number;
  /** @format int32 */
  organizationKey?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  recruitmentKey?: number;
  /** @format int32 */
  round?: number;
  /** @format int32 */
  year?: number;
}

export interface V1DraftsAutocompleteListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  term?: string;
}

export interface V1DraftsListParams {
  /** @format int64 */
  leagueKey?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int32 */
  year?: number;
}

export interface V1EventsAutocompleteListParams {
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  term?: string;
}

export interface V1EventsListParams {
  /**
   * @format int32
   * @default 10
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1InsidersListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1InsidersScrapeDetailParams {
  /** @default false */
  dryRun?: boolean;
  /** @format int32 */
  key: number;
  /** @format int32 */
  pages?: number;
  /** @format int32 */
  year?: number;
}

export interface V1InsidersScrapeHistoryListParams {
  /** @default false */
  dryRun?: boolean;
}

export interface V1InsidersScrapeListParams {
  sport?: SportKeys;
}

export interface V1LeagueConferenceOrganizationsListParams {
  /** @format int32 */
  conferenceKey?: number;
  /** @format int64 */
  leagueConferenceKey?: number;
  /** @format int64 */
  leagueKey?: number;
  /** @format int32 */
  organizationKey?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V1LeagueConferencesListParams {
  /** @format int32 */
  conferenceKey?: number;
  /** @format int64 */
  leagueKey?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int32 */
  sportKey?: number;
}

export interface V1LeaguesListParams {
  abbreviation?: string;
  name?: string;
  organizationType?: OrganizationType;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int32 */
  sportKey?: number;
}

export interface V1LeaguesScoreboardDetailParams {
  filter?: string;
  /** @format int64 */
  gamesOnDayUtcTimestamp?: number;
  /** @format int64 */
  key: number;
  phase?: string;
  /** @default true */
  showOnlyFbs?: boolean;
  /** @format int32 */
  year?: number;
}

export interface V1OrgTypeClassHierarchiesListParams {
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  childOrgTypeClassHierarchyKey?: number;
  classificationType?: ClassificationType;
  isRankable?: boolean;
  /**
   * @format int32
   * @min 1
   * @max 1000
   */
  limit?: number;
  organizationType: OrganizationType;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  page?: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  parentOrgTypeClassHierarchyKey?: number;
  /** @format int32 */
  sportKey?: number;
  status?: OrgTypeClassHierarchyStatus;
}

export interface V1OrgTypeClassHierarchiesOrganizationsDetailParams {
  /** @format int32 */
  key: number;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  year: number;
}

export interface V1OrganizationKeyMappingsImportForSourceCreateParams {
  source?: MappingSource;
}

export type V1OrganizationKeyMappingsImportForSourceCreatePayload =
  ImportMappingReq[];

export interface V1OrganizationSportHierarchiesImportCreateParams {
  /** @default true */
  addOrgs?: boolean;
  mappingSource?: MappingSource;
}

export interface V1OrganizationSportHierarchiesImportCreatePayload {
  /** @format binary */
  csvFile?: File;
}

export type V1OrganizationSportsCloneHierarchiesCreatePayload = any;

export interface V1OrganizationSportsHierarchiesDetailParams {
  /** @format int32 */
  organizationSportKey: number;
  /** @format int32 */
  year?: number;
}

export interface V1OrganizationsAutocompletePaginatedListParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  term?: string;
  type?: OrganizationType[];
}

export interface V1OrganizationsCommitsContactInfoDetailParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  orgKey: number;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  year: number;
}

export interface V1OrganizationsEnsureRosterCreateParams {
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  year: number;
}

export interface V1OrganizationsImportMasseyAffiliationCreateParams {
  /** @default true */
  diff?: boolean;
  orgType?: OrganizationType;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V1OrganizationsImportMasseyAffiliationCreatePayload {
  /** @format binary */
  csvFile?: File;
}

export interface V1OrganizationsImportMasseyHierarchyCreateParams {
  /** @default true */
  diff?: boolean;
  orgType?: OrganizationType;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V1OrganizationsImportMasseyHierarchyCreatePayload {
  /** @format binary */
  csvFile?: File;
}

export interface V1OrganizationsOpponentsRecentScoresDetailParams {
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V1OrganizationsRosterContactInfoDetailParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  orgKey: number;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  year: number;
}

export interface V1OrganizationsScheduleDetailParams {
  /** @format int32 */
  orgKey: number;
  /**
   * @format int32
   * @default 1
   */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V1OrganizationsSearchListParams {
  address?: string;
  /** @format int32 */
  addressCityKey?: number;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  limit?: number;
  mascot?: string;
  name?: string;
  orgType?: OrganizationType;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  stateKey?: number;
}

export interface V1OrganizationsSearchRebuildCreateParams {
  /**
   * @format int32
   * @default 500
   */
  batchSize?: number;
  /** @default false */
  resetIndex?: boolean;
}

export interface V1OrganizationsTargetsContactInfoDetailParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  orgKey: number;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  year: number;
}

export interface V1PeopleAdminSearchListParams {
  articleTaggingEnabled?: boolean;
  athleteVerified?: boolean;
  /** @format int32 */
  attendedOrGraduatedFromOrgKey?: number;
  collegeOrgKeys?: number[];
  /** @format int32 */
  committedOrganizationKey?: number;
  /** @format int32 */
  currentOrganizationKey?: number;
  currentOrganizationLevel?: OrganizationType;
  currentPositionTypes?: PositionType[];
  /** @format double */
  distanceFromLatitude?: number;
  /** @format double */
  distanceFromLongitude?: number;
  /** @format double */
  distanceFromMiles?: number;
  /** The first name of the person */
  firstName?: string;
  /** The known alias a person goes by */
  goesBy?: string;
  highSchoolOrgKeys?: number[];
  /** @format int32 */
  homeStateKey?: number;
  isDraftPick?: boolean;
  isFiveStarPlus?: boolean;
  /** The last name of the person */
  lastName?: string;
  /**
   * @format int32
   * @min 1
   * @max 50
   */
  limit?: number;
  /** @format int32 */
  maxClassYear?: number;
  /** @format int32 */
  maxConsensusNationalRank?: number;
  /** @format int64 */
  maxNilValuation?: number;
  /** @format int32 */
  maxOverallNationalRank?: number;
  /** @format int32 */
  maxStars?: number;
  /** @format int32 */
  minClassYear?: number;
  /**
   * The optional minimum height in inches to filter down
   * @format double
   */
  minHeight?: number;
  /** @format int64 */
  minNilValuation?: number;
  /** @format int32 */
  minStars?: number;
  /**
   * The optional minimum weight in pounds to filter down
   * @format double
   */
  minWeight?: number;
  /** @format int64 */
  officialVisitEndDate?: number;
  officialVisitOrgKeys?: number[];
  /** @format int64 */
  officialVisitStartDate?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /**
   * The optional position key to filter down
   * @format int32
   */
  positionKey?: number;
  positionTypes?: PositionType[];
  /** @format int64 */
  recruitedByPersonKey?: number;
  recruitingStatuses?: RecruitmentStatus[];
  recruitmentInterestOrgKeys?: number[];
  searchText?: string;
  /**
   * The optional sport to filter down
   * @format int32
   */
  sportKey?: number;
  tier?: PersonStatusTier;
  /**
   * The optional recruitment year to filter down
   * @format int32
   */
  year?: number;
}

export interface V1PeopleAdvancedDetailsDetailParams {
  /** @default false */
  forceRebuild?: boolean;
  /** @format int64 */
  key: number;
}

export interface V1PeopleContactInfoLogsDetailParams {
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  personKey: number;
}

export interface V1PeopleContactInfoMigrateUpdate2Params {
  /**
   * @format int64
   * @default 100
   */
  limit?: number;
}

export interface V1PeopleEducationUpdatePayload {
  /**
   * @format int32
   * @min 1
   * @max 36
   */
  aCT?: number;
  /**
   * @format double
   * @min 0
   * @max 5
   */
  gPA?: number;
  /** @format binary */
  highSchoolTranscriptFile?: File;
  /**
   * @minLength 0
   * @maxLength 255
   */
  intendedMajor?: string;
  /**
   * @format int32
   * @min 400
   * @max 1600
   */
  sAT?: number;
}

export interface V1PeopleEnsureDenormalizedOrganizationsCreateParams {
  /**
   * @format int64
   * @min 1
   * @default 100
   */
  limit?: number;
  /**
   * @format int64
   * @min 1
   */
  startingPersonKey: number;
}

export interface V1PeopleEnsureDenormalizedTermsCreateParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 100
   */
  limit?: number;
  /**
   * @format int64
   * @min 1
   */
  startingPersonKey: number;
}

export type V1PeopleMeasurementsUpdatePayload = MeasurementRequest[];

export interface V1PeoplePersonConnectionsDetailParams {
  /**
   * The pagination limit per page- Default: 25
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * The pagination page desired-Default: 1
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The Person key
   * @format int64
   */
  personKey: number;
}

export interface V1PeoplePersonSportsDetailParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  personKey: number;
}

export interface V1PeopleScrapeCreateParams {
  /** @format int64 */
  personKey: number;
  socialTypes?: SocialType[];
}

export interface V1PeopleSearchListParams {
  articleTaggingEnabled?: boolean;
  athleteVerified?: boolean;
  /** @format int32 */
  attendedOrGraduatedFromOrgKey?: number;
  collegeOrgKeys?: number[];
  /** @format int32 */
  committedOrganizationKey?: number;
  /** @format int32 */
  currentOrganizationKey?: number;
  currentOrganizationLevel?: OrganizationType;
  currentPositionTypes?: PositionType[];
  /** @format double */
  distanceFromLatitude?: number;
  /** @format double */
  distanceFromLongitude?: number;
  /** @format double */
  distanceFromMiles?: number;
  /** The first name of the person */
  firstName?: string;
  /** The known alias a person goes by */
  goesBy?: string;
  highSchoolOrgKeys?: number[];
  /** @format int32 */
  homeStateKey?: number;
  isDraftPick?: boolean;
  isFiveStarPlus?: boolean;
  /** The last name of the person */
  lastName?: string;
  /**
   * @format int32
   * @min 1
   * @max 50
   */
  limit?: number;
  /** @format int32 */
  maxClassYear?: number;
  /** @format int32 */
  maxConsensusNationalRank?: number;
  /** @format int64 */
  maxNilValuation?: number;
  /** @format int32 */
  maxOverallNationalRank?: number;
  /** @format int32 */
  maxStars?: number;
  /** @format int32 */
  minClassYear?: number;
  /**
   * The optional minimum height in inches to filter down
   * @format double
   */
  minHeight?: number;
  /** @format int64 */
  minNilValuation?: number;
  /** @format int32 */
  minStars?: number;
  /**
   * The optional minimum weight in pounds to filter down
   * @format double
   */
  minWeight?: number;
  /** @format int64 */
  officialVisitEndDate?: number;
  officialVisitOrgKeys?: number[];
  /** @format int64 */
  officialVisitStartDate?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /**
   * The optional position key to filter down
   * @format int32
   */
  positionKey?: number;
  positionTypes?: PositionType[];
  /** @format int64 */
  recruitedByPersonKey?: number;
  recruitingStatuses?: RecruitmentStatus[];
  recruitmentInterestOrgKeys?: number[];
  searchText?: string;
  /**
   * The optional sport to filter down
   * @format int32
   */
  sportKey?: number;
  tier?: PersonStatusTier;
  /**
   * The optional recruitment year to filter down
   * @format int32
   */
  year?: number;
}

export interface V1PeopleSearchRebuildCreateParams {
  /**
   * @format int32
   * @default 500
   */
  batchSize?: number;
  /** @default false */
  resetIndex?: boolean;
}

export interface V1PeopleStatusListParams {
  /**
   * @format int32
   * @min 1
   * @max 1000
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  reviewStatuses?: PersonStatusReviewStatus[];
  tiers?: PersonStatusTier[];
  visibilities?: PersonStatusVisibility[];
}

export type V1PeopleUpdateProspectVerifiedUpdatePayload = boolean;

export interface V1PeopleVerifiedAdminListParams {
  /**
   * Page to return, limit is default of 25
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1PeopleVideosHighlightsDetailParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  personKey: number;
  /** @format int32 */
  sportKey?: number;
}

export interface V1PersonSportOrganizationsListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export type V1PersonSportOrganizationsRenovateStatsUpdatePayload =
  StatRequest[];

export type V1PersonSportStatsUpdatePayload = StatRequest[];

export interface V1PersonSportsListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1PositionsAutocompleteListParams {
  /** @default false */
  abbreviation?: boolean;
  /** @format int32 */
  sportKey?: number;
  term?: string;
  type?: PositionType;
}

export interface V1PositionsListParams {
  positionType?: PositionType;
  /** @format int32 */
  sportKey?: number;
}

export interface V1ProcessScheduleUpdateCreateParams {
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V1RecruitmentEvaluationsListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1RecruitmentInterestsListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1RecruitmentLatestRpmPicksDeleteParams {
  /** @format int64 */
  rpmKeyToDelete?: number;
}

export interface V1RecruitmentLatestRpmPicksListParams {
  /**
   * The Optional Amount of items to return: default is 50
   * @format int32
   */
  limit?: number;
  /**
   * The Optional Recruitment Oracle Pick Team Orginization Key to filter to
   * @format int32
   */
  orgKey?: number;
  /**
   * The Optional Page to return: default is 1
   * @format int32
   */
  page?: number;
  /**
   * The Optional Sport Key to filter to
   * @format int32
   */
  sportKey?: number;
  /**
   * The Optional Recruitment year to filter to
   * @format int32
   */
  year?: number;
  /** The Optional way to Sort the RPM Pick List: default is descending by Recruitment Oracle Date Added */
  yearSort?: SortDirection;
}

export interface V1RecruitmentOraclePickersAutocompleteListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  term?: string;
}

export interface V1RecruitmentOraclePickersListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export type V1RecruitmentOraclePickersRenovateUpdatePayload =
  RenovateRopRequest[];

export interface V1RecruitmentsListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1RecruitmentsRecruitmentEvaluationsAdminDetailParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  recruitmentKey: number;
}

export interface V1RecruitmentsRecruitmentOraclesAdminDetailParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  recruitmentKey: number;
}

export interface V1RpmAccuracyListParams {
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V1SchedulesProcessActiveLocCreateParams {
  /** @format int32 */
  sportKey?: number;
}

export interface V1SchedulesUpdateUpcomingGamesCreateParams {
  /** @format int32 */
  sportKey?: number;
}

export interface V1SchedulesUpdateUpcomingGamesLocCreateParams {
  /** @format int32 */
  sportKey?: number;
}

export interface V1ScoreboardFiltersListParams {
  organizationType: OrganizationType;
  /** @format int32 */
  sportKey: number;
}

export interface V1ScoreboardListParams {
  top25?: boolean;
  filter?: string;
  /** @format int64 */
  gamesOnDayUtcTimestamp?: number;
  organizationType: OrganizationType;
  /**
   * Used to paginate through national HS ranks
   * 100 games per page
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  phase?: string;
  showOnlyFbs?: boolean;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year?: number;
}

export interface V1SeasonPhasesImportCreateParams {
  /** @format int32 */
  leagueKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V1SeasonPhasesImportCreatePayload {
  /** @format binary */
  csvFile?: File;
}

export interface V1SeasonPhasesListParams {
  /** @format int32 */
  leagueSeasonKey?: number;
}

export interface V1SportsAutocompleteListParams {
  /** @default false */
  abbreviation?: boolean;
  term?: string;
}

export interface V1SportsEnsurePickRankingSummaryCreateParams {
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year?: number;
}

export interface V1SportsEnsureRankingSummaryCreateParams {
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year?: number;
}

export interface V1StatsProcessAccuracyRatingsCreateParams {
  organizationType?: OrganizationType;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V1StrengthsListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int32 */
  sportKey?: number;
}

export interface V1StrengthsSimpleListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  recruitmentKey?: number;
  /** @format int32 */
  sportKey?: number;
}

export interface V1TeamRankingsImportMasseyRankingsCreateParams {
  /** @default true */
  diff?: boolean;
  orgType?: OrganizationType;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V1TeamRankingsImportMasseyRankingsCreatePayload {
  /** @format binary */
  csvFile?: File;
}

export interface V1TeamRankingsRankingsDetailParams {
  /** @format int32 */
  orgKey: number;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface V1TeamRankingsRankingsListParams {
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  conferenceKey?: number;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  limit?: number;
  orgType: OrganizationType;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  sportKey: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  stateKey?: number;
  /**
   * @format int32
   * @min 1800
   * @max 2147483647
   */
  year: number;
}

export interface ValuationResponse {
  /** @format int64 */
  groupRank?: number | null;
  /** @format int64 */
  key: number;
  nilStatus: NilStatuses;
  /** @format int64 */
  rank?: number | null;
  /** @format int64 */
  totalValue?: number | null;
}

export interface Verification {
  /** @format int64 */
  userKey: number;
  verificationSource: VerificationSource;
  /** @format date-time */
  verifiedOn: string;
}

export interface VerificationResponse {
  /** @format int64 */
  userKey: number;
  verificationSource: VerificationSource;
  /** @format date-time */
  verifiedOn: string;
}

export enum VerificationSource {
  Athlete = 'Athlete',
  DBAdmin = 'DBAdmin',
  Unknown = 'Unknown',
}

export interface VideoResponse {
  category?: Int32StringKeyValuePair;
  /** @format int32 */
  date: number;
  description?: string | null;
  /** @format int32 */
  featuredOrder?: number | null;
  isFeatured: boolean;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey?: number | null;
  personSport?: PersonSportBase;
  sourceUrl?: string;
  thumbnail?: string | null;
  title?: string | null;
}

export interface VideoResponsePagedData {
  list?: VideoResponse[];
  pagination?: Pagination;
}

export enum Visibility {
  Private = 'Private',
  Friends = 'Friends',
  Athletes = 'Athletes',
  Public = 'Public',
  Unknown = 'Unknown',
}

export interface WeatherInformationResponse {
  conditions?: string | null;
  /** @format int32 */
  temperature?: number | null;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags Search
 * @name SearchV1AliasesAddRemoveCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/search/v1/aliases/add-remove
 * @secure
 */
export const searchV1AliasesAddRemoveCreate = (
  client: AxiosInstance,
  data: AddAndRemoveAliasRequest,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/rdb/search/v1/aliases/add-remove`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Search
 * @name SearchV1AliasesCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/search/v1/aliases
 * @secure
 */
export const searchV1AliasesCreate = (
  client: AxiosInstance,
  data: AddAliasRequest,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/rdb/search/v1/aliases`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Search
 * @name SearchV1AliasesDelete
 * @summary  (Roles: developer)
 * @request DELETE:/rdb/search/v1/aliases
 * @secure
 */
export const searchV1AliasesDelete = (
  client: AxiosInstance,
  data: DeleteAliasRequest,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/rdb/search/v1/aliases`,
      method: 'DELETE',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Search
 * @name SearchV1AliasesIndicesDetail
 * @summary  (Roles: developer)
 * @request GET:/rdb/search/v1/aliases/{alias}/indices
 * @secure
 */
export const searchV1AliasesIndicesDetail = (
  client: AxiosInstance,
  alias: string,
  params: RequestParams = {},
) =>
  client
    .request<string[]>({
      url: `/rdb/search/v1/aliases/${alias}/indices`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Search
 * @name SearchV1AliasesList
 * @summary  (Roles: developer)
 * @request GET:/rdb/search/v1/aliases
 * @secure
 */
export const searchV1AliasesList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<CatAliasResponsePagedData>({
      url: `/rdb/search/v1/aliases`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Search
 * @name SearchV1IndicesCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/search/v1/indices
 * @secure
 */
export const searchV1IndicesCreate = (
  client: AxiosInstance,
  data: CreateIndexRequest,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/rdb/search/v1/indices`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Search
 * @name SearchV1IndicesDelete
 * @summary  (Roles: developer)
 * @request DELETE:/rdb/search/v1/indices/{index}
 * @secure
 */
export const searchV1IndicesDelete = (
  client: AxiosInstance,
  index: string,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/rdb/search/v1/indices/${index}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Search
 * @name SearchV1IndicesDetail
 * @summary  (Roles: developer)
 * @request GET:/rdb/search/v1/indices/{index}
 * @secure
 */
export const searchV1IndicesDetail = (
  client: AxiosInstance,
  index: string,
  params: RequestParams = {},
) =>
  client
    .request<GetIndexAsyncResponse>({
      url: `/rdb/search/v1/indices/${index}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Search
 * @name SearchV1IndicesList
 * @summary  (Roles: developer)
 * @request GET:/rdb/search/v1/indices
 * @secure
 */
export const searchV1IndicesList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<CatIndexResponsePagedData>({
      url: `/rdb/search/v1/indices`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Search
 * @name SearchV1OrganizationsAdvancedList
 * @request GET:/rdb/search/v1/organizations/advanced
 */
export const searchV1OrganizationsAdvancedList = (
  client: AxiosInstance,
  query: SearchV1OrganizationsAdvancedListParams,
  params: RequestParams = {},
) =>
  client
    .request<PublicOrganizationSearchResponsePagedData>({
      url: `/rdb/search/v1/organizations/advanced`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Search
 * @name SearchV1PeopleAdminSearchList
 * @request GET:/rdb/search/v1/people/admin-search
 */
export const searchV1PeopleAdminSearchList = (
  client: AxiosInstance,
  query: SearchV1PeopleAdminSearchListParams,
  params: RequestParams = {},
) =>
  client
    .request<PrivateOpenSearchResponsePagedData>({
      url: `/rdb/search/v1/people/admin-search`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Search
 * @name SearchV1PeopleAdvancedDetailsDetail
 * @summary  (Roles: developer)
 * @request GET:/rdb/search/v1/people/advanced-details/{key}
 * @secure
 */
export const searchV1PeopleAdvancedDetailsDetail = (
  client: AxiosInstance,
  { key, ...query }: SearchV1PeopleAdvancedDetailsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PrivateOpenSearchResponse>({
      url: `/rdb/search/v1/people/advanced-details/${key}`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Search
 * @name SearchV1PeopleList
 * @request GET:/rdb/search/v1/people
 */
export const searchV1PeopleList = (
  client: AxiosInstance,
  query: SearchV1PeopleListParams,
  params: RequestParams = {},
) =>
  client
    .request<PublicOpenSearchResponsePagedData>({
      url: `/rdb/search/v1/people`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Search
 * @name SearchV1RebuildCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/search/v1/rebuild
 * @secure
 */
export const searchV1RebuildCreate = (
  client: AxiosInstance,
  query: SearchV1RebuildCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/search/v1/rebuild`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Search
 * @name SearchV1RebuildCreate2
 * @summary  (Roles: developer)
 * @request POST:/rdb/search/v1/rebuild/{key}
 * @originalName searchV1RebuildCreate
 * @duplicate
 * @secure
 */
export const searchV1RebuildCreate2 = (
  client: AxiosInstance,
  { key, ...query }: SearchV1RebuildCreate2Params,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/search/v1/rebuild/${key}`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Search
 * @name SearchV1UsersList
 * @summary  (Roles: developer, cs_admin)
 * @request GET:/rdb/search/v1/users
 * @secure
 */
export const searchV1UsersList = (
  client: AxiosInstance,
  query: SearchV1UsersListParams,
  params: RequestParams = {},
) =>
  client
    .request<UserSearchResponsePagedData>({
      url: `/rdb/search/v1/users`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags CollectiveGroup
 * @name V1CollectiveGroupsAutocompleteList
 * @summary  (Roles: developer, rdb2, rdb3, rdb4, rdb5)
 * @request GET:/rdb/v1/collective-groups/autocomplete
 * @secure
 */
export const v1CollectiveGroupsAutocompleteList = (
  client: AxiosInstance,
  query: V1CollectiveGroupsAutocompleteListParams,
  params: RequestParams = {},
) =>
  client
    .request<UInt64StringKeyValuePair[]>({
      url: `/rdb/v1/collective-groups/autocomplete`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags CollectiveGroup
 * @name V1CollectiveGroupsCreate
 * @summary  (Roles: developer, rdb2, rdb3, rdb4, rdb5)
 * @request POST:/rdb/v1/collective-groups
 * @secure
 */
export const v1CollectiveGroupsCreate = (
  client: AxiosInstance,
  data: CreateCollectiveGroupRequest,
  params: RequestParams = {},
) =>
  client
    .request<CollectiveGroupResponse>({
      url: `/rdb/v1/collective-groups`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags CollectiveGroup
 * @name V1CollectiveGroupsDelete
 * @summary  (Roles: developer, rdb2, rdb3, rdb4, rdb5)
 * @request DELETE:/rdb/v1/collective-groups/{key}
 * @secure
 */
export const v1CollectiveGroupsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/collective-groups/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags CollectiveGroup
 * @name V1CollectiveGroupsUpdate
 * @summary  (Roles: developer, rdb2, rdb3, rdb4, rdb5)
 * @request PUT:/rdb/v1/collective-groups/{key}
 * @secure
 */
export const v1CollectiveGroupsUpdate = (
  client: AxiosInstance,
  key: number,
  data: UpdateCollectiveGroupRequest,
  params: RequestParams = {},
) =>
  client
    .request<CollectiveGroupResponse>({
      url: `/rdb/v1/collective-groups/${key}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Conference
 * @name V1ConferencesDetail
 * @summary Get the Conference for the specified key.
 * @request GET:/rdb/v1/conferences/{key}
 */
export const v1ConferencesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ConferenceResponse>({
      url: `/rdb/v1/conferences/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Conference
 * @name V1ConferencesList
 * @summary Get all Conferences optionally filtered by League.
 * @request GET:/rdb/v1/conferences
 */
export const v1ConferencesList = (
  client: AxiosInstance,
  query: V1ConferencesListParams,
  params: RequestParams = {},
) =>
  client
    .request<ConferenceResponse[]>({
      url: `/rdb/v1/conferences`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Conference
 * @name V1ConferencesOrganizationsDetail
 * @summary Get all Organizations in a Conference for the given year.
 * @request GET:/rdb/v1/conferences/{conferenceKey}/organizations
 */
export const v1ConferencesOrganizationsDetail = (
  client: AxiosInstance,
  { conferenceKey, ...query }: V1ConferencesOrganizationsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ConferenceOrgResponse[]>({
      url: `/rdb/v1/conferences/${conferenceKey}/organizations`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Conference
 * @name V1ConferencesUpdate
 * @summary Create/Update a Conference.
 * @request PUT:/rdb/v1/conferences
 * @secure
 */
export const v1ConferencesUpdate = (
  client: AxiosInstance,
  data: RenovateConferencesReq,
  params: RequestParams = {},
) =>
  client
    .request<ConferenceResponse[]>({
      url: `/rdb/v1/conferences`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonContactInfo
 * @name V1ContactInfoContributionsList
 * @summary Gets the contact info contributions by user.
 * @request GET:/rdb/v1/contact-info/contributions
 * @secure
 */
export const v1ContactInfoContributionsList = (
  client: AxiosInstance,
  query: V1ContactInfoContributionsListParams,
  params: RequestParams = {},
) =>
  client
    .request<PersonContactInfoContributionResponsePagedData>({
      url: `/rdb/v1/contact-info/contributions`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonContactInfo
 * @name V1ContactInfoIndustryTop500List
 * @summary Gets the industry top 500 with contact info for a sport and year.
 * @request GET:/rdb/v1/contact-info/industry-top-500
 * @secure
 */
export const v1ContactInfoIndustryTop500List = (
  client: AxiosInstance,
  query: V1ContactInfoIndustryTop500ListParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerItemContactInfoResponsePagedData>({
      url: `/rdb/v1/contact-info/industry-top-500`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonContactInfo
 * @name V1ContactInfoKpisList
 * @summary Gets the contact info KPIs.
 * @request GET:/rdb/v1/contact-info/kpis
 * @secure
 */
export const v1ContactInfoKpisList = (
  client: AxiosInstance,
  query: V1ContactInfoKpisListParams,
  params: RequestParams = {},
) =>
  client
    .request<PersonContactInfoKPIResponsePagedData>({
      url: `/rdb/v1/contact-info/kpis`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonContactInfo
 * @name V1ContactInfoList
 * @summary Gets the contact info for multiple people.
 * @request GET:/rdb/v1/contact-info
 * @secure
 */
export const v1ContactInfoList = (
  client: AxiosInstance,
  query: V1ContactInfoListParams,
  params: RequestParams = {},
) =>
  client
    .request<PersonContactInfoBatchResponsePagedData>({
      url: `/rdb/v1/contact-info`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonContactInfo
 * @name V1ContactInfoTop300List
 * @summary Gets the top 300 with contact info for a sport and year.
 * @request GET:/rdb/v1/contact-info/top-300
 * @secure
 */
export const v1ContactInfoTop300List = (
  client: AxiosInstance,
  query: V1ContactInfoTop300ListParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerItemContactInfoResponsePagedData>({
      url: `/rdb/v1/contact-info/top-300`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonContactInfo
 * @name V1ContactInfoTransferPortalTop750List
 * @summary Gets the transfer portal top 750 with contact info for a sport and year.
 * @request GET:/rdb/v1/contact-info/transfer-portal-top-750
 * @secure
 */
export const v1ContactInfoTransferPortalTop750List = (
  client: AxiosInstance,
  query: V1ContactInfoTransferPortalTop750ListParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerItemContactInfoResponsePagedData>({
      url: `/rdb/v1/contact-info/transfer-portal-top-750`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DatabaseUpdate
 * @name V1DatabaseupdatesCreate
 * @summary Creates a new Database Update.
 * @request POST:/rdb/v1/databaseupdates
 * @secure
 */
export const v1DatabaseupdatesCreate = (
  client: AxiosInstance,
  data: DatabaseUpdateRequest,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/databaseupdates`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DatabaseUpdate
 * @name V1DatabaseupdatesDelete
 * @summary Deletes the Database Update for a key
 * @request DELETE:/rdb/v1/databaseupdates/{key}
 * @secure
 */
export const v1DatabaseupdatesDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<NoContentResult>({
      url: `/rdb/v1/databaseupdates/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DatabaseUpdate
 * @name V1DatabaseupdatesUpdate
 * @summary Updates the Database Update for a key
 * @request PUT:/rdb/v1/databaseupdates/{key}
 * @secure
 */
export const v1DatabaseupdatesUpdate = (
  client: AxiosInstance,
  key: number,
  data: DatabaseUpdateRequest,
  params: RequestParams = {},
) =>
  client
    .request<NoContentResult>({
      url: `/rdb/v1/databaseupdates/${key}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DraftPick
 * @name V1DraftDraftPicksDetail
 * @summary Paginated listing of picks for a given draft or by round
 * @request GET:/rdb/v1/draft/{draftKey}/draft-picks
 * @secure
 */
export const v1DraftDraftPicksDetail = (
  client: AxiosInstance,
  { draftKey, ...query }: V1DraftDraftPicksDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DraftPickSimpleResponsePagedData>({
      url: `/rdb/v1/draft/${draftKey}/draft-picks`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DraftPick
 * @name V1DraftPicksDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request DELETE:/rdb/v1/draft-picks/{key}
 * @secure
 */
export const v1DraftPicksDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/rdb/v1/draft-picks/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DraftPick
 * @name V1DraftPicksDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/draft-picks/{key}
 * @secure
 */
export const v1DraftPicksDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<DraftPickResponse>({
      url: `/rdb/v1/draft-picks/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DraftPick
 * @name V1DraftPicksList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/draft-picks
 * @secure
 */
export const v1DraftPicksList = (
  client: AxiosInstance,
  query: V1DraftPicksListParams,
  params: RequestParams = {},
) =>
  client
    .request<DraftPickResponsePagedData>({
      url: `/rdb/v1/draft-picks`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DraftPick
 * @name V1DraftPicksRenovateAdminUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/draft-picks/renovate-admin
 * @secure
 */
export const v1DraftPicksRenovateAdminUpdate = (
  client: AxiosInstance,
  data: RenovateDraftPickRequest,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/draft-picks/renovate-admin`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name V1DraftsAutocompleteList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/drafts/autocomplete
 * @secure
 */
export const v1DraftsAutocompleteList = (
  client: AxiosInstance,
  query: V1DraftsAutocompleteListParams,
  params: RequestParams = {},
) =>
  client
    .request<DraftAutocompleteResponse[]>({
      url: `/rdb/v1/drafts/autocomplete`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name V1DraftsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/drafts/{key}
 * @secure
 */
export const v1DraftsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<DraftResponse>({
      url: `/rdb/v1/drafts/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name V1DraftsDistributionByStateDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/drafts/{key}/distribution-by-state
 * @secure
 */
export const v1DraftsDistributionByStateDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<DraftDistributionByStateResponse>({
      url: `/rdb/v1/drafts/${key}/distribution-by-state`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name V1DraftsEnsureEligibleSummaryCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/drafts/{key}/ensure-eligible-summary
 * @secure
 */
export const v1DraftsEnsureEligibleSummaryCreate = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/drafts/${key}/ensure-eligible-summary`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name V1DraftsList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/drafts
 * @secure
 */
export const v1DraftsList = (
  client: AxiosInstance,
  query: V1DraftsListParams,
  params: RequestParams = {},
) =>
  client
    .request<DraftResponsePagedData>({
      url: `/rdb/v1/drafts`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name V1DraftsRenovateAdminUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/drafts/renovate-admin
 * @secure
 */
export const v1DraftsRenovateAdminUpdate = (
  client: AxiosInstance,
  data: RenovateDraftRequest,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/drafts/renovate-admin`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Event
 * @name V1EventsAutocompleteList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/events/autocomplete
 * @secure
 */
export const v1EventsAutocompleteList = (
  client: AxiosInstance,
  query: V1EventsAutocompleteListParams,
  params: RequestParams = {},
) =>
  client
    .request<EventAutocompleteResponsePagedData>({
      url: `/rdb/v1/events/autocomplete`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Event
 * @name V1EventsCreate
 * @summary Add or Updates a specific event with details
 * @request POST:/rdb/v1/events
 * @secure
 */
export const v1EventsCreate = (
  client: AxiosInstance,
  data: EventUpdateRequest,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/events`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Event
 * @name V1EventsDelete
 * @summary Deletes a specific event by key
 * @request DELETE:/rdb/v1/events/{eventKey}
 * @secure
 */
export const v1EventsDelete = (
  client: AxiosInstance,
  eventKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/events/${eventKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Event
 * @name V1EventsDetail
 * @summary Gets specific event based on key
 * @request GET:/rdb/v1/events/{eventKey}
 * @secure
 */
export const v1EventsDetail = (
  client: AxiosInstance,
  eventKey: number,
  params: RequestParams = {},
) =>
  client
    .request<EventResponseRecord>({
      url: `/rdb/v1/events/${eventKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Event
 * @name V1EventsList
 * @summary Gets paged list of all events in the system
 * @request GET:/rdb/v1/events
 * @secure
 */
export const v1EventsList = (
  client: AxiosInstance,
  query: V1EventsListParams,
  params: RequestParams = {},
) =>
  client
    .request<EventResponseRecordPagedData>({
      url: `/rdb/v1/events`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Event
 * @name V1EventsUpdate
 * @summary Add or Updates a specific event with details
 * @request PUT:/rdb/v1/events
 * @secure
 */
export const v1EventsUpdate = (
  client: AxiosInstance,
  data: EventUpdateRequest,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/events`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1GamesDelete
 * @summary Deletes the Game for a key
 * @request DELETE:/rdb/v1/games/{key}
 * @secure
 */
export const v1GamesDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/games/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1GamesDetail
 * @summary Get details for a specific game.
 * @request GET:/rdb/v1/games/{gameKey}
 */
export const v1GamesDetail = (
  client: AxiosInstance,
  gameKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ScoreboardResponse>({
      url: `/rdb/v1/games/${gameKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1GamesRefreshCreate
 * @summary  (Roles: developer, rdb5, rdb4)
 * @request POST:/rdb/v1/games/{key}/refresh
 * @secure
 */
export const v1GamesRefreshCreate = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ScoreboardResponse>({
      url: `/rdb/v1/games/${key}/refresh`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1GamesRenovateByMatchIdCreate
 * @summary  (Roles: developer, rdb5, rdb4)
 * @request POST:/rdb/v1/games/renovate-by-match-id
 * @secure
 */
export const v1GamesRenovateByMatchIdCreate = (
  client: AxiosInstance,
  data: RenovateGameByMatchIdRequest,
  params: RequestParams = {},
) =>
  client
    .request<ScoreboardResponse>({
      url: `/rdb/v1/games/renovate-by-match-id`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Insider
 * @name V1InsidersCreate
 * @summary Creates a new Insider.
 * @request POST:/rdb/v1/insiders
 * @secure
 */
export const v1InsidersCreate = (
  client: AxiosInstance,
  data: InsiderRequest,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/insiders`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Insider
 * @name V1InsidersDelete
 * @summary Deletes the Insider for a key
 * @request DELETE:/rdb/v1/insiders/{key}
 * @secure
 */
export const v1InsidersDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<NoContentResult>({
      url: `/rdb/v1/insiders/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Insider
 * @name V1InsidersList
 * @summary Get a list of insiders.
 * @request GET:/rdb/v1/insiders
 * @secure
 */
export const v1InsidersList = (
  client: AxiosInstance,
  query: V1InsidersListParams,
  params: RequestParams = {},
) =>
  client
    .request<InsiderResponsePagedData>({
      url: `/rdb/v1/insiders`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Insider
 * @name V1InsidersScrapeDetail
 * @summary  (Roles: developer, rdb5, cron)
 * @request GET:/rdb/v1/insiders/{key}/scrape
 * @secure
 */
export const v1InsidersScrapeDetail = (
  client: AxiosInstance,
  { key, ...query }: V1InsidersScrapeDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<InsiderScrapeResponse[]>({
      url: `/rdb/v1/insiders/${key}/scrape`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Insider
 * @name V1InsidersScrapeHistoryList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/insiders/scrape-history
 * @secure
 */
export const v1InsidersScrapeHistoryList = (
  client: AxiosInstance,
  query: V1InsidersScrapeHistoryListParams,
  params: RequestParams = {},
) =>
  client
    .request<InsiderScrapeHistoryResponse[]>({
      url: `/rdb/v1/insiders/scrape-history`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Insider
 * @name V1InsidersScrapeList
 * @summary  (Roles: developer, rdb5, cron)
 * @request GET:/rdb/v1/insiders/scrape
 * @secure
 */
export const v1InsidersScrapeList = (
  client: AxiosInstance,
  query: V1InsidersScrapeListParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/insiders/scrape`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Insider
 * @name V1InsidersUpdate
 * @summary Updates the Insider for a key
 * @request PUT:/rdb/v1/insiders/{key}
 * @secure
 */
export const v1InsidersUpdate = (
  client: AxiosInstance,
  key: number,
  data: InsiderRequest,
  params: RequestParams = {},
) =>
  client
    .request<NoContentResult>({
      url: `/rdb/v1/insiders/${key}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags LeagueConferenceOrganization
 * @name V1LeagueConferenceOrganizationsDetail
 * @request GET:/rdb/v1/league-conference-organizations/{key}
 */
export const v1LeagueConferenceOrganizationsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<LeagueConferenceOrganizationRes>({
      url: `/rdb/v1/league-conference-organizations/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags LeagueConferenceOrganization
 * @name V1LeagueConferenceOrganizationsList
 * @request GET:/rdb/v1/league-conference-organizations
 */
export const v1LeagueConferenceOrganizationsList = (
  client: AxiosInstance,
  query: V1LeagueConferenceOrganizationsListParams,
  params: RequestParams = {},
) =>
  client
    .request<LeagueConferenceOrganizationResPagedData>({
      url: `/rdb/v1/league-conference-organizations`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags LeagueConferenceOrganization
 * @name V1LeagueConferenceOrganizationsRenovateAdminUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request PUT:/rdb/v1/league-conference-organizations/renovate-admin
 * @secure
 */
export const v1LeagueConferenceOrganizationsRenovateAdminUpdate = (
  client: AxiosInstance,
  data: RenovateLeagueConferenceOrganizationsReq,
  params: RequestParams = {},
) =>
  client
    .request<LeagueConferenceOrganizationRes[]>({
      url: `/rdb/v1/league-conference-organizations/renovate-admin`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags LeagueConference
 * @name V1LeagueConferencesDetail
 * @request GET:/rdb/v1/league-conferences/{key}
 */
export const v1LeagueConferencesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<LeagueConferenceRes>({
      url: `/rdb/v1/league-conferences/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags LeagueConference
 * @name V1LeagueConferencesList
 * @request GET:/rdb/v1/league-conferences
 */
export const v1LeagueConferencesList = (
  client: AxiosInstance,
  query: V1LeagueConferencesListParams,
  params: RequestParams = {},
) =>
  client
    .request<LeagueConferenceResPagedData>({
      url: `/rdb/v1/league-conferences`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags LeagueConference
 * @name V1LeagueConferencesRenovateAdminUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request PUT:/rdb/v1/league-conferences/renovate-admin
 * @secure
 */
export const v1LeagueConferencesRenovateAdminUpdate = (
  client: AxiosInstance,
  data: RenovateLeagueConferencesReq,
  params: RequestParams = {},
) =>
  client
    .request<LeagueConferenceRes[]>({
      url: `/rdb/v1/league-conferences/renovate-admin`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags League
 * @name V1LeaguesDetail
 * @request GET:/rdb/v1/leagues/{key}
 */
export const v1LeaguesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<LeagueResponse>({
      url: `/rdb/v1/leagues/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1LeagueSeasonsRefreshScheduleCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/league-seasons/{key}/refresh-schedule
 * @secure
 */
export const v1LeagueSeasonsRefreshScheduleCreate = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/league-seasons/${key}/refresh-schedule`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1LeagueSeasonsRefreshScheduleLocCreate
 * @request POST:/rdb/v1/league-seasons/{key}/refresh-schedule-loc
 */
export const v1LeagueSeasonsRefreshScheduleLocCreate = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/league-seasons/${key}/refresh-schedule-loc`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags League
 * @name V1LeaguesList
 * @request GET:/rdb/v1/leagues
 */
export const v1LeaguesList = (
  client: AxiosInstance,
  query: V1LeaguesListParams,
  params: RequestParams = {},
) =>
  client
    .request<LeagueResponsePagedData>({
      url: `/rdb/v1/leagues`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags League
 * @name V1LeaguesRenovateAdminUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request PUT:/rdb/v1/leagues/renovate-admin
 * @secure
 */
export const v1LeaguesRenovateAdminUpdate = (
  client: AxiosInstance,
  data: RenovateLeagueRequest,
  params: RequestParams = {},
) =>
  client
    .request<LeagueResponse[]>({
      url: `/rdb/v1/leagues/renovate-admin`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1LeaguesScoreboardDetail
 * @request GET:/rdb/v1/leagues/{key}/scoreboard
 */
export const v1LeaguesScoreboardDetail = (
  client: AxiosInstance,
  { key, ...query }: V1LeaguesScoreboardDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ScoreboardGroupByResponseLeagueScoreBoardRelatedResponseRelatedPagedData>(
      {
        url: `/rdb/v1/leagues/${key}/scoreboard`,
        method: 'GET',
        params: query,
        ...params,
      },
    )
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1LeaguesScoreboardFiltersDetail
 * @request GET:/rdb/v1/leagues/{key}/scoreboard-filters
 */
export const v1LeaguesScoreboardFiltersDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ScoreboardFiltersResponseLeagueResponseRelatedPagedData>({
      url: `/rdb/v1/leagues/${key}/scoreboard-filters`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1LeaguesSeedSeasonsCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/leagues/{key}/seed-seasons
 * @secure
 */
export const v1LeaguesSeedSeasonsCreate = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/leagues/${key}/seed-seasons`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Measurement
 * @name V1MeasurementsDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request DELETE:/rdb/v1/measurements/{key}
 * @secure
 */
export const v1MeasurementsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/measurements/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationKeyMapping
 * @name V1OrganizationKeyMappingsDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request DELETE:/rdb/v1/organization-key-mappings/{key}
 * @secure
 */
export const v1OrganizationKeyMappingsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationKeyMappingRes>({
      url: `/rdb/v1/organization-key-mappings/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationKeyMapping
 * @name V1OrganizationKeyMappingsImportForSourceCreate
 * @summary  (Roles: developer, cron)
 * @request POST:/rdb/v1/organization-key-mappings/import-for-source
 * @secure
 */
export const v1OrganizationKeyMappingsImportForSourceCreate = (
  client: AxiosInstance,
  query: V1OrganizationKeyMappingsImportForSourceCreateParams,
  data: V1OrganizationKeyMappingsImportForSourceCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/organization-key-mappings/import-for-source`,
      method: 'POST',
      params: query,
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationKeyMapping
 * @name V1OrganizationKeyMappingsUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/organization-key-mappings
 * @secure
 */
export const v1OrganizationKeyMappingsUpdate = (
  client: AxiosInstance,
  data: RenOrganizationKeyMappingReq,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationKeyMappingRes>({
      url: `/rdb/v1/organization-key-mappings`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1OrganizationRecruitmentInterestsDetail
 * @summary Get all RecruitmentInterests for a given organization
 * @request GET:/rdb/v1/organization/{organizationKey}/recruitment-interests
 * @secure
 */
export const v1OrganizationRecruitmentInterestsDetail = (
  client: AxiosInstance,
  organizationKey: number,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentInterestResponsePagedData>({
      url: `/rdb/v1/organization/${organizationKey}/recruitment-interests`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1OrganizationRecruitmentInterestsDetail2
 * @summary Get all RecruitmentInterests for a given organization
 * @request GET:/rdb/v1/organization/{organizationKey}/recruitmentInterests
 * @originalName v1OrganizationRecruitmentInterestsDetail
 * @duplicate
 * @secure
 */
export const v1OrganizationRecruitmentInterestsDetail2 = (
  client: AxiosInstance,
  organizationKey: number,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentInterestResponsePagedData>({
      url: `/rdb/v1/organization/${organizationKey}/recruitmentInterests`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name V1OrganizationsAutocompletePaginatedList
 * @request GET:/rdb/v1/organizations/autocomplete-paginated
 * @deprecated
 */
export const v1OrganizationsAutocompletePaginatedList = (
  client: AxiosInstance,
  query: V1OrganizationsAutocompletePaginatedListParams,
  params: RequestParams = {},
) =>
  client
    .request<OrgAutocompleteResponsePagedData>({
      url: `/rdb/v1/organizations/autocomplete-paginated`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonContactInfo
 * @name V1OrganizationsCommitsContactInfoDetail
 * @summary Gets the commits with contact info for an organization, sport, and year.
 * @request GET:/rdb/v1/organizations/{orgKey}/commits/contact-info
 * @secure
 */
export const v1OrganizationsCommitsContactInfoDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: V1OrganizationsCommitsContactInfoDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerItemContactInfoResponsePagedData>({
      url: `/rdb/v1/organizations/${orgKey}/commits/contact-info`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name V1OrganizationsCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request POST:/rdb/v1/organizations
 * @secure
 */
export const v1OrganizationsCreate = (
  client: AxiosInstance,
  data: CreateOrganizationRequest,
  params: RequestParams = {},
) =>
  client
    .request<OrgSingleAdminResponseResourceResponseMessage>({
      url: `/rdb/v1/organizations`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name V1OrganizationsDetail
 * @request GET:/rdb/v1/organizations/{key}
 */
export const v1OrganizationsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<OrgSingleAdminResponse>({
      url: `/rdb/v1/organizations/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportOrganization
 * @name V1OrganizationsEnsureRosterCreate
 * @summary  (Roles: developer, rdb5, rdb4)
 * @request POST:/rdb/v1/organizations/{organizationKey}/ensure-roster
 * @secure
 */
export const v1OrganizationsEnsureRosterCreate = (
  client: AxiosInstance,
  { organizationKey, ...query }: V1OrganizationsEnsureRosterCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/organizations/${organizationKey}/ensure-roster`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportOrganization
 * @name V1OrganizationsEnsureRosterLocCreate
 * @request POST:/rdb/v1/organizations/ensure-roster-loc
 */
export const v1OrganizationsEnsureRosterLocCreate = (
  client: AxiosInstance,
  data: EnsureRostersBatchRequest,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/organizations/ensure-roster-loc`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name V1OrganizationsImportMasseyAffiliationCreate
 * @summary  (Roles: developer, cron)
 * @request POST:/rdb/v1/organizations/import-massey-affiliation
 * @secure
 */
export const v1OrganizationsImportMasseyAffiliationCreate = (
  client: AxiosInstance,
  query: V1OrganizationsImportMasseyAffiliationCreateParams,
  data: V1OrganizationsImportMasseyAffiliationCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<UploadByCsvResponse>({
      url: `/rdb/v1/organizations/import-massey-affiliation`,
      method: 'POST',
      params: query,
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name V1OrganizationsImportMasseyHierarchyCreate
 * @summary  (Roles: developer, cron)
 * @request POST:/rdb/v1/organizations/import-massey-hierarchy
 * @secure
 */
export const v1OrganizationsImportMasseyHierarchyCreate = (
  client: AxiosInstance,
  query: V1OrganizationsImportMasseyHierarchyCreateParams,
  data: V1OrganizationsImportMasseyHierarchyCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<UploadByCsvResponse>({
      url: `/rdb/v1/organizations/import-massey-hierarchy`,
      method: 'POST',
      params: query,
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationKeyMapping
 * @name V1OrganizationsMappingsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/organizations/{organizationKey}/mappings
 * @secure
 */
export const v1OrganizationsMappingsDetail = (
  client: AxiosInstance,
  organizationKey: number,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationKeyMappingResOrganizationResponseRelatedPagedData>({
      url: `/rdb/v1/organizations/${organizationKey}/mappings`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1OrganizationsOpponentsRecentScoresDetail
 * @request GET:/rdb/v1/organizations/{organizationKey}/opponents-recent-scores
 */
export const v1OrganizationsOpponentsRecentScoresDetail = (
  client: AxiosInstance,
  {
    organizationKey,
    ...query
  }: V1OrganizationsOpponentsRecentScoresDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ScoreboardResponseRelatedScheduleResponseRelatedPagedData>({
      url: `/rdb/v1/organizations/${organizationKey}/opponents-recent-scores`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationSportHierarchy
 * @name V1OrganizationSportHierarchiesDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request DELETE:/rdb/v1/organization-sport-hierarchies/{key}
 * @secure
 */
export const v1OrganizationSportHierarchiesDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/organization-sport-hierarchies/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationSportHierarchy
 * @name V1OrganizationSportHierarchiesImportCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/organization-sport-hierarchies/import
 * @secure
 */
export const v1OrganizationSportHierarchiesImportCreate = (
  client: AxiosInstance,
  query: V1OrganizationSportHierarchiesImportCreateParams,
  data: V1OrganizationSportHierarchiesImportCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/organization-sport-hierarchies/import`,
      method: 'POST',
      params: query,
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationSportHierarchy
 * @name V1OrganizationSportHierarchiesUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request PUT:/rdb/v1/organization-sport-hierarchies
 * @secure
 */
export const v1OrganizationSportHierarchiesUpdate = (
  client: AxiosInstance,
  data: RenOrgSportHierarchyReq,
  params: RequestParams = {},
) =>
  client
    .request<OrgSportHierarchyForOrgSportRes>({
      url: `/rdb/v1/organization-sport-hierarchies`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationSportHierarchy
 * @name V1OrganizationSportsCloneHierarchiesCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request POST:/rdb/v1/organization-sports/{organizationSportKey}/clone-hierarchies
 * @secure
 */
export const v1OrganizationSportsCloneHierarchiesCreate = (
  client: AxiosInstance,
  organizationSportKey: number,
  data: V1OrganizationSportsCloneHierarchiesCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<any>({
      url: `/rdb/v1/organization-sports/${organizationSportKey}/clone-hierarchies`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationSport
 * @name V1OrganizationSportsDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request DELETE:/rdb/v1/organization-sports
 * @secure
 */
export const v1OrganizationSportsDelete = (
  client: AxiosInstance,
  data: DeleteOrgSportBodyReq,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/organization-sports`,
      method: 'DELETE',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationSportHierarchy
 * @name V1OrganizationSportsHierarchiesDetail
 * @request GET:/rdb/v1/organization-sports/{organizationSportKey}/hierarchies
 */
export const v1OrganizationSportsHierarchiesDetail = (
  client: AxiosInstance,
  {
    organizationSportKey,
    ...query
  }: V1OrganizationSportsHierarchiesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<OrgSportHierarchyForOrgSportResRelatedOrgSportHierarcyFilterResRelatedPagedData>(
      {
        url: `/rdb/v1/organization-sports/${organizationSportKey}/hierarchies`,
        method: 'GET',
        params: query,
        ...params,
      },
    )
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationSportHierarchy
 * @name V1OrganizationSportsHierarchyYearsDetail
 * @request GET:/rdb/v1/organization-sports/{organizationSportKey}/hierarchy-years
 */
export const v1OrganizationSportsHierarchyYearsDetail = (
  client: AxiosInstance,
  organizationSportKey: number,
  params: RequestParams = {},
) =>
  client
    .request<OrgSportHierarchyYearResFullOrgSportResRelatedPagedData>({
      url: `/rdb/v1/organization-sports/${organizationSportKey}/hierarchy-years`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationSport
 * @name V1OrganizationSportsRenovateUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/organization-sports/renovate
 * @secure
 */
export const v1OrganizationSportsRenovateUpdate = (
  client: AxiosInstance,
  data: RenOrgSportBodyReq,
  params: RequestParams = {},
) =>
  client
    .request<OrgSportForOrgRes>({
      url: `/rdb/v1/organization-sports/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonContactInfo
 * @name V1OrganizationsRosterContactInfoDetail
 * @summary Gets the roster with contact info for an organization, sport, and year.
 * @request GET:/rdb/v1/organizations/{orgKey}/roster/contact-info
 * @secure
 */
export const v1OrganizationsRosterContactInfoDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: V1OrganizationsRosterContactInfoDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerItemContactInfoResponsePagedData>({
      url: `/rdb/v1/organizations/${orgKey}/roster/contact-info`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1OrganizationsScheduleDetail
 * @summary Get schedule for a org and season.
 * @request GET:/rdb/v1/organizations/{orgKey}/schedule
 */
export const v1OrganizationsScheduleDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: V1OrganizationsScheduleDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ScheduleResponseRelatedScheduleResponseRelatedPagedData>({
      url: `/rdb/v1/organizations/${orgKey}/schedule`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name V1OrganizationsSearchList
 * @request GET:/rdb/v1/organizations/search
 * @deprecated
 */
export const v1OrganizationsSearchList = (
  client: AxiosInstance,
  query: V1OrganizationsSearchListParams,
  params: RequestParams = {},
) =>
  client
    .request<AdvancedOrgSearchResponsePagedData>({
      url: `/rdb/v1/organizations/search`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name V1OrganizationsSearchRebuildCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/organizations/search/rebuild
 * @secure
 */
export const v1OrganizationsSearchRebuildCreate = (
  client: AxiosInstance,
  query: V1OrganizationsSearchRebuildCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/organizations/search/rebuild`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name V1OrganizationsSearchRebuildCreate2
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/organizations/{orgKey}/search/rebuild
 * @originalName v1OrganizationsSearchRebuildCreate
 * @duplicate
 * @secure
 */
export const v1OrganizationsSearchRebuildCreate2 = (
  client: AxiosInstance,
  orgKey: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/organizations/${orgKey}/search/rebuild`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationSport
 * @name V1OrganizationsSportsDetail
 * @request GET:/rdb/v1/organizations/{organizationKey}/sports
 */
export const v1OrganizationsSportsDetail = (
  client: AxiosInstance,
  organizationKey: number,
  params: RequestParams = {},
) =>
  client
    .request<OrgSportForOrgResOrganizationResponseRelatedPagedData>({
      url: `/rdb/v1/organizations/${organizationKey}/sports`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonContactInfo
 * @name V1OrganizationsTargetsContactInfoDetail
 * @summary Gets the targets with contact info for an organization, sport, and year.
 * @request GET:/rdb/v1/organizations/{orgKey}/targets/contact-info
 * @secure
 */
export const v1OrganizationsTargetsContactInfoDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: V1OrganizationsTargetsContactInfoDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerItemContactInfoResponsePagedData>({
      url: `/rdb/v1/organizations/${orgKey}/targets/contact-info`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name V1OrganizationsTypesList
 * @request GET:/rdb/v1/organizations/types
 * @deprecated
 */
export const v1OrganizationsTypesList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<Int32StringKeyValuePair[]>({
      url: `/rdb/v1/organizations/types`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name V1OrganizationsUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/organizations/{key}
 * @secure
 */
export const v1OrganizationsUpdate = (
  client: AxiosInstance,
  key: number,
  data: UpdateOrganizationRequest,
  params: RequestParams = {},
) =>
  client
    .request<OrgSingleAdminResponseResourceResponseMessage>({
      url: `/rdb/v1/organizations/${key}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrgTypeClassHierarchy
 * @name V1OrgTypeClassHierarchiesDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request DELETE:/rdb/v1/org-type-class-hierarchies/{key}
 * @secure
 */
export const v1OrgTypeClassHierarchiesDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/org-type-class-hierarchies/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrgTypeClassHierarchy
 * @name V1OrgTypeClassHierarchiesList
 * @request GET:/rdb/v1/org-type-class-hierarchies
 */
export const v1OrgTypeClassHierarchiesList = (
  client: AxiosInstance,
  query: V1OrgTypeClassHierarchiesListParams,
  params: RequestParams = {},
) =>
  client
    .request<OrgTypeClassHierarchyResRelatedOrgTypeClassHierarcyFilterResRelatedPagedData>(
      {
        url: `/rdb/v1/org-type-class-hierarchies`,
        method: 'GET',
        params: query,
        ...params,
      },
    )
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrgTypeClassHierarchy
 * @name V1OrgTypeClassHierarchiesOrganizationsDetail
 * @request GET:/rdb/v1/org-type-class-hierarchies/{key}/organizations
 */
export const v1OrgTypeClassHierarchiesOrganizationsDetail = (
  client: AxiosInstance,
  { key, ...query }: V1OrgTypeClassHierarchiesOrganizationsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationResponseOrgTypeClassHierarchyResRelatedPagedData>({
      url: `/rdb/v1/org-type-class-hierarchies/${key}/organizations`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrgTypeClassHierarchy
 * @name V1OrgTypeClassHierarchiesUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request PUT:/rdb/v1/org-type-class-hierarchies
 * @secure
 */
export const v1OrgTypeClassHierarchiesUpdate = (
  client: AxiosInstance,
  data: RenOrgTypeClassHierarchyReq,
  params: RequestParams = {},
) =>
  client
    .request<OrgTypeClassHierarchyRes>({
      url: `/rdb/v1/org-type-class-hierarchies`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleAdminDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/people/{key}/admin
 * @secure
 */
export const v1PeopleAdminDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<AdminPerResponse>({
      url: `/rdb/v1/people/${key}/admin`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleAdminNavDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/people/{key}/admin-nav
 * @secure
 */
export const v1PeopleAdminNavDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<AdminNavPerResponse>({
      url: `/rdb/v1/people/${key}/admin-nav`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleAdminSearchList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/people/admin-search
 * @secure
 */
export const v1PeopleAdminSearchList = (
  client: AxiosInstance,
  query: V1PeopleAdminSearchListParams,
  params: RequestParams = {},
) =>
  client
    .request<PrivateOpenSearchResponsePagedData>({
      url: `/rdb/v1/people/admin-search`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleAdvancedDetailsDetail
 * @summary  (Roles: developer)
 * @request GET:/rdb/v1/people/advanced-details/{key}
 * @secure
 */
export const v1PeopleAdvancedDetailsDetail = (
  client: AxiosInstance,
  { key, ...query }: V1PeopleAdvancedDetailsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PrivateOpenSearchResponse>({
      url: `/rdb/v1/people/advanced-details/${key}`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonContactInfo
 * @name V1PeopleContactInfoDetail
 * @summary Gets the contact info for a person.
 * @request GET:/rdb/v1/people/{personKey}/contact-info
 * @secure
 */
export const v1PeopleContactInfoDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonContactInfoResponse>({
      url: `/rdb/v1/people/${personKey}/contact-info`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonContactInfo
 * @name V1PeopleContactInfoLogsDetail
 * @summary Gets the contact info logs for a person.
 * @request GET:/rdb/v1/people/{personKey}/contact-info/logs
 * @secure
 */
export const v1PeopleContactInfoLogsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V1PeopleContactInfoLogsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PersonContactInfoLogResponsePagedData>({
      url: `/rdb/v1/people/${personKey}/contact-info/logs`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonContactInfo
 * @name V1PeopleContactInfoMigrateUpdate
 * @summary Migrates the existing contact info for a person to the new contact info structure.
 * @request PUT:/rdb/v1/people/{personKey}/contact-info/migrate
 * @secure
 */
export const v1PeopleContactInfoMigrateUpdate = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonContactInfoResponse>({
      url: `/rdb/v1/people/${personKey}/contact-info/migrate`,
      method: 'PUT',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonContactInfo
 * @name V1PeopleContactInfoMigrateUpdate2
 * @summary Migrates the existing contact info for people to the new contact info structure in batches.
 * @request PUT:/rdb/v1/people/contact-info/migrate
 * @originalName v1PeopleContactInfoMigrateUpdate
 * @duplicate
 * @secure
 */
export const v1PeopleContactInfoMigrateUpdate2 = (
  client: AxiosInstance,
  query: V1PeopleContactInfoMigrateUpdate2Params,
  params: RequestParams = {},
) =>
  client
    .request<number[]>({
      url: `/rdb/v1/people/contact-info/migrate`,
      method: 'PUT',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonContactInfo
 * @name V1PeopleContactInfoUpdate
 * @summary Updates the contact info for a person.
 * @request PUT:/rdb/v1/people/{personKey}/contact-info
 * @secure
 */
export const v1PeopleContactInfoUpdate = (
  client: AxiosInstance,
  personKey: number,
  data: PersonContactInfoRequest,
  params: RequestParams = {},
) =>
  client
    .request<PersonContactInfoResponse>({
      url: `/rdb/v1/people/${personKey}/contact-info`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request POST:/rdb/v1/people
 * @secure
 */
export const v1PeopleCreate = (
  client: AxiosInstance,
  data: CreatePersonRequest,
  params: RequestParams = {},
) =>
  client
    .request<AdminPerResponse>({
      url: `/rdb/v1/people`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request DELETE:/rdb/v1/people/{key}
 * @secure
 */
export const v1PeopleDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/people/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleEducationDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/people/{personKey}/education
 * @secure
 */
export const v1PeopleEducationDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonEducationResponse>({
      url: `/rdb/v1/people/${personKey}/education`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleEducationUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/people/{personKey}/education
 * @secure
 */
export const v1PeopleEducationUpdate = (
  client: AxiosInstance,
  personKey: number,
  data: V1PeopleEducationUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<PersonEducationResponse>({
      url: `/rdb/v1/people/${personKey}/education`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleEnsureDenormalizedOrganizationsCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/people/ensure-denormalized-organizations
 * @secure
 */
export const v1PeopleEnsureDenormalizedOrganizationsCreate = (
  client: AxiosInstance,
  query: V1PeopleEnsureDenormalizedOrganizationsCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/people/ensure-denormalized-organizations`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleEnsureDenormalizedTermsCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/people/ensure-denormalized-terms
 * @secure
 */
export const v1PeopleEnsureDenormalizedTermsCreate = (
  client: AxiosInstance,
  query: V1PeopleEnsureDenormalizedTermsCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/people/ensure-denormalized-terms`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Measurement
 * @name V1PeopleMeasurementsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/people/{personKey}/measurements
 * @secure
 */
export const v1PeopleMeasurementsDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<MeasurementPersonResponseRecord>({
      url: `/rdb/v1/people/${personKey}/measurements`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Measurement
 * @name V1PeopleMeasurementsUpdate
 * @summary Creates/Updates a list of measurements for a personKey.
 * @request PUT:/rdb/v1/people/{personKey}/measurements
 * @secure
 */
export const v1PeopleMeasurementsUpdate = (
  client: AxiosInstance,
  personKey: number,
  data: V1PeopleMeasurementsUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<MeasurementResponseRecord[]>({
      url: `/rdb/v1/people/${personKey}/measurements`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name V1PeopleOrganizationsAdminDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/people/{personKey}/organizations/admin
 * @secure
 */
export const v1PeopleOrganizationsAdminDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<AdminOrgResponse[]>({
      url: `/rdb/v1/people/${personKey}/organizations/admin`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonConnection
 * @name V1PeoplePersonConnectionsCreate
 * @summary Creates an Connection using Person Keys and SportKey
 * @request POST:/rdb/v1/people/{personKey}/person-connections
 * @secure
 */
export const v1PeoplePersonConnectionsCreate = (
  client: AxiosInstance,
  personKey: number,
  data: PersonConnectionRequest,
  params: RequestParams = {},
) =>
  client
    .request<PersonConnectionFullResponse>({
      url: `/rdb/v1/people/${personKey}/person-connections`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonConnection
 * @name V1PeoplePersonConnectionsDetail
 * @summary Gets Person Connections by Person Key
 * @request GET:/rdb/v1/people/{personKey}/person-connections
 * @deprecated
 * @secure
 */
export const v1PeoplePersonConnectionsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V1PeoplePersonConnectionsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PersonConnectionFullResponsePagedData>({
      url: `/rdb/v1/people/${personKey}/person-connections`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonConnection
 * @name V1PeoplePersonConnectionsUpdate
 * @summary Updates a Connection using Person Keys And SportKey
 * @request PUT:/rdb/v1/people/{personKey}/person-connections/{connectionKey}
 * @secure
 */
export const v1PeoplePersonConnectionsUpdate = (
  client: AxiosInstance,
  personKey: number,
  connectionKey: number,
  data: PersonConnectionRequest,
  params: RequestParams = {},
) =>
  client
    .request<PersonConnectionFullResponse>({
      url: `/rdb/v1/people/${personKey}/person-connections/${connectionKey}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportOrganization
 * @name V1PeoplePersonSportOrganizationsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/people/{personKey}/person-sport-organizations
 * @secure
 */
export const v1PeoplePersonSportOrganizationsDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonSportOrganizationBase[]>({
      url: `/rdb/v1/people/${personKey}/person-sport-organizations`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportOrganization
 * @name V1PeoplePersonSportOrganizationsSimpleDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/people/{personKey}/person-sport-organizations/simple
 * @secure
 */
export const v1PeoplePersonSportOrganizationsSimpleDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonSportOrganizationBase[]>({
      url: `/rdb/v1/people/${personKey}/person-sport-organizations/simple`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSport
 * @name V1PeoplePersonSportsDetail
 * @summary  (Roles: developer, rdb2, rdb3, rdb4, rdb5)
 * @request GET:/rdb/v1/people/{personKey}/person-sports
 * @secure
 */
export const v1PeoplePersonSportsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V1PeoplePersonSportsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PersonSportResponseRecordPagedData>({
      url: `/rdb/v1/people/${personKey}/person-sports`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonRelation
 * @name V1PeopleRelationsCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request POST:/rdb/v1/people/{perKey}/relations
 * @secure
 */
export const v1PeopleRelationsCreate = (
  client: AxiosInstance,
  perKey: number,
  data: CreateRelationRequest,
  params: RequestParams = {},
) =>
  client
    .request<PersonRelationResponse>({
      url: `/rdb/v1/people/${perKey}/relations`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonRelation
 * @name V1PeopleRelationsDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request DELETE:/rdb/v1/people/{perKey}/relations/{prKey}
 * @secure
 */
export const v1PeopleRelationsDelete = (
  client: AxiosInstance,
  perKey: number,
  prKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/people/${perKey}/relations/${prKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonRelation
 * @name V1PeopleRelationsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/people/{perKey}/relations
 * @secure
 */
export const v1PeopleRelationsDetail = (
  client: AxiosInstance,
  perKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonRelationResponse[]>({
      url: `/rdb/v1/people/${perKey}/relations`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonRelation
 * @name V1PeopleRelationsUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/people/{perKey}/relations/{prKey}
 * @secure
 */
export const v1PeopleRelationsUpdate = (
  client: AxiosInstance,
  perKey: number,
  prKey: number,
  data: UpdateRelationRequest,
  params: RequestParams = {},
) =>
  client
    .request<PersonRelationResponse>({
      url: `/rdb/v1/people/${perKey}/relations/${prKey}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleScrapeCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request POST:/rdb/v1/people/{personKey}/scrape
 * @secure
 */
export const v1PeopleScrapeCreate = (
  client: AxiosInstance,
  { personKey, ...query }: V1PeopleScrapeCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/people/${personKey}/scrape`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleSearchList
 * @request GET:/rdb/v1/people/search
 */
export const v1PeopleSearchList = (
  client: AxiosInstance,
  query: V1PeopleSearchListParams,
  params: RequestParams = {},
) =>
  client
    .request<PublicOpenSearchResponsePagedData>({
      url: `/rdb/v1/people/search`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleSearchRebuildCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/people/search/rebuild
 * @secure
 */
export const v1PeopleSearchRebuildCreate = (
  client: AxiosInstance,
  query: V1PeopleSearchRebuildCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/people/search/rebuild`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleSearchRebuildCreate2
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/people/search/rebuild/{personKey}
 * @originalName v1PeopleSearchRebuildCreate
 * @duplicate
 * @secure
 */
export const v1PeopleSearchRebuildCreate2 = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/people/search/rebuild/${personKey}`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleSocialsDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request DELETE:/rdb/v1/people/{key}/socials/{socialType}
 * @secure
 */
export const v1PeopleSocialsDelete = (
  client: AxiosInstance,
  key: number,
  socialType: SocialType,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/people/${key}/socials/${socialType}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleStatusBulkUpdate
 * @summary Updates the person status for multiple people.
 * @request PUT:/rdb/v1/people/status/bulk
 * @secure
 */
export const v1PeopleStatusBulkUpdate = (
  client: AxiosInstance,
  data: PersonStatusBulkRequest,
  params: RequestParams = {},
) =>
  client
    .request<PersonStatusResponsePagedData>({
      url: `/rdb/v1/people/status/bulk`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleStatusDetail
 * @summary Gets the person status for a person.
 * @request GET:/rdb/v1/people/{personKey}/status
 * @secure
 */
export const v1PeopleStatusDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonStatusResponse>({
      url: `/rdb/v1/people/${personKey}/status`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleStatusInitialCreate
 * @summary Sets the initial person status in bulk for people with no status.
 * @request POST:/rdb/v1/people/status/initial
 * @secure
 */
export const v1PeopleStatusInitialCreate = (
  client: AxiosInstance,
  data: InitialPersonStatusRequest,
  params: RequestParams = {},
) =>
  client
    .request<PersonStatusResponsePagedData>({
      url: `/rdb/v1/people/status/initial`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleStatusList
 * @summary Gets the person statuses for multiple people.
 * @request GET:/rdb/v1/people/status
 * @secure
 */
export const v1PeopleStatusList = (
  client: AxiosInstance,
  query: V1PeopleStatusListParams,
  params: RequestParams = {},
) =>
  client
    .request<PersonStatusListResponsePagedData>({
      url: `/rdb/v1/people/status`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleStatusUpdate
 * @summary Updates the person status for a person.
 * @request PUT:/rdb/v1/people/{personKey}/status
 * @secure
 */
export const v1PeopleStatusUpdate = (
  client: AxiosInstance,
  personKey: number,
  data: PersonStatusRequest,
  params: RequestParams = {},
) =>
  client
    .request<PersonStatusResponse>({
      url: `/rdb/v1/people/${personKey}/status`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/people/{key}
 * @secure
 */
export const v1PeopleUpdate = (
  client: AxiosInstance,
  key: number,
  data: UpdatePersonRequest,
  params: RequestParams = {},
) =>
  client
    .request<AdminPerResponse>({
      url: `/rdb/v1/people/${key}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleUpdateProspectVerifiedUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/people/{key}/update-prospect-verified
 * @secure
 */
export const v1PeopleUpdateProspectVerifiedUpdate = (
  client: AxiosInstance,
  key: number,
  data: V1PeopleUpdateProspectVerifiedUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/people/${key}/update-prospect-verified`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V1PeopleVerifiedAdminList
 * @summary Gets the validated players for admin with linked-user
 * @request GET:/rdb/v1/people/verified-admin
 * @secure
 */
export const v1PeopleVerifiedAdminList = (
  client: AxiosInstance,
  query: V1PeopleVerifiedAdminListParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerProfileAdminResponsePagedData>({
      url: `/rdb/v1/people/verified-admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Video
 * @name V1PeopleVideosHighlightsDetail
 * @summary  (Roles: registered user)
 * @request GET:/rdb/v1/people/{personKey}/videos/highlights
 * @secure
 */
export const v1PeopleVideosHighlightsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V1PeopleVideosHighlightsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<VideoResponsePagedData>({
      url: `/rdb/v1/people/${personKey}/videos/highlights`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Video
 * @name V1PeopleVideosHighlightsUpdate
 * @summary  (Roles: registered user)
 * @request PUT:/rdb/v1/people/{personKey}/videos/highlights
 * @secure
 */
export const v1PeopleVideosHighlightsUpdate = (
  client: AxiosInstance,
  personKey: number,
  data: RenovateVideoHighlightRequest,
  params: RequestParams = {},
) =>
  client
    .request<VideoResponse>({
      url: `/rdb/v1/people/${personKey}/videos/highlights`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonConnection
 * @name V1PersonConnectionsDelete
 * @summary Deletes Connection by ConnectionKey
 * @request DELETE:/rdb/v1/person-connections/{connectionKey}
 * @secure
 */
export const v1PersonConnectionsDelete = (
  client: AxiosInstance,
  connectionKey: number,
  params: RequestParams = {},
) =>
  client
    .request<string>({
      url: `/rdb/v1/person-connections/${connectionKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DatabaseUpdate
 * @name V1PersonDatabaseupdatesDetail
 * @summary Get a list of database updates for a personKey.
 * @request GET:/rdb/v1/person/{personKey}/databaseupdates
 * @secure
 */
export const v1PersonDatabaseupdatesDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<DatabaseUpdatePersonResponse[]>({
      url: `/rdb/v1/person/${personKey}/databaseupdates`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonKeyMapping
 * @name V1PersonKeyMappingsDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request DELETE:/rdb/v1/person-key-mappings/{key}
 * @secure
 */
export const v1PersonKeyMappingsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonKeyMappingRes>({
      url: `/rdb/v1/person-key-mappings/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonKeyMapping
 * @name V1PersonKeyMappingsUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/person-key-mappings
 * @secure
 */
export const v1PersonKeyMappingsUpdate = (
  client: AxiosInstance,
  data: RenPersonKeyMappingReq,
  params: RequestParams = {},
) =>
  client
    .request<PersonKeyMappingRes>({
      url: `/rdb/v1/person-key-mappings`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonKeyMapping
 * @name V1PersonMappingsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/person/{personKey}/mappings
 * @secure
 */
export const v1PersonMappingsDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonKeyMappingResSimplePersonResponseRelatedPagedData>({
      url: `/rdb/v1/person/${personKey}/mappings`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name V1PersonOrganizationsRenovateAdminUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/person/{personKey}/organizations/renovate-admin
 * @secure
 */
export const v1PersonOrganizationsRenovateAdminUpdate = (
  client: AxiosInstance,
  personKey: number,
  data: RenovateOrgRequest,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/person/${personKey}/organizations/renovate-admin`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonRelationType
 * @name V1PersonRelationTypesDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/person-relation-types/{key}
 * @secure
 */
export const v1PersonRelationTypesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonRelationTypeRes>({
      url: `/rdb/v1/person-relation-types/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonRelationType
 * @name V1PersonRelationTypesList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/person-relation-types
 * @secure
 */
export const v1PersonRelationTypesList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<PersonRelationTypeRes[]>({
      url: `/rdb/v1/person-relation-types`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportOrganization
 * @name V1PersonSportOrganizationsCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request POST:/rdb/v1/person-sport-organizations
 * @secure
 */
export const v1PersonSportOrganizationsCreate = (
  client: AxiosInstance,
  data: CreatePersonSportOrganizationRequest,
  params: RequestParams = {},
) =>
  client
    .request<PsoResponse>({
      url: `/rdb/v1/person-sport-organizations`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportOrganization
 * @name V1PersonSportOrganizationsDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request DELETE:/rdb/v1/person-sport-organizations/{key}
 * @secure
 */
export const v1PersonSportOrganizationsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/person-sport-organizations/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportOrganization
 * @name V1PersonSportOrganizationsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/person-sport-organizations/{key}
 * @secure
 */
export const v1PersonSportOrganizationsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<PsoResponse>({
      url: `/rdb/v1/person-sport-organizations/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportOrganization
 * @name V1PersonSportOrganizationsList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/person-sport-organizations
 * @secure
 */
export const v1PersonSportOrganizationsList = (
  client: AxiosInstance,
  query: V1PersonSportOrganizationsListParams,
  params: RequestParams = {},
) =>
  client
    .request<PsoResponse[]>({
      url: `/rdb/v1/person-sport-organizations`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportStat
 * @name V1PersonSportOrganizationsRenovateStatsUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/person-sport-organizations/{psoKey}/renovate-stats
 * @secure
 */
export const v1PersonSportOrganizationsRenovateStatsUpdate = (
  client: AxiosInstance,
  psoKey: number,
  data: V1PersonSportOrganizationsRenovateStatsUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<StatResponseListResourceResponseMessage>({
      url: `/rdb/v1/person-sport-organizations/${psoKey}/renovate-stats`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportStat
 * @name V1PersonSportOrganizationsStatsAdminDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/person-sport-organizations/{personSportOrgKey}/stats/admin
 * @secure
 */
export const v1PersonSportOrganizationsStatsAdminDetail = (
  client: AxiosInstance,
  personSportOrgKey: number,
  params: RequestParams = {},
) =>
  client
    .request<StatResponse[]>({
      url: `/rdb/v1/person-sport-organizations/${personSportOrgKey}/stats/admin`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportOrganization
 * @name V1PersonSportOrganizationsUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/person-sport-organizations/{key}
 * @secure
 */
export const v1PersonSportOrganizationsUpdate = (
  client: AxiosInstance,
  key: number,
  data: UpdatePersonSportOrganizationRequest,
  params: RequestParams = {},
) =>
  client
    .request<PsoResponse>({
      url: `/rdb/v1/person-sport-organizations/${key}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSport
 * @name V1PersonSportsCreate
 * @summary  (Roles: developer, rdb2, rdb3, rdb4, rdb5)
 * @request POST:/rdb/v1/person-sports
 * @secure
 */
export const v1PersonSportsCreate = (
  client: AxiosInstance,
  data: CreatePersonSportRequest,
  params: RequestParams = {},
) =>
  client
    .request<PersonSportResponseRecordResourceResponseMessage>({
      url: `/rdb/v1/person-sports`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSport
 * @name V1PersonSportsDelete
 * @summary  (Roles: developer, rdb5)
 * @request DELETE:/rdb/v1/person-sports/{key}
 * @secure
 */
export const v1PersonSportsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/person-sports/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSport
 * @name V1PersonSportsDetail
 * @summary  (Roles: developer, rdb2, rdb3, rdb4, rdb5)
 * @request GET:/rdb/v1/person-sports/{key}
 * @secure
 */
export const v1PersonSportsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonSportResponseRecord>({
      url: `/rdb/v1/person-sports/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSport
 * @name V1PersonSportsList
 * @summary  (Roles: developer, rdb2, rdb3, rdb4, rdb5)
 * @request GET:/rdb/v1/person-sports
 * @secure
 */
export const v1PersonSportsList = (
  client: AxiosInstance,
  query: V1PersonSportsListParams,
  params: RequestParams = {},
) =>
  client
    .request<PersonSportResponseRecordPagedData>({
      url: `/rdb/v1/person-sports`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportStat
 * @name V1PersonSportStatsAdminDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/person-sport-stats/{personSportOrgKey}/admin
 * @secure
 */
export const v1PersonSportStatsAdminDetail = (
  client: AxiosInstance,
  personSportOrgKey: number,
  params: RequestParams = {},
) =>
  client
    .request<StatResponse[]>({
      url: `/rdb/v1/person-sport-stats/${personSportOrgKey}/admin`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportStat
 * @name V1PersonSportStatsDelete
 * @summary Deletes the Stat for a key
 * @request DELETE:/rdb/v1/person-sport-stats/{key}
 * @secure
 */
export const v1PersonSportStatsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/person-sport-stats/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportStat
 * @name V1PersonSportStatsUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/person-sport-stats/{psoKey}
 * @secure
 */
export const v1PersonSportStatsUpdate = (
  client: AxiosInstance,
  psoKey: number,
  data: V1PersonSportStatsUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<StatResponseListResourceResponseMessage>({
      url: `/rdb/v1/person-sport-stats/${psoKey}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Position
 * @name V1PositionsAutocompleteList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/positions/autocomplete
 * @secure
 */
export const v1PositionsAutocompleteList = (
  client: AxiosInstance,
  query: V1PositionsAutocompleteListParams,
  params: RequestParams = {},
) =>
  client
    .request<UInt32StringKeyValuePair[]>({
      url: `/rdb/v1/positions/autocomplete`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Position
 * @name V1PositionsCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request POST:/rdb/v1/positions
 * @secure
 */
export const v1PositionsCreate = (
  client: AxiosInstance,
  data: CreatePositionRequest,
  params: RequestParams = {},
) =>
  client
    .request<PositionResponseResourceResponseMessage>({
      url: `/rdb/v1/positions`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Position
 * @name V1PositionsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/positions/{key}
 * @secure
 */
export const v1PositionsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<PositionResponse>({
      url: `/rdb/v1/positions/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Position
 * @name V1PositionsList
 * @request GET:/rdb/v1/positions
 */
export const v1PositionsList = (
  client: AxiosInstance,
  query: V1PositionsListParams,
  params: RequestParams = {},
) =>
  client
    .request<PositionResponse[]>({
      url: `/rdb/v1/positions`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Position
 * @name V1PositionsRenovateUpdate
 * @summary Updates a position
 * @request PUT:/rdb/v1/positions/renovate
 * @secure
 */
export const v1PositionsRenovateUpdate = (
  client: AxiosInstance,
  data: PositionRequest,
  params: RequestParams = {},
) =>
  client
    .request<PositionResponse>({
      url: `/rdb/v1/positions/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Rpm
 * @name V1PredictionsUpdate
 * @summary Update prediction for a key.
 * @request PUT:/rdb/v1/predictions
 * @secure
 */
export const v1PredictionsUpdate = (
  client: AxiosInstance,
  data: UpdateRpmRequest,
  params: RequestParams = {},
) =>
  client
    .request<RpmAdminResponse[]>({
      url: `/rdb/v1/predictions`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1ProcessScheduleUpdateCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/rdb/v1/process-schedule-update
 * @secure
 */
export const v1ProcessScheduleUpdateCreate = (
  client: AxiosInstance,
  query: V1ProcessScheduleUpdateCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<UpdateScheduleResponse>({
      url: `/rdb/v1/process-schedule-update`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags QuoteAdmin
 * @name V1QuotesCreate
 * @summary Creates a Quote
 * @request POST:/rdb/v1/quotes
 * @secure
 */
export const v1QuotesCreate = (
  client: AxiosInstance,
  data: QuoteRequest,
  params: RequestParams = {},
) =>
  client
    .request<QuoteResponse>({
      url: `/rdb/v1/quotes`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags QuoteAdmin
 * @name V1QuotesDelete
 * @summary Deletes a Quote
 * @request DELETE:/rdb/v1/quotes/{key}
 * @secure
 */
export const v1QuotesDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<DeleteResponse>({
      url: `/rdb/v1/quotes/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags QuoteAdmin
 * @name V1QuotesUpdate
 * @summary Updates a Quote
 * @request PUT:/rdb/v1/quotes/{key}
 * @secure
 */
export const v1QuotesUpdate = (
  client: AxiosInstance,
  key: number,
  data: QuoteRequest,
  params: RequestParams = {},
) =>
  client
    .request<QuoteResponse>({
      url: `/rdb/v1/quotes/${key}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentBreakdownSummary
 * @name V1RecruitmentBreakdownSummariesEnsureUpdate
 * @summary  (Roles: developer)
 * @request PUT:/rdb/v1/recruitment-breakdown-summaries/ensure
 * @secure
 */
export const v1RecruitmentBreakdownSummariesEnsureUpdate = (
  client: AxiosInstance,
  data: UpdateRecruitmentBreakdownSummaryRequest,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentBreakdownSummaryResponse>({
      url: `/rdb/v1/recruitment-breakdown-summaries/ensure`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentEvaluation
 * @name V1RecruitmentEvaluationsCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request POST:/rdb/v1/recruitment-evaluations
 * @secure
 */
export const v1RecruitmentEvaluationsCreate = (
  client: AxiosInstance,
  data: CreateReeRequest,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentEvaluationResponse>({
      url: `/rdb/v1/recruitment-evaluations`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentEvaluation
 * @name V1RecruitmentEvaluationsDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request DELETE:/rdb/v1/recruitment-evaluations/{key}
 * @secure
 */
export const v1RecruitmentEvaluationsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/recruitment-evaluations/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentEvaluation
 * @name V1RecruitmentEvaluationsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/recruitment-evaluations/{key}
 * @secure
 */
export const v1RecruitmentEvaluationsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<SlimRecruitmentEvaluationRes>({
      url: `/rdb/v1/recruitment-evaluations/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentEvaluation
 * @name V1RecruitmentEvaluationsList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/recruitment-evaluations
 * @secure
 */
export const v1RecruitmentEvaluationsList = (
  client: AxiosInstance,
  query: V1RecruitmentEvaluationsListParams,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentEvaluationResponse[]>({
      url: `/rdb/v1/recruitment-evaluations`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentEvaluation
 * @name V1RecruitmentEvaluationsUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/recruitment-evaluations/{key}
 * @secure
 */
export const v1RecruitmentEvaluationsUpdate = (
  client: AxiosInstance,
  key: number,
  data: UpdateReeRequest,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentEvaluationResponse>({
      url: `/rdb/v1/recruitment-evaluations/${key}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1RecruitmentInterestCoachingVisitsDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request DELETE:/rdb/v1/recruitment-interest-coaching-visits/{key}
 * @secure
 */
export const v1RecruitmentInterestCoachingVisitsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonTargetResponse>({
      url: `/rdb/v1/recruitment-interest-coaching-visits/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1RecruitmentInterestEventsDelete
 * @summary Deletes the RecruitmentInterestEvent for a key
 * @request DELETE:/rdb/v1/recruitment-interest-events/{key}
 * @secure
 */
export const v1RecruitmentInterestEventsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/recruitment-interest-events/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1RecruitmentInterestsDelete
 * @summary Deletes the RecruitmentInterest for a key
 * @request DELETE:/rdb/v1/recruitment-interests/{key}
 * @secure
 */
export const v1RecruitmentInterestsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/recruitment-interests/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1RecruitmentInterestsDelete2
 * @summary Deletes the RecruitmentInterest for a key
 * @request DELETE:/rdb/v1/recruitmentInterests/{key}
 * @originalName v1RecruitmentInterestsDelete
 * @duplicate
 * @secure
 */
export const v1RecruitmentInterestsDelete2 = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/recruitmentInterests/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1RecruitmentInterestsEnsureOrganizationRankingCreate
 * @summary  (Roles: registered user)
 * @request POST:/rdb/v1/recruitment-interests/{key}/ensure-organization-ranking
 * @secure
 */
export const v1RecruitmentInterestsEnsureOrganizationRankingCreate = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/recruitment-interests/${key}/ensure-organization-ranking`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1RecruitmentInterestsEventsDelete
 * @summary Deletes the RecruitmentInterestEvent for a key
 * @request DELETE:/rdb/v1/recruitmentInterests/events/{key}
 * @secure
 */
export const v1RecruitmentInterestsEventsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/recruitmentInterests/events/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1RecruitmentInterestsList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/recruitment-interests
 * @secure
 */
export const v1RecruitmentInterestsList = (
  client: AxiosInstance,
  query: V1RecruitmentInterestsListParams,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentInterestResponsePagedData>({
      url: `/rdb/v1/recruitment-interests`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1RecruitmentInterestsVisitsDelete
 * @summary Deletes the RecruitmentInterestVisit for a key
 * @request DELETE:/rdb/v1/recruitmentInterests/visits/{key}
 * @secure
 */
export const v1RecruitmentInterestsVisitsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/recruitmentInterests/visits/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1RecruitmentInterestVisitsDelete
 * @summary Deletes the RecruitmentInterestVisit for a key
 * @request DELETE:/rdb/v1/recruitment-interest-visits/{key}
 * @secure
 */
export const v1RecruitmentInterestVisitsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/recruitment-interest-visits/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Rpm
 * @name V1RecruitmentLatestRpmPicksDelete
 * @summary  (Roles: developer, rdb5)
 * @request DELETE:/rdb/v1/recruitment/latest-rpm-picks
 * @secure
 */
export const v1RecruitmentLatestRpmPicksDelete = (
  client: AxiosInstance,
  query: V1RecruitmentLatestRpmPicksDeleteParams,
  params: RequestParams = {},
) =>
  client
    .request<DeleteResponse>({
      url: `/rdb/v1/recruitment/latest-rpm-picks`,
      method: 'DELETE',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Rpm
 * @name V1RecruitmentLatestRpmPicksList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/recruitment/latest-rpm-picks
 * @secure
 */
export const v1RecruitmentLatestRpmPicksList = (
  client: AxiosInstance,
  query: V1RecruitmentLatestRpmPicksListParams,
  params: RequestParams = {},
) =>
  client
    .request<RpmPickListPredictionAccuraciesRelatedPagedData>({
      url: `/rdb/v1/recruitment/latest-rpm-picks`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentOraclePicker
 * @name V1RecruitmentOraclePickersAutocompleteList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/recruitment-oracle-pickers/autocomplete
 * @secure
 */
export const v1RecruitmentOraclePickersAutocompleteList = (
  client: AxiosInstance,
  query: V1RecruitmentOraclePickersAutocompleteListParams,
  params: RequestParams = {},
) =>
  client
    .request<PickerAutocompleteResponse[]>({
      url: `/rdb/v1/recruitment-oracle-pickers/autocomplete`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentOraclePicker
 * @name V1RecruitmentOraclePickersDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request DELETE:/rdb/v1/recruitment-oracle-pickers/{key}
 * @secure
 */
export const v1RecruitmentOraclePickersDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/recruitment-oracle-pickers/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentOraclePicker
 * @name V1RecruitmentOraclePickersDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/recruitment-oracle-pickers/{key}
 * @secure
 */
export const v1RecruitmentOraclePickersDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<PickerResponse>({
      url: `/rdb/v1/recruitment-oracle-pickers/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentOraclePicker
 * @name V1RecruitmentOraclePickersList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/recruitment-oracle-pickers
 * @secure
 */
export const v1RecruitmentOraclePickersList = (
  client: AxiosInstance,
  query: V1RecruitmentOraclePickersListParams,
  params: RequestParams = {},
) =>
  client
    .request<PickerResponse[]>({
      url: `/rdb/v1/recruitment-oracle-pickers`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentOraclePicker
 * @name V1RecruitmentOraclePickersRenovateUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/recruitment-oracle-pickers/renovate
 * @secure
 */
export const v1RecruitmentOraclePickersRenovateUpdate = (
  client: AxiosInstance,
  data: V1RecruitmentOraclePickersRenovateUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<PickerResponse[]>({
      url: `/rdb/v1/recruitment-oracle-pickers/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentOracle
 * @name V1RecruitmentOraclesCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request POST:/rdb/v1/recruitment-oracles
 * @secure
 */
export const v1RecruitmentOraclesCreate = (
  client: AxiosInstance,
  data: CreateRecruitmentOracleRequest,
  params: RequestParams = {},
) =>
  client
    .request<OracleBaseAdminResponse>({
      url: `/rdb/v1/recruitment-oracles`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentOracle
 * @name V1RecruitmentOraclesDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request DELETE:/rdb/v1/recruitment-oracles/{key}
 * @secure
 */
export const v1RecruitmentOraclesDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/recruitment-oracles/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentOracle
 * @name V1RecruitmentOraclesDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/recruitment-oracles/{key}
 * @secure
 */
export const v1RecruitmentOraclesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<OracleBaseAdminResponse>({
      url: `/rdb/v1/recruitment-oracles/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentOracle
 * @name V1RecruitmentOraclesSimulateRpmForPickCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request POST:/rdb/v1/recruitment-oracles/simulate-rpm-for-pick
 * @secure
 */
export const v1RecruitmentOraclesSimulateRpmForPickCreate = (
  client: AxiosInstance,
  data: CreateRecruitmentOracleRequest,
  params: RequestParams = {},
) =>
  client
    .request<RpmAdminResponsePagedData>({
      url: `/rdb/v1/recruitment-oracles/simulate-rpm-for-pick`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Rpm
 * @name V1RecruitmentPredictionsDetail
 * @summary Get predictions for a recruitment.
 * @request GET:/rdb/v1/recruitment/{recKey}/predictions
 * @secure
 */
export const v1RecruitmentPredictionsDetail = (
  client: AxiosInstance,
  recKey: number,
  params: RequestParams = {},
) =>
  client
    .request<RpmAdminResponse[]>({
      url: `/rdb/v1/recruitment/${recKey}/predictions`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Rpm
 * @name V1RecruitmentProcessRpmCreate
 * @summary  (Roles: rdb5)
 * @request POST:/rdb/v1/recruitment/{recruitmentKey}/process-rpm
 * @secure
 */
export const v1RecruitmentProcessRpmCreate = (
  client: AxiosInstance,
  recruitmentKey: number,
  params: RequestParams = {},
) =>
  client
    .request<RpmAdminResponse[]>({
      url: `/rdb/v1/recruitment/${recruitmentKey}/process-rpm`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruitment
 * @name V1RecruitmentsArticlesDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/recruitments/{key}/articles
 * @secure
 */
export const v1RecruitmentsArticlesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentArticlesResponse>({
      url: `/rdb/v1/recruitments/${key}/articles`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruitment
 * @name V1RecruitmentsArticlesUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/recruitments/{key}/articles
 * @secure
 */
export const v1RecruitmentsArticlesUpdate = (
  client: AxiosInstance,
  key: number,
  data: RecruitmentArticlesRequest,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/recruitments/${key}/articles`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruitment
 * @name V1RecruitmentsDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request DELETE:/rdb/v1/recruitments/{key}
 * @secure
 */
export const v1RecruitmentsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/recruitments/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruitment
 * @name V1RecruitmentsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/recruitments/{key}
 * @secure
 */
export const v1RecruitmentsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentResponse>({
      url: `/rdb/v1/recruitments/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruitment
 * @name V1RecruitmentsList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/recruitments
 * @secure
 */
export const v1RecruitmentsList = (
  client: AxiosInstance,
  query: V1RecruitmentsListParams,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentResponsePagedData>({
      url: `/rdb/v1/recruitments`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruitment
 * @name V1RecruitmentsLockOracleUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/recruitments/{key}/lock-oracle
 * @secure
 */
export const v1RecruitmentsLockOracleUpdate = (
  client: AxiosInstance,
  key: number,
  data: LockOracleRequest,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/recruitments/${key}/lock-oracle`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1RecruitmentsOraclesUpdateUpdate
 * @summary Updates Recruitment Oracles' Correct and Days Correct columns, and returns Count
 * @request PUT:/rdb/v1/recruitments/oracles/update
 * @secure
 */
export const v1RecruitmentsOraclesUpdateUpdate = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<string>({
      url: `/rdb/v1/recruitments/oracles/update`,
      method: 'PUT',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportStat
 * @name V1RecruitmentsPersonSportStatsAdminDetail
 * @summary Get all Stats for a given Recruitment key
 * @request GET:/rdb/v1/recruitments/{recruitmentKey}/person-sport-stats/admin
 * @secure
 */
export const v1RecruitmentsPersonSportStatsAdminDetail = (
  client: AxiosInstance,
  recruitmentKey: number,
  params: RequestParams = {},
) =>
  client
    .request<StatResponse[]>({
      url: `/rdb/v1/recruitments/${recruitmentKey}/person-sport-stats/admin`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentEvaluation
 * @name V1RecruitmentsRecruitmentEvaluationsAdminDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/recruitments/{recruitmentKey}/recruitment-evaluations/admin
 * @secure
 */
export const v1RecruitmentsRecruitmentEvaluationsAdminDetail = (
  client: AxiosInstance,
  {
    recruitmentKey,
    ...query
  }: V1RecruitmentsRecruitmentEvaluationsAdminDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentEvaluationResponse[]>({
      url: `/rdb/v1/recruitments/${recruitmentKey}/recruitment-evaluations/admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1RecruitmentsRecruitmentInterestsAdminDetail
 * @summary Populate the Team Target component for the Admin.
 * @request GET:/rdb/v1/recruitments/{recruitmentKey}/recruitment-interests/admin
 * @secure
 */
export const v1RecruitmentsRecruitmentInterestsAdminDetail = (
  client: AxiosInstance,
  recruitmentKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonTargetResponse>({
      url: `/rdb/v1/recruitments/${recruitmentKey}/recruitment-interests/admin`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1RecruitmentsRecruitmentInterestsAdminDetail2
 * @summary Populate the Team Target component for the Admin.
 * @request GET:/rdb/v1/recruitments/{recruitmentKey}/recruitmentInterests/admin
 * @originalName v1RecruitmentsRecruitmentInterestsAdminDetail
 * @duplicate
 * @secure
 */
export const v1RecruitmentsRecruitmentInterestsAdminDetail2 = (
  client: AxiosInstance,
  recruitmentKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonTargetResponse>({
      url: `/rdb/v1/recruitments/${recruitmentKey}/recruitmentInterests/admin`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1RecruitmentsRecruitmentInterestsAdminUpdate
 * @summary Creates/Updates a list of recruitment interest for a recruitmentKey.
 * @request PUT:/rdb/v1/recruitments/{recruitmentKey}/recruitment-interests/admin
 * @secure
 */
export const v1RecruitmentsRecruitmentInterestsAdminUpdate = (
  client: AxiosInstance,
  recruitmentKey: number,
  data: PersonTargetRequest,
  params: RequestParams = {},
) =>
  client
    .request<PersonTargetResponse>({
      url: `/rdb/v1/recruitments/${recruitmentKey}/recruitment-interests/admin`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentInterest
 * @name V1RecruitmentsRecruitmentInterestsAdminUpdate2
 * @summary Creates/Updates a list of recruitment interest for a recruitmentKey.
 * @request PUT:/rdb/v1/recruitments/{recruitmentKey}/recruitmentInterests/admin
 * @originalName v1RecruitmentsRecruitmentInterestsAdminUpdate
 * @duplicate
 * @secure
 */
export const v1RecruitmentsRecruitmentInterestsAdminUpdate2 = (
  client: AxiosInstance,
  recruitmentKey: number,
  data: PersonTargetRequest,
  params: RequestParams = {},
) =>
  client
    .request<PersonTargetResponse>({
      url: `/rdb/v1/recruitments/${recruitmentKey}/recruitmentInterests/admin`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentOracle
 * @name V1RecruitmentsRecruitmentOraclesAdminDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/recruitments/{recruitmentKey}/recruitment-oracles/admin
 * @secure
 */
export const v1RecruitmentsRecruitmentOraclesAdminDetail = (
  client: AxiosInstance,
  {
    recruitmentKey,
    ...query
  }: V1RecruitmentsRecruitmentOraclesAdminDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ReorAdminResponse>({
      url: `/rdb/v1/recruitments/${recruitmentKey}/recruitment-oracles/admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruitment
 * @name V1RecruitmentsYearDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/recruitments/{key}/year
 * @secure
 */
export const v1RecruitmentsYearDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/recruitments/${key}/year`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruitment
 * @name V1RecruitmentsYearUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/recruitments/{key}/year
 * @secure
 */
export const v1RecruitmentsYearUpdate = (
  client: AxiosInstance,
  key: number,
  data: RecruitmentYearRequest,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentResponse>({
      url: `/rdb/v1/recruitments/${key}/year`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Rpm
 * @name V1RpmAccuracyList
 * @summary Gets the RPM accuracy by sport
 * @request GET:/rdb/v1/rpm-accuracy
 * @secure
 */
export const v1RpmAccuracyList = (
  client: AxiosInstance,
  query: V1RpmAccuracyListParams,
  params: RequestParams = {},
) =>
  client
    .request<RpmAccuracyResponse>({
      url: `/rdb/v1/rpm-accuracy`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1SchedulesDelete
 * @summary Deletes the Game for a key
 * @request DELETE:/rdb/v1/schedules/{key}
 * @secure
 */
export const v1SchedulesDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/schedules/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1SchedulesProcessActiveLocCreate
 * @request POST:/rdb/v1/schedules/process-active-loc
 */
export const v1SchedulesProcessActiveLocCreate = (
  client: AxiosInstance,
  query: V1SchedulesProcessActiveLocCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<UpdateScheduleResponse>({
      url: `/rdb/v1/schedules/process-active-loc`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1SchedulesUpdateUpcomingGamesCreate
 * @request POST:/rdb/v1/schedules/update-upcoming-games
 */
export const v1SchedulesUpdateUpcomingGamesCreate = (
  client: AxiosInstance,
  query: V1SchedulesUpdateUpcomingGamesCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<UpdateScheduleResponse>({
      url: `/rdb/v1/schedules/update-upcoming-games`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1SchedulesUpdateUpcomingGamesLocCreate
 * @request POST:/rdb/v1/schedules/update-upcoming-games-loc
 */
export const v1SchedulesUpdateUpcomingGamesLocCreate = (
  client: AxiosInstance,
  query: V1SchedulesUpdateUpcomingGamesLocCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<UpdateScheduleResponse>({
      url: `/rdb/v1/schedules/update-upcoming-games-loc`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1ScoreboardFiltersList
 * @request GET:/rdb/v1/scoreboard-filters
 */
export const v1ScoreboardFiltersList = (
  client: AxiosInstance,
  query: V1ScoreboardFiltersListParams,
  params: RequestParams = {},
) =>
  client
    .request<ScoreboardFiltersResponseLeagueResponseRelatedPagedData>({
      url: `/rdb/v1/scoreboard-filters`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Schedule
 * @name V1ScoreboardList
 * @request GET:/rdb/v1/scoreboard
 */
export const v1ScoreboardList = (
  client: AxiosInstance,
  query: V1ScoreboardListParams,
  params: RequestParams = {},
) =>
  client
    .request<ScoreboardResponseLeagueScoreBoardRelatedResponseRelatedPagedData>(
      {
        url: `/rdb/v1/scoreboard`,
        method: 'GET',
        params: query,
        ...params,
      },
    )
    .then((r) => r.data);

/**
 * No description
 *
 * @tags SeasonPhase
 * @name V1SeasonPhasesImportCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/season-phases/import
 * @secure
 */
export const v1SeasonPhasesImportCreate = (
  client: AxiosInstance,
  query: V1SeasonPhasesImportCreateParams,
  data: V1SeasonPhasesImportCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/season-phases/import`,
      method: 'POST',
      params: query,
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags SeasonPhase
 * @name V1SeasonPhasesList
 * @request GET:/rdb/v1/season-phases
 */
export const v1SeasonPhasesList = (
  client: AxiosInstance,
  query: V1SeasonPhasesListParams,
  params: RequestParams = {},
) =>
  client
    .request<SeasonPhaseResponse[]>({
      url: `/rdb/v1/season-phases`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags SeasonPhase
 * @name V1SeasonPhasesRankingsDetail
 * @summary Updates an organization ranking (by Season Phase - week 1 / week 2...)
 * @request GET:/rdb/v1/season-phases/{seasonPhaseKey}/rankings
 */
export const v1SeasonPhasesRankingsDetail = (
  client: AxiosInstance,
  seasonPhaseKey: number,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationRankingPhaseResponse>({
      url: `/rdb/v1/season-phases/${seasonPhaseKey}/rankings`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags SeasonPhase
 * @name V1SeasonRankingPhasesRankingDetail
 * @summary Updates an organization ranking (by Season Phase - week 1 / week 2...)
 * @request GET:/rdb/v1/season-ranking-phases/{seasonPhaseKey}/{orgKey}/ranking
 */
export const v1SeasonRankingPhasesRankingDetail = (
  client: AxiosInstance,
  orgKey: number,
  seasonPhaseKey: number,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationRankingPhaseResponse>({
      url: `/rdb/v1/season-ranking-phases/${seasonPhaseKey}/${orgKey}/ranking`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags SeasonPhase
 * @name V1SeasonRankingPhasesRankingsDetail
 * @summary Updates an organization ranking (by Season Phase - week 1 / week 2...)
 * @request GET:/rdb/v1/season-ranking-phases/{seasonPhaseKey}/rankings
 */
export const v1SeasonRankingPhasesRankingsDetail = (
  client: AxiosInstance,
  seasonPhaseKey: number,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationRankingPhaseResponse>({
      url: `/rdb/v1/season-ranking-phases/${seasonPhaseKey}/rankings`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags SeasonPhase
 * @name V1SeasonRankingPhasesRenovateCreate
 * @summary Updates an organization ranking (by Season Phase - week 1 / week 2...)
 * @request POST:/rdb/v1/season-ranking-phases/renovate
 * @secure
 */
export const v1SeasonRankingPhasesRenovateCreate = (
  client: AxiosInstance,
  data: OrganizationRankingPhaseRequest,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationRankingPhaseResponse>({
      url: `/rdb/v1/season-ranking-phases/renovate`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Sport
 * @name V1SportsAutocompleteList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/sports/autocomplete
 * @secure
 */
export const v1SportsAutocompleteList = (
  client: AxiosInstance,
  query: V1SportsAutocompleteListParams,
  params: RequestParams = {},
) =>
  client
    .request<UInt32StringKeyValuePair[]>({
      url: `/rdb/v1/sports/autocomplete`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Sport
 * @name V1SportsCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request POST:/rdb/v1/sports
 * @deprecated
 * @secure
 */
export const v1SportsCreate = (
  client: AxiosInstance,
  data: RenSportReq,
  params: RequestParams = {},
) =>
  client
    .request<SportResponseRecord>({
      url: `/rdb/v1/sports`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Sport
 * @name V1SportsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/sports/{key}
 * @secure
 */
export const v1SportsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<SportResponseRecord>({
      url: `/rdb/v1/sports/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name V1SportsEnsureAllPickRankingSummaryCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/sports/{sportKey}/ensure-all-pick-ranking-summary
 * @secure
 */
export const v1SportsEnsureAllPickRankingSummaryCreate = (
  client: AxiosInstance,
  sportKey: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/sports/${sportKey}/ensure-all-pick-ranking-summary`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name V1SportsEnsurePickRankingSummaryCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/sports/{sportKey}/ensure-pick-ranking-summary
 * @secure
 */
export const v1SportsEnsurePickRankingSummaryCreate = (
  client: AxiosInstance,
  { sportKey, ...query }: V1SportsEnsurePickRankingSummaryCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/sports/${sportKey}/ensure-pick-ranking-summary`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name V1SportsEnsureRankingSummaryCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/sports/{sportKey}/ensure-ranking-summary
 * @secure
 */
export const v1SportsEnsureRankingSummaryCreate = (
  client: AxiosInstance,
  { sportKey, ...query }: V1SportsEnsureRankingSummaryCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/sports/${sportKey}/ensure-ranking-summary`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Sport
 * @name V1SportsList
 * @request GET:/rdb/v1/sports
 */
export const v1SportsList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<SportResponseRecord[]>({
      url: `/rdb/v1/sports`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Measurement
 * @name V1SportsMeasurementsGenerateAveragesCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/sports/{sportKey}/measurements/generate-averages
 * @secure
 */
export const v1SportsMeasurementsGenerateAveragesCreate = (
  client: AxiosInstance,
  sportKey: number,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/rdb/v1/sports/${sportKey}/measurements/generate-averages`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Sport
 * @name V1SportsRenovateUpdate
 * @summary Updates or adds a sport
 * @request PUT:/rdb/v1/sports/renovate
 * @secure
 */
export const v1SportsRenovateUpdate = (
  client: AxiosInstance,
  data: RenSportReq,
  params: RequestParams = {},
) =>
  client
    .request<SportResponseRecord>({
      url: `/rdb/v1/sports/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Stat
 * @name V1StatsProcessAccuracyRatingsCreate
 * @request POST:/rdb/v1/stats/process-accuracy-ratings
 */
export const v1StatsProcessAccuracyRatingsCreate = (
  client: AxiosInstance,
  query: V1StatsProcessAccuracyRatingsCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<UpdateStatResponse>({
      url: `/rdb/v1/stats/process-accuracy-ratings`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Strength
 * @name V1StrengthsCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request POST:/rdb/v1/strengths
 * @secure
 */
export const v1StrengthsCreate = (
  client: AxiosInstance,
  data: CreateStrengthRequest,
  params: RequestParams = {},
) =>
  client
    .request<StrengthResResourceResponseMessage>({
      url: `/rdb/v1/strengths`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Strength
 * @name V1StrengthsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/strengths/{key}
 * @secure
 */
export const v1StrengthsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<StrengthRes>({
      url: `/rdb/v1/strengths/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Strength
 * @name V1StrengthsList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/strengths
 * @secure
 */
export const v1StrengthsList = (
  client: AxiosInstance,
  query: V1StrengthsListParams,
  params: RequestParams = {},
) =>
  client
    .request<StrengthRes[]>({
      url: `/rdb/v1/strengths`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Strength
 * @name V1StrengthsSimpleList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/strengths/simple
 * @secure
 */
export const v1StrengthsSimpleList = (
  client: AxiosInstance,
  query: V1StrengthsSimpleListParams,
  params: RequestParams = {},
) =>
  client
    .request<StrengthBase[]>({
      url: `/rdb/v1/strengths/simple`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Strength
 * @name V1StrengthsUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/strengths/{key}
 * @secure
 */
export const v1StrengthsUpdate = (
  client: AxiosInstance,
  key: number,
  data: UpdateStrengthRequest,
  params: RequestParams = {},
) =>
  client
    .request<StrengthResResourceResponseMessage>({
      url: `/rdb/v1/strengths/${key}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TeamRanking
 * @name V1TeamRankingsImportMasseyRankingsCreate
 * @summary  (Roles: developer, cron)
 * @request POST:/rdb/v1/team-rankings/import-massey-rankings
 * @secure
 */
export const v1TeamRankingsImportMasseyRankingsCreate = (
  client: AxiosInstance,
  query: V1TeamRankingsImportMasseyRankingsCreateParams,
  data: V1TeamRankingsImportMasseyRankingsCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<UploadByCsvResponse>({
      url: `/rdb/v1/team-rankings/import-massey-rankings`,
      method: 'POST',
      params: query,
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TeamRanking
 * @name V1TeamRankingsRankingsDetail
 * @request GET:/rdb/v1/team-rankings/{orgKey}/rankings
 */
export const v1TeamRankingsRankingsDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: V1TeamRankingsRankingsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<SingleTeamRankingResponse>({
      url: `/rdb/v1/team-rankings/${orgKey}/rankings`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TeamRanking
 * @name V1TeamRankingsRankingsList
 * @summary Get all Massey Rankings for an Organization/Sport/Year at a national level or provide
a filterKey eg) State Abbreviation or Conference Short Name
 * @request GET:/rdb/v1/team-rankings/rankings
 */
export const v1TeamRankingsRankingsList = (
  client: AxiosInstance,
  query: V1TeamRankingsRankingsListParams,
  params: RequestParams = {},
) =>
  client
    .request<TeamRankingResponseRelatedTeamRankingResponseRelatedPagedData>({
      url: `/rdb/v1/team-rankings/rankings`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TeamRanking
 * @name V1TeamRankingsSyncPhaseRanksToGamesCreate
 * @summary  (Roles: developer, cron)
 * @request POST:/rdb/v1/team-rankings/sync-phase-ranks-to-games
 * @secure
 */
export const v1TeamRankingsSyncPhaseRanksToGamesCreate = (
  client: AxiosInstance,
  data: SyncPhaseRanksToGamesReq,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/team-rankings/sync-phase-ranks-to-games`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Video
 * @name V1VideosDetail
 * @summary  (Roles: registered user)
 * @request GET:/rdb/v1/videos/{videoKey}
 * @secure
 */
export const v1VideosDetail = (
  client: AxiosInstance,
  videoKey: number,
  params: RequestParams = {},
) =>
  client
    .request<VideoResponse>({
      url: `/rdb/v1/videos/${videoKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);
