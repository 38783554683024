import { Input } from '@on3/ui-lib/components/Input/Input';
import { filterPhoneNumber } from '@on3/ui-lib/utils/filters';
import { formatPhoneNumber } from '@on3/ui-lib/utils/formatters';
import { ChangeEvent, useState } from 'react';

import { CompleteProfileModalScreenProps } from '..';
import styles from './Screens.module.scss';

type Key = 'parentInfo' | 'secondaryParentInfo';
type Property = 'name' | 'email' | 'phoneNumber';

export const GuardianScreen = ({
  formState,
  profileData,
  handleSave,
}: CompleteProfileModalScreenProps) => {
  const [state, setState] = useState({
    parentInfo: {
      name: profileData.parentInfo?.value?.name || '',
      email: profileData.parentInfo?.value?.email || '',
      phoneNumber: profileData.parentInfo?.value?.phoneNumber || '',
    },
    secondaryParentInfo: {
      name: profileData.secondaryParentInfo?.value?.name || '',
      email: profileData.secondaryParentInfo?.value?.email || '',
      phoneNumber: profileData.secondaryParentInfo?.value?.phoneNumber || '',
    },
  });

  const handleValueChange = (key: Key, property: Property, val: string) => {
    setState((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [property]: val,
      },
    }));
  };

  return (
    <form
      className={styles.block}
      onSubmit={(e) =>
        handleSave(e, {
          parentInfo: {
            value: state.parentInfo,
          },
          secondaryParentInfo: {
            value: state.secondaryParentInfo,
          },
        })
      }
    >
      <div className={styles.group}>
        <Input
          error={formState.errors['parentInfo.value.name']}
          id="parentName"
          label="Guardian Name"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleValueChange('parentInfo', 'name', e.target.value)
          }
          value={state.parentInfo.name}
        />
        <Input
          error={formState.errors['parentInfo.value.email']}
          id="parentEmail"
          label="Guardian Email Address"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleValueChange('parentInfo', 'email', e.target.value)
          }
          value={state.parentInfo.email}
        />
        <Input
          error={formState.errors['parentInfo.value.phoneNumber']}
          id="parentPhone"
          label="Guardian Phone Number"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleValueChange(
              'parentInfo',
              'phoneNumber',
              filterPhoneNumber(e.target.value),
            )
          }
          value={formatPhoneNumber(state.parentInfo.phoneNumber)}
        />
      </div>
      <div className={styles.group}>
        <Input
          error={formState.errors['secondaryParentInfo.value.name']}
          id="secondaryParentName"
          label="Additional Guardian Name"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleValueChange('secondaryParentInfo', 'name', e.target.value)
          }
          value={state.secondaryParentInfo.name}
        />
        <Input
          error={formState.errors['secondaryParentInfo.value.email']}
          id="secondaryParentEmail"
          label="Additional Guardian Email Address"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleValueChange('secondaryParentInfo', 'email', e.target.value)
          }
          value={state.secondaryParentInfo.email}
        />
        <Input
          error={formState.errors['secondaryParentInfo.value.phoneNumber']}
          id="secondaryParentPhone"
          label="Additional Guardian Phone Number"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleValueChange(
              'secondaryParentInfo',
              'phoneNumber',
              filterPhoneNumber(e.target.value),
            )
          }
          value={formatPhoneNumber(state.secondaryParentInfo.phoneNumber)}
        />
      </div>
      <div className={styles.submit}>
        <button disabled={formState.loading} type="submit">
          Save
        </button>
      </div>
    </form>
  );
};
