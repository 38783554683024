import { useClickOutside } from '@on3/ui-lib/hooks/useClickOutside';
import clsx from 'clsx';
import {
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldError } from 'react-hook-form';

import { Spinner } from '../Spinner/Spinner';
import { CloseButton } from '../Svg/CloseButton';
import styles from './AutoComplete.module.scss';

export type OptionType = {
  key: number;
  label: string;
  value?: string | number;
  selectedLabel?: string;
  [key: string]: any;
};

export interface AutoCompleteProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'value' | 'prefix'
  > {
  id: string;
  label: string;
  options: OptionType[];
  className?: string;
  error?: FieldError | string;
  helperText?: string;
  limit?: number;
  loading?: boolean;
  prefix?: React.ReactNode | string;
  srLabel?: boolean;
  value?: OptionType[];
  onChange: (value: OptionType[]) => void;
  onSearch?: (value: string) => void;
  chipFormatter?: (str: string) => string;
}

const AutoComplete = forwardRef<HTMLInputElement, AutoCompleteProps>(
  (
    {
      id,
      label,
      options,
      value = [],
      onChange,
      onSearch = (str: string) => str,
      chipFormatter = (str: string) => str,
      disabled,
      limit = 1,
      loading = false,
      error,
      srLabel,
      helperText,
      prefix,
      className,
      ...props
    },
    _parentRef,
  ) => {
    const ref = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const prevValRef = useRef<OptionType[]>([]);
    const [search, setSearch] = useState<string>('');
    const [selected, setSelected] = useState<OptionType[]>(value);
    const [open, setOpen] = useState<boolean>(false);

    useClickOutside(ref, () => setOpen(false));

    // Handle the input change
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const inputVal = event.target.value;

      setSearch(inputVal);
      onSearch(inputVal);
    }

    // Handle the selection of an option
    function handleSelect(option: OptionType) {
      setSearch('');
      setSelected(() => (limit === 1 ? [option] : [...selected, option]));
      setOpen(false);

      inputRef.current?.focus();
    }

    // Remove an item from the selected list
    function handleRemove(key: number, e?: React.KeyboardEvent) {
      if (e && !['Enter', 'Backspace', ' '].includes(e.key)) {
        return;
      }

      setSelected(selected.filter((item) => item.key !== key));
    }

    // Open the dropdown when options are available and search is not empty
    useEffect(() => {
      if (options.length > 0 && search.length > 0) {
        setOpen(true);
      }
    }, [options, search]);

    // Update the value when selected items change
    // Memoize the selected items to prevent unnecessary updates
    useEffect(() => {
      onChange(selected);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    // Change state tracking and reset handling
    useEffect(() => {
      const prevValue = prevValRef.current;

      if (prevValue && prevValue.length > 0 && value.length === 0) {
        setSelected([]);
      }

      prevValRef.current = value;
    }, [value]);

    const isDisabled = useMemo(
      () => disabled || selected.length >= limit,
      [disabled, selected, limit],
    );

    return (
      <div
        className={clsx(styles.base, className)}
        data-ui="autocomplete"
        ref={ref}
      >
        <label htmlFor={id}>
          <span
            className={clsx({
              'sr-only': srLabel,
            })}
            data-ui="autocomplete-label"
          >
            {label}
          </span>
          <div className={styles.wrapper}>
            {prefix && (
              <div className={styles.prefix} data-ui="autocomplete-prefix">
                {prefix}
              </div>
            )}
            <div className={styles.inner} data-autocomplete-ui="interactive">
              {selected.map((option) => (
                <div
                  className={styles.chip}
                  data-autocomplete-ui="chip"
                  key={option.key}
                  onClick={() => handleRemove(option.key)}
                  onKeyDown={(e) =>
                    handleRemove(option.key, e as React.KeyboardEvent)
                  }
                  role="button"
                  tabIndex={0}
                >
                  <span>
                    {chipFormatter(option.selectedLabel || option.label)}
                  </span>
                  <CloseButton />
                </div>
              ))}
              {selected.length < limit && (
                <div data-autocomplete-ui="input">
                  <input
                    aria-autocomplete="list"
                    aria-controls="listbox"
                    aria-expanded={open}
                    disabled={isDisabled}
                    id={id}
                    onChange={handleChange}
                    ref={inputRef}
                    role="combobox"
                    value={search}
                    {...props}
                  />
                  {loading && <Spinner />}
                </div>
              )}
            </div>
          </div>
        </label>
        {open && (
          <ul className={styles.dropdown} role="listbox">
            {options.map((option) => (
              <li
                aria-selected={selected.some((item) => item.key === option.key)}
                key={option.value || option.key}
                role="option"
              >
                <button
                  className={styles.option}
                  onClick={() => handleSelect(option)}
                  type="button"
                >
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        )}
        {helperText && <p className={styles.helper}>{helperText}</p>}
        {error && (
          <p className={styles.error}>
            {typeof error === 'object' ? error.message : error}
          </p>
        )}
      </div>
    );
  },
);

AutoComplete.displayName = 'AutoComplete';

export { AutoComplete };
