import { Input } from '@on3/ui-lib/components/Input/Input';
import { filterPhoneNumber } from '@on3/ui-lib/utils/filters';
import { formatPhoneNumber } from '@on3/ui-lib/utils/formatters';
import { ChangeEvent, useState } from 'react';

import { CompleteProfileModalScreenProps } from '..';
import styles from './Screens.module.scss';

type Key = 'email' | 'name' | 'phoneNumber';

export const AgentScreen = ({
  formState,
  profileData,
  handleSave,
}: CompleteProfileModalScreenProps) => {
  const [state, setState] = useState({
    email: profileData.agentInfo?.value?.email || '',
    name: profileData.agentInfo?.value?.name || '',
    phoneNumber: profileData.agentInfo?.value?.phoneNumber || '',
  });

  const handleValueChange = (key: Key, val: string) => {
    setState((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  return (
    <form
      className={styles.block}
      onSubmit={(e) =>
        handleSave(e, {
          agentInfo: {
            value: state,
          },
        })
      }
    >
      <div className={styles.group}>
        <Input
          error={formState.errors['agentInfo.value.name']}
          id="agentName"
          label="Agent Name"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleValueChange('name', e.target.value)
          }
          value={state.name}
        />

        <Input
          error={formState.errors['agentInfo.value.email']}
          id="agentEmail"
          label="Agent Email Address"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleValueChange('email', e.target.value)
          }
          value={state.email}
        />

        <Input
          error={formState.errors['agentInfo.value.phoneNumber']}
          id="agentPhone"
          label="Agent Phone Number"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleValueChange('phoneNumber', filterPhoneNumber(e.target.value))
          }
          value={formatPhoneNumber(state.phoneNumber)}
        />
      </div>
      <div className={styles.submit}>
        <button disabled={formState.loading} type="submit">
          Continue
        </button>
      </div>
    </form>
  );
};
